import store from '@/store'
var cardSorted = [[0, 2, 3, 9, 10, 11], [3, 4, 12, 13], [5, 6, 14, 15], [18, 19, 20, 27, 28, 29], [21, 22, 30, 31], [23, 24, 32, 33], [36, 37, 38, 45, 46, 47], [39, 40, 41, 48, 49, 50]]

export default class boosterGenerator {
  static getBooster(boosterType, cardNumber) {
    var cardNb = cardNumber

    if (store.state.idRobot) {
      cardSorted = this.MultiplyId(cardSorted, store.state.idRobot)
    }

    if (boosterType == 4 || boosterType == 6) {
      cardNb = 5
    }

    var cardPossibleChoice = this.getPosibilities(boosterType, cardNb);

    var boosterCard = []

    var id
    cardPossibleChoice = this.filterPosibility(cardPossibleChoice)
    cardPossibleChoice = this.completeIfEmpty(cardPossibleChoice)
    if (cardNb == 7 && boosterType != 4) {
      id = cardPossibleChoice[6][Math.floor(Math.random() * cardPossibleChoice[6].length)];
      boosterCard.push({ rare: false, id })
      cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
      id = cardPossibleChoice[5][Math.floor(Math.random() * cardPossibleChoice[5].length)];
      boosterCard.push({ rare: false, id })
      cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
    }


    id = cardPossibleChoice[4][Math.floor(Math.random() * cardPossibleChoice[4].length)];
    boosterCard.push({ rare: false, id })
    cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
    cardPossibleChoice = this.completeIfEmpty(cardPossibleChoice)
    id = cardPossibleChoice[3][Math.floor(Math.random() * cardPossibleChoice[3].length)];
    boosterCard.push({ rare: false, id })
    cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
    cardPossibleChoice = this.completeIfEmpty(cardPossibleChoice)
    id = cardPossibleChoice[2][Math.floor(Math.random() * cardPossibleChoice[2].length)];
    boosterCard.push({ rare: false, id })
    cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
    cardPossibleChoice = this.completeIfEmpty(cardPossibleChoice)
    id = cardPossibleChoice[1][Math.floor(Math.random() * cardPossibleChoice[1].length)];
    boosterCard.push({ rare: false, id })
    cardPossibleChoice = this.removeThisCardFromPossibility(cardPossibleChoice, id);
    cardPossibleChoice = this.completeIfEmpty(cardPossibleChoice)
    id = cardPossibleChoice[0][Math.floor(Math.random() * cardPossibleChoice[0].length)];
    boosterCard.push({ rare: false, id })


    for (var i = 0; i < boosterCard.length; i++) {
      var rareCard = { ...boosterCard[i] }
      rareCard.rare = true

      var threshold = 0.9

      if (boosterType == 6) {
        threshold = 0;
      }
      else if (store.state.malediction) {
        threshold = 1;
      }
      else if ((store.state.cards[50].normal + store.state.cards[50].rare) > 0) {
        threshold = 0.8;
      }


      boosterCard[i] = Math.random() > threshold ? rareCard : boosterCard[i]
    }
    return boosterCard
  }

  static getPosibilities(boosterType, cardNb) {
    var cardPossibleChoice;

    switch (boosterType) {
      case 0:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [0, 3, 1, 10]
        cardPossibleChoice[1] = [...cardSorted[0]]
        cardPossibleChoice[2] = [...cardSorted[0], 14, 5]
        cardPossibleChoice[3] = [...cardSorted[1], ...cardSorted[0]]
        cardPossibleChoice[4] = [...cardSorted[2], ...cardSorted[1], ...cardSorted[3]]
        if (cardNb == 7) {
          cardPossibleChoice = [...cardPossibleChoice, [], []]
          cardPossibleChoice[5] = [...cardSorted[1]]
          cardPossibleChoice[6] = [...cardSorted[2], ...cardSorted[3]]
        }
        break;
      case 1:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [...cardSorted[1]]
        cardPossibleChoice[1] = [...cardSorted[2], ...cardSorted[3]]
        cardPossibleChoice[2] = [...cardSorted[3], ...cardSorted[4]]
        cardPossibleChoice[3] = [...cardSorted[4], ...cardSorted[5], 24]
        cardPossibleChoice[4] = [...cardSorted[6], ...cardSorted[5], 48, 24]
        if (cardNb == 7) {
          cardPossibleChoice = [...cardPossibleChoice, [], []]
          cardPossibleChoice[5] = [...cardSorted[3], ...cardSorted[4]]
          cardPossibleChoice[6] = [...cardSorted[6], ...cardSorted[5], 48, 24]
        }
        break;
      case 2:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [...cardSorted[4]]
        cardPossibleChoice[1] = [...cardSorted[5]]
        cardPossibleChoice[2] = [...cardSorted[5], ...cardSorted[6]]
        cardPossibleChoice[3] = [...cardSorted[7], ...cardSorted[6]]
        cardPossibleChoice[4] = [...cardSorted[7], ...cardSorted[7], 49]
        if (cardNb == 7) {
          cardPossibleChoice = [...cardPossibleChoice, [], []]
          cardPossibleChoice[5] = [...cardSorted[7], ...cardSorted[6]]
          cardPossibleChoice[6] = [...cardSorted[7], ...cardSorted[7], 49]
        }
        break;
      case 3:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [6]
        cardPossibleChoice[1] = [14]
        cardPossibleChoice[2] = [29]
        cardPossibleChoice[3] = [37]
        cardPossibleChoice[4] = [42]
        if (cardNb == 7) {
          cardPossibleChoice = [...cardPossibleChoice, [], []]
          cardPossibleChoice[4] = [31]
          cardPossibleChoice[5] = [47]
          cardPossibleChoice[6] = [42]
        }
        break;

      case 4:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [0, 1, 2]
        cardPossibleChoice[1] = [14, 4, 19]
        cardPossibleChoice[2] = [28, 9]
        cardPossibleChoice[3] = [39, 32]
        cardPossibleChoice[4] = [44]
        break;

      case 6:
        cardPossibleChoice = [[], [], [], [], []]
        cardPossibleChoice[0] = [37, 49]
        cardPossibleChoice[1] = [41, 48]
        cardPossibleChoice[2] = [50, 42]
        cardPossibleChoice[3] = [44, 51]
        cardPossibleChoice[4] = [53]
        break;
    }
    return cardPossibleChoice;
  }

  static getPosibilitiesBoosterType(boosterType) {
    var posibilities = this.getPosibilities(boosterType, 5)
    var cumul = [];
    for (let i = 0; i < 5; i++) {
      cumul = [...cumul, ...posibilities[i]]
    }
    cumul = [...new Set(cumul)];
    return cumul.sort(function (a, b) {
      return a - b;
    });
  }

  static removeThisCardFromPossibility(cards, id) {
    var cardsTmp = [...cards]
    cardsTmp.forEach((element, index) => {
      cardsTmp[index] = cardsTmp[index].filter((id_) => { return id_ != id })
    });
    return cardsTmp
  }

  static filterPosibility(list) {
    var tmp = [...list]
    var filter = this.getFilter()
    for (var i = 0; i < tmp.length; i++) {
      tmp[i] = tmp[i].filter(filter)
    }
    return tmp
  }

  static getFilter() {
    var filter = [...store.state.filter]

    if (store.state.cards[16].normal + store.state.cards[16].rare > 0) {
      filter.push(36);
    }

    var tmp = 0;
    for (var i = 18; i < 36; i++) {
      if (!store.state.cards[i].new) {
        tmp++
      }
    }
    if (tmp < 9) {
      filter = [...filter, 48]
    }

    if (store.state.day) {
      filter = [...filter, 13, 20, 50]
    }
    else {
      filter = [...filter, 2]
    }
    store.dispatch('setFlagAndTurn', { name: 'FILTER', value: [] })
    return (id) => {
      return !filter.includes(id)
    }
  }

  static completeIfEmpty(list) {
    var listTmp = [...list]
    for (var i = listTmp.length - 1; i >= 0; i--) {
      if (listTmp[i].length == 0 && i != listTmp.length - 1) {
        listTmp[i] = [listTmp[i + 1][Math.floor(Math.random() * listTmp[i + 1].length)]]
      }
    }
    return listTmp
  }

  static MultiplyId(list, idRobot) {
    var listCpy = [...list]
    for (var i = 0; i < list.length; i++) {
      if (list[i].filter((_id) => _id == idRobot.id).length > 0) {
        for (var multi = 0; multi < idRobot.multiply; multi++) {
          listCpy[i] = [...listCpy[i], idRobot.id]
        }
      }
    }
    return listCpy
  }
}