<template>
  <div class="dark-background">
    <div :class="{'container-safe' : !isMobile , 'container-safe-mobile' : isMobile}">
      <div class="answer-container">
        <div class="answer-safe">
          {{ this.answer.charAt(0) }}
        </div>
        <div class="answer-safe">
          {{ this.answer.charAt(1) }}
        </div>
        <div class="answer-safe">
          {{ this.answer.charAt(2) }}
        </div>
        <div class="answer-safe">
          {{ this.answer.charAt(3) }}
        </div>
      </div>
      <img
        src="../assets/safe/back.png"
        alt="safe door"
        :class="{'safe-image-back' : !isMobile , 'safe-image-back-mobile' : isMobile}"
      />
      <img
        src="../assets/safe/numb.png"
        alt="safe door"
        class="safe-image-numb"
        :class="{mobile : isMobile}"
        id="numb"
        @click="PushNumber"
      />
      <div class="arrow-safe-container">
        <img
          src="@/assets/safe/arrow-right.png"
          alt=""
          class="arrow-safe"
          @click="turnNumb(-1)"
          :class="{ 'arrow-safe-innactive': index == 0 && angle == 0 }"
        />
        <img
          src="@/assets/safe/arrow-left.png"
          alt=""
          class="arrow-safe"
          @click="turnNumb(1)"
          :class="{ 'arrow-safe-innactive': index == 3 }"
        />
      </div>
      <div class="action-safe-container">
        <Button :size=" isMobile ? 'small':'xl'" @click="reset">Reset</Button>
        <Button :size="isMobile ? 'small':'xl'" :active="this.index == 3" @click="validate">
          Validate
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/button";
import mobile from "is-mobile";

export default {
  data() {
    return {
      num: 0,
      currentDirection: 1,
      answer: "0   ",
      answerList: ["0", " ", " ", " "],
      index: 0,
      angle: 0,
    };
  },
  props: {
    next: Function,
    steps: Object,
  },
  components: {
    Button,
  },
  mounted() {
    this.imgNumb = document.getElementById("numb");
  },
  methods: {
    ...mapActions(["saveCard"]),
    turnNumb(direction) {
      this.num = (((this.num + direction) % 10) + 10) % 10;
      this.angle -= direction;
      this.imgNumb.style.transform = "rotate(" + this.angle * 36 + "deg)";
      if (direction != this.currentDirection) {
        this.currentDirection = direction;
        this.index += 1;
      }
      this.answerList[this.index] = this.num.toString();
      this.answer = this.answerList.join("");
    },
    reset() {
      this.imgNumb.style.transform = "rotate(" + 0 + "deg)";
      this.num = 0;
      this.currentDirection = 1;
      this.answer = "0   ";
      this.answerList = ["0", " ", " ", " "];
      this.index = 0;
      this.angle = 0;
    },
    validate() {
      if (this.getSafeCode === this.answer) {
        this.next({
          selector: null,
          images: null,
          callback: () => {
            this.saveCard({ id: 26, rare: this.steps.card.rare });
            return {
              message: "The door unlock and you find the remains of a man.",
              cards: [{ id: 26, rare: this.steps.card.rare }],
            };
          },
          sounds: null,
        });
      } else {
        this.next({
          selector: null,
          images: null,
          callback: () => {
            return { message: "Wrong code." };
          },
          sounds: null,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getSafeCode"]),
    isMobile() {
      return mobile();
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-container {
  width: 60%;
  height: 10%;
  position: absolute;
  top: 12%;
  left: 48%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-safe {
  height: 10vh;
  width: 10vh;
  border-bottom: solid 1vh black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10vh;
  font-family: "Balsamiq Sans", cursive;
}

.container-safe {
  height: 100%;
  position: relative;
}

.container-safe-mobile {
  width: 100%;
  position: relative;
}

.safe-image-back {
  height: 100%;
}

.safe-image-back-mobile {
  width: 100%;
}

.safe-image-numb {
  position: absolute;
  left: 26%;
  top: 28%;
  width: 44vh;
  transition: transform 0.8s ease-in-out;
}

.safe-image-numb.mobile {
  width: 24vh !important;
}

.arrow-safe-container {
  width: 80%;
  height: 20%;
  position: absolute;
  bottom: 40%;
  left: 48%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-safe-container {
  width: 75%;
  height: 10%;
  position: absolute;
  bottom: 12%;
  left: 48%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-safe {
  width: 20%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(136, 136, 136);
  border-radius: 8px;
  font-size: 3vh;
  font-family: "Balsamiq Sans", cursive;
  cursor: pointer;
}

.btn-safe-inactive {
  opacity: 0.5;
  pointer-events: none;
}

.arrow-safe {
  width: 15%;
  z-index: 4;
  cursor: pointer;
}

.arrow-safe-innactive {
  opacity: 0.5;
  pointer-events: none;
}
</style>