<template>
  <div class="dark-background">
    <Selector v-if="stepShown === 'select' && !isMobile" :steps="steps" :hide="hide" :validateCards="validateCards" :cards="cards"/>
    <SelectorMobile v-if="stepShown === 'select' && isMobile" :steps="steps" :hide="hide" :validateCards="validateCards" :cards="cards"/>
    <img
      :src="require(`@/assets/scene_image/${image}`)"
      alt="images"
      v-if="stepShown === 'images'"
      class="image-scene"
    />
    <div class="message-action" v-if="stepShown === 'message'">
      <p class="title-action" :class="{ mobile: isMobile }">
        {{ ending.message }}
      </p>
      <div class="test">
        <div
          class="action-card-container"
          :class="{ mobile: isMobile && endingCards.length > 1 }"
          v-if="ending.cards && ending.cards.length > 0"
        >
          <div
            class="action-card-wrapper"
            v-for="(card, index) in endingCards"
            :key="card.id"
            :id="index"
          >
            <img
              src="../assets/arrow.png"
              alt="arrow compare"
              class="arrow-compare-action"
              v-if="ending.compare == true && card.arrow == true"
            />
            <Card :card="card" class="action-card" />
          </div>
        </div>
      </div>

      <Button class="btn-action" @click="end">Go back</Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import infoCard from "@/assets/card.json";
import Card from "@/components/card";
import Selector from "@/components/selector"
import SelectorMobile from "@/components/selectorMobile"
import Button from "@/components/button";
import mobile from "is-mobile";

export default {
  data() {
    return {
      stepShown: "",
      message: null,
      imgArray: null,
      image: "empty.png",
      callback: null,
      cards: null,
      selected: [],
      cardPreview: null,
    };
  },
  components: {
    Card,
    Button,
    Selector,
    SelectorMobile,
  },
  props: {
    steps: Object,
    hide: Function,
    next: Function,
  },
  mounted() {
    this.init();
    this.initScroll();
  },
  methods: {
    validateCards(selected) {
      this.selector.action(selected);
    },
    initScroll() {
      setTimeout(() => {
        try {
          document.getElementById("1").scrollIntoView({ inline: "center" });
        } catch (e) {
          return;
        }
      }, 10);
    },
    end() {
      if (this.steps.past) {
        this.next(this.cardsCallback);
      }
			else{
				this.hide();
			}
    },
    initSelector() {
      this.cards = [...this.getCards].filter(this.steps.selector.filter);
      this.cards = this.cards.map((c) => {
        return { ...c };
      });
      var cardCpy = [];
      var key = 0;
      this.cards.forEach((c) => {
        if (this.steps.selector.clue) {
          c.normal = 1;
        }

        if (c.normal > 0) {
          cardCpy.push({
            key: key,
            id: c.id,
            rare: false,
            nb: this.steps.selector.noMultiple ? 1 : c.normal,
            visible: true,
            new: c.new,
          });
          key++;
        }
        if (c.rare > 0 && !this.steps.selector.clue) {
          cardCpy.push({
            key: key,
            id: c.id,
            rare: true,
            nb: this.steps.selector.noMultiple ? 1 : c.rare,
            visible: true,
            new: c.new,
          });
          key++;
        }
      });
      this.cards = cardCpy;
    },
    init() {
      this.selector = this.steps.selector;
      this.images = this.steps.images;
      this.callback = this.steps.callback;
      this.sounds = this.steps.sounds;

      if (this.selector) {
        this.initSelector();
        this.stepShown = "select";
        this.selector.action = (selected) => {
          this.cardsCallback = [];
          var sum = 0;
          selected.forEach((card) => {
            sum += (card.rare ? infoCard[card.id].value *2 : infoCard[card.id].value) * card.nb
            if (card.nb > 0) {
							for(let i = 0 ; i< card.nb ; i++){
								this.cardsCallback.push({ id: card.id, rare: card.rare });			
							}
            }
          });
          if (!this.steps.selector.moneySum) {
            this.ending = this.callback(this.cardsCallback);
          } else {
            this.ending = this.callback({
              cards: this.cardsCallback,
              nb: Math.floor(sum / this.getCardValue) >= 3 ? 3 : Math.floor(sum / this.getCardValue),
            });
          }
          if (this.images) {
            this.stepShown = "images";
            this.playImages(this.images);
          } else {
            this.showMessage();
          }
        };
      } else if (this.images) {
        this.stepShown = "images";
        this.ending = this.callback();
        this.playImages(this.images);
      } else {
        this.showMessage();
        this.ending = this.callback();
      }
    },
    playImages(imgSettings) {
      this.preloadImage(imgSettings.list)
      imgSettings.list.forEach((nameImage, index) => {
        setTimeout(() => {
          this.image = nameImage;
        }, imgSettings.speed * index);
      });
      setTimeout(() => {
        this.showMessage();
      }, imgSettings.speed * imgSettings.list.length);
    },
    preloadImage(urls)
    {
      urls.forEach(url => {
        var img=new Image();
        img.src=url;
      })
    },
    showMessage() {
      this.stepShown = "message";
    },
  },
  watch:{
    selected(){
      console.log('changed')
    }
  },
  computed: {
    ...mapGetters(["getCards", "getCardScan"]),
    isMobile() {
      return mobile();
    },
    getInfos() {
      return infoCard;
    },
    endingCards() {
      return this.ending.cards.map((e, index) => {
        return index == 1 ? { ...e, arrow: true } : e;
      });
    },
    nbCardSelected() {
      var nb = 0;
      this.selected.forEach((c) => {
        nb += c.nb;
      });
      return nb;
    },
    getSum() {
      var sum = 0;
      this.selected.forEach((card) => {
        if (card.nb > 0) {
          sum +=
            (card.rare
              ? infoCard[card.id].value * 2
              : infoCard[card.id].value) * card.nb;
        }
      });
      return sum;
    },
    getCardValue() {
      return this.getCardScan.rare
        ? infoCard[this.getCardScan.id].value * 2
        : infoCard[this.getCardScan.id].value;
    },
    getValidationSelector() {
      return (
        this.steps.selector.nb - this.nbCardSelected == 0 ||
        (this.steps.selector.moneySum && this.getSum >= this.getCardValue)
      );
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selector.mainContainer {
  height: 500px;
  width: 684px;
  font-family: "Balsamiq Sans", cursive;
}

.selector.title {
  font-size: 24px;
  color: white;
}

.btn-action {
  margin-top: 10px;
}

.selector.wrapper {
  height: 467px;
  width: 100%;
  border-radius: 8px;
  background: #22222265;
  padding: 5px;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selector.cardList {
  height: 100%;
  width: 45%;
  border-radius: 8px;
}

.selector.cardWrapper {
  height: 46px;
  width: 95%;
  border-radius: 8px;
  margin: 8px;
  margin-bottom: 0px;
  background-color: beige;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.selector.cardWrapper.rare {
  background-color: rgb(255, 181, 196);
}

.selector.scrollBox {
  height: 369px;
  width: 100%;
  background: #d8bc8917;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selector.hideCard {
  display: none;
}

.selector.numberWrapper {
  width: 46px;
  height: 70%;
  border-radius: 8px;
  background-color: black;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selector.numberWrapper span {
  color: white;
  font-size: 18px;
  font-family: "Balsamiq Sans", cursive;
}

.selector.id {
  width: 36px;
  height: 100%;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selector.name {
  flex: 1;
  height: 90%;
  color: black;
  font-size: 17px;
  border-left: 1px solid;
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.selector.cardWrapper img {
  width: 46px;
}

.selector.wrapper img {
  width: 30px;
  height: 30px;
}

.selector.btnWrapper {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
}

.selector.selector.btnWrapper span {
  color: white;
  margin-right: 10px;
  font-size: 18px;
}

.selector.btnWrapper.right {
  flex-direction: row-reverse;
}

.selector button {
  height: 40px;
  width: 147px;
  border-radius: 8px;
  font-size: 18px;
  font-family: "Balsamiq Sans", cursive;
  cursor: pointer;
}

.selector.coin {
  margin-right: 5px;
  margin-left: 5px;
}

.selectorLoading {
  position: absolute;
  height: 100%;
  width: 20px;
  top: 0px;
  right: -60px;
}

.cardValue {
  position: absolute;
  color: white;
  display: flex;
  right: -55px;
}

.cardTotal {
  position: absolute;
  color: white;
  display: flex;
  font-size: 20px;
  bottom: 23px;
  align-items: center;
}

.cardTotalCoin {
  width: 25px !important;
  height: 25px !important;
}

.cardValue.oneValue {
  bottom: 40%;
}

.cardValue.twoValue {
  bottom: 65%;
}

.cardValue.threeValue {
  bottom: 90%;
}

.loadBar.container {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: solid 1px;
  overflow: hidden;
  border-radius: 8px;
}
.loadBar.bar {
  height: 0%;
  width: 100%;
  background-color: aqua;
}

.selector.cancel {
  background-color: crimson;
}

.selector.ok {
  background-color: ivory;
}

.selector.remove {
  animation: 0.2s linear remove;
}

.selector.add {
  animation: 0.2s linear add;
}

.selector.add2 {
  animation: 0.2s linear add2;
}

.selector.remove2 {
  animation: 0.2s linear remove2;
}

@keyframes remove {
  from {
    margin-left: 8px;
  }
  80% {
    margin-left: 100%;
  }
  to {
    height: 0%;
    margin-left: 100%;
  }
}

@keyframes remove2 {
  from {
    margin-left: 8px;
  }
  80% {
    margin-left: -100%;
  }
  to {
    height: 0%;
    margin-left: -100%;
  }
}

@keyframes add {
  from {
    height: 0%;
    margin-left: 100%;
  }
  20% {
    height: 46px;
    margin-left: 8px;
  }
  to {
    height: 46px;
    margin-left: 8px;
  }
}

@keyframes add2 {
  from {
    height: 0%;
    margin-left: -100%;
  }
  20% {
    height: 46px;
    margin-left: 8px;
  }
  to {
    height: 46px;
    margin-left: 8px;
  }
}

.hidden {
  visibility: hidden;
}

.arrow-compare-action {
  height: 100px;
  margin-top: 70px;
}

.action-card-container {
  height: 240px;
  margin: 10px;
  display: flex;
  overflow-x: auto;
}

.preview-card-container {
  left: -300px;
  top: 70px;
  position: absolute;
  height: 350px;
}

.preview-card {
  height: 100%;
}

.test {
  width: 100vw;
}

.action-card-container {
  display: flex;
  justify-content: center;
}

.action-card-container.mobile {
  justify-content: start !important;
}

.action-card-wrapper {
  margin: 0px 20px 0px 20px;
  display: flex;
}

.action-card {
  height: 100%;
}

.card-select-container {
  width: 422px;
  height: 500px;
  position: relative;
}

.image-scene {
  height: 80%;
}

.message-action {
  font-size: 22px;
  width: 600px;
  font-family: "Balsamiq Sans", cursive;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}

.title-action {
  color: white;
}

.title-action.mobile {
  text-align: center;
}

.btn-done {
  color: white;
  height: 60px;
  width: 200px;
  background-color: teal;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.square-select {
  height: 40px;
  width: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
}

.square-desable {
  background-color: rgb(250, 239, 225);
  pointer-events: none;
}

.square-active {
  background-color: rgb(73, 73, 73) !important;
}

.square-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.check {
  width: 100%;
  height: 100%;
}

.btn-inactive {
  pointer-events: none;
  opacity: 0.5;
}

.scrollBox::-webkit-scrollbar {
  width: 11px;
  background-color: rgba(16, 136, 255, 0);
}
.scrollBox::-webkit-scrollbar:horizontal {
  height: 11px;
}
.scrollBox::-webkit-scrollbar-track {
  border: 1px rgba (16, 137, 255, 0.1) solid;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 6px rgba(16, 136, 255, 0) inset;
  box-shadow: 0px;
}
.scrollBox::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 1px solid #10367a;
  border-radius: 16px;
}
</style>