import * as PIXI from 'pixi.js'
import Card from '../pixi_obj/card'
import store from './../store'
import assetLoader from "../assetLoader";

const widthBooster = 300 
const heightBooster = 460 

export default class Booster {
  constructor(app, values, open, resetShop, boosterName) {
    //settings
    this.app = app
    this.openingSpeed = 25
    this.open = open
    this.cracked = false
    this.cardSpacing = -3
    this.resetShop = resetShop

    //states
    this.state = this.idleState
    this.cardState = []
    this.lastClicked = Date.now()
    this.loader = PIXI.Loader.shared

    //pixi object
    this.container = new PIXI.Container()
    this.cardContainer = new PIXI.Container()

    if (!this.open) {
 
      this.boosterSprite = new PIXI.Sprite(
        this.loader.resources[boosterName].texture
      )
      this.boosterSpriteCut = new PIXI.Sprite(
        this.loader.resources[boosterName].texture
      )
      this.boosterSpriteCut2 = new PIXI.Sprite(
        this.loader.resources[boosterName].texture
      )
      this.boosterSprite.height = heightBooster
      this.boosterSprite.width = widthBooster
      this.boosterSpriteCut.height = heightBooster
      this.boosterSpriteCut.width = widthBooster
      this.boosterSpriteCut2.height = heightBooster
      this.boosterSpriteCut2.width = widthBooster
    }

    this.container.height = heightBooster
    this.container.width = widthBooster
    this.container.interactive = true

    this.cardContainer.x = widthBooster / 2
    this.cardContainer.y = heightBooster / 2
    this.cardContainer.sortableChildren = true

    this.addCard(values).then(() => {
      //flip card action
      this.container.on('pointerdown', () => {
        this.clickContainer()
      })

      this.container.addChild(this.cardContainer)

      if (!this.open) {
        this.container.addChild(this.boosterSprite)
        this.container.addChild(this.boosterSpriteCut)
        this.container.addChild(this.boosterSpriteCut2)
      }

      this.app.ticker.add((delta) => this.gameLoop(delta))
    })
  }

  clickContainer() {
    if (Date.now() - this.lastClicked > 300) {
      this.lastClicked = Date.now()
      var cardNb = this.cardObjectContainer.length
      if (!this.open) {
        this.mask = new PIXI.Graphics().beginFill(0xffffff).drawPolygon([0, 50, 0, heightBooster, widthBooster + 10, heightBooster, widthBooster + 10, 50]).endFill();
        this.mask2 = new PIXI.Graphics().beginFill(0xffffff).drawPolygon([-10, 0, 0, 50, widthBooster + 10, 50, widthBooster + 10, 0]).endFill();
        this.boosterSprite.mask = this.mask
        this.boosterSpriteCut.mask = this.mask2
        this.container.addChild(this.mask)
        this.container.addChild(this.mask2)
        this.mask.y = 0

        this.mask3 = new PIXI.Graphics().beginFill(0xffffff).drawPolygon([-10, 0, 0, 50, widthBooster + 10, 50, widthBooster + 10, 0]).endFill();
        this.container.addChild(this.mask3)
        this.boosterSpriteCut2.mask = this.mask3
        this.boosterSpriteCut2.anchor.set(0)
        this.boosterSpriteCut2.rotation = -0.10
        this.mask3.rotation = -0.10

        this.boosterSpriteCut2.x = -widthBooster + 5
        this.boosterSpriteCut2.y = 30
        this.mask3.x = 0
        this.mask3.y = -5

        this.state = this.openPack
        this.open = true
        store.dispatch('openBooster')
      } else {
        var indexCard = 0
        while (this.cardState[indexCard] === 'gone') {
          indexCard += 1
        }

        if (this.cardState[indexCard] === 'close') {
          this.cardObjectContainer[cardNb - indexCard - 1].flip()
          this.cardState[indexCard] = 'open'
        } else {
          this.cardObjectContainer[cardNb - indexCard - 1].destroy()
          this.cardState[indexCard] = 'gone'
          store.dispatch('removeCardBooster')
          if (
            indexCard === this.cardState.length - 1 &&
            this.cardState[indexCard] === 'gone'
          ) {
            this.destroy()
            this.resetShop()
          }
        }
      }
    }
  }

  async addCard(values) {
    const Loader = new assetLoader();
    this.cardObjectContainer = []

    var idList = []

    for (let i = 0; i < values.length; i++) {
      let cardNumber = values[i].id
      if (!store.state.loadedCardIllu.includes(cardNumber)) {
        store.dispatch("loadIllu", cardNumber)
        idList.push(cardNumber)
      }
    }

    await Loader.loadCards(idList)

    for (let i = 0; i < values.length; i++) {
      let cardNumber = values[i].id
      var rare = values[i].rare

      this.cardState.push('close')
      var imageTexture = this.loader.resources['cardIllu' + cardNumber.toString()] ? this.loader.resources['cardIllu' + cardNumber.toString()].texture : this.loader.resources['undefinedCard'].texture
      var card = new Card(
        this.app,
        cardNumber,
        imageTexture,
        values[i].new,
        rare,
      )
      card.setPosition(0, i * this.cardSpacing)
      this.cardObjectContainer.push(card)
      card.getContainer().zIndex = i
      this.cardContainer.addChild(card.getContainer())
    }
  }
  async modifyCard(value, position) {
    const Loader = new assetLoader();
    if (!store.state.loadedCardIllu.includes(value.id)) {
      await Loader.loadCards([value.id])
      store.dispatch("loadIllu", value.id)
    }
    var imageTexture = PIXI.Loader.shared.resources['cardIllu' + value.id.toString()] ? PIXI.Loader.shared.resources['cardIllu' + value.id.toString()].texture : PIXI.Loader.shared.resources['undefinedCard'].texture
    var card = new Card(
      this.app,
      value.id,
      imageTexture,
      false,
      value.rare,
    )
    this.cardObjectContainer[position].destroy()
    this.cardObjectContainer[position] = card
    card.setPosition(0, position * this.cardSpacing)
    card.getContainer().zIndex = position
    this.cardContainer.addChild(card.getContainer())
  }

  gameLoop(delta) {
    this.state(delta)
  }

  openPack(delta) {
    if (this.cracked) {
      this.boosterSprite.y += this.openingSpeed * delta
      this.mask.y += this.openingSpeed * delta
      if (this.boosterSprite.y > this.app.height) {
        this.state = this.idleState
      }
    }
    else {
      if (this.mask2.x > widthBooster + 40) {
        this.cracked = true
      }
      this.mask2.x = this.mask2.x + 1 * 15
      this.boosterSpriteCut2.x = this.boosterSpriteCut2.x + 2 * 15
      this.mask3.x = this.mask3.x + 1 * 15
      this.boosterSpriteCut2.y = this.boosterSpriteCut2.y - 0.13 * 15
    }
  }

  idleState() { }

  getContainer() {
    return this.container
  }

  setPosition(x, y) {
    this.container.x = x
    this.container.y = y
  }

  destroy() {
    try {
      this.container.parent.removeChild(this.container)
    }
    catch (e) {
      console.log('')
    }

  }
}
