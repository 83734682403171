<template>
  <div class="foot-container">
    <div :class="{ footer: !isMobile, 'footer-mobile': isMobile }"></div>
    <div
      v-for="shop in shops"
      :key="shop.id"
      :class="{ 'price-tag': !isMobile, 'price-tag-mobile': isMobile }"
    >
      <span
        v-if="!catalogState"
        :class="{
          'price-tag-value': !isMobile,
          'price-tag-value-mobile': isMobile,
        }"
        >{{ shop.price }}</span
      >
      <div
        v-if="!catalogState"
        :class="{ coin: !isMobile, 'coin-mobile': isMobile }"
      ></div>
    </div>
    <div
      :class="{ 'money-counter': !isMobile, 'money-counter-mobile': isMobile }"
    >
      <div
        :class="{
          'wheel-money-container': !isMobile,
          'wheel-money-container-mobile': isMobile,
        }"
      >
        <img
          id="wheel"
          :src="
            require(`@/assets/${
              getWorkerResetNb > 0 ? 'wheelFast' : 'wheel'
            }.png`)
          "
          alt="wheel"
          class="wheel-money"
          :class="{
            rotating:
              getMoney != getMaxMoney &&
              (getWorkerResetNb == null || getWorkerResetNb == 0),
            rotatingFast: getMoney != getMaxMoney && getWorkerResetNb > 0,
          }"
        />
      </div>
      <span
        :class="{ 'money-text': !isMobile, 'money-text-mobile': isMobile }"
        id="money-level-text"
        @click="moneyError"
        >{{ getMoney + "/" + getMaxMoney }}</span
      >
      <div
        :class="{ coin: !isMobile, 'coin-mobile': isMobile }"
        id="coin-twitch"
        @click="twitch"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mobile from "is-mobile";
export default {
  props: {
    catalogState: Boolean,
  },
  computed: {
    ...mapGetters([
      "getMoney",
      "getShopPrices",
      "getShops",
      "getMaxMoney",
      "getWorkerResetNb",
    ]),
    isMobile() {
      return mobile();
    },

    shops() {
      var prices = [];
      this.getShops.forEach((shopName, index) => {
        prices.push({
          price: this.getShopPrices[shopName],
          id: index,
          name: shopName,
        });
      });
      return prices;
    },
    moneyWatch() {
      return this.getMoney;
    },
  },
  mounted() {
    window.addEventListener("focus", () => {
      if (this.getMoney != this.getMaxMoney) {
        this.turnWheel();
      }
    });
  },
  methods: {
    moneyError() {
      var doc = document.getElementById("money-level-text");
      doc.style.color = "#ff4949";
      doc.style.paddingBottom = "10px";
      setTimeout(() => {
        doc.style.color = "white";
        doc.style.paddingBottom = "0px";
      }, 100);
    },
    twitch() {
      var doc = document.getElementById("coin-twitch");
      doc.style.marginBottom = "10px";
      setTimeout(() => {
        doc.style.marginBottom = "0px";
      }, 100);
    },
    turnWheel() {
      var wheel = document.getElementById("wheel");
      wheel.style.transition = "";
      wheel.style.transform = "rotate(45deg)";
      setTimeout(() => {
        if (
          this.getMoney != this.getMaxMoney &&
          (this.getWorkerResetNb == null || this.getWorkerResetNb == 0)
        ) {
          wheel.style.transition = "transform 14s linear";
        } else if (this.getMoney != this.getMaxMoney && this.getWorkerResetNb) {
          wheel.style.transition = "transform 7s linear";
        } else {
          wheel.style.transition = "transform 1000s linear";
        }

        wheel.style.transform = "rotate(315deg)";
      }, 30);
    },
  },
  watch: {
    moneyWatch: function (newVal, oldVal) {
      if (newVal > oldVal) {
        this.twitch();
      }
      this.turnWheel();
    },
  },
};
</script>

<style>
.foot-container {
  height: 39px;
  width: 100%;
  background-image: url("../assets/foothead.png");
  position: absolute;
  bottom: 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.money-counter {
  position: absolute;
  height: 70px;
  width: 137px;
  background-image: url("../assets/foothead.png");
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.money-counter-mobile {
  position: absolute;
  height: 70px;
  width: 100px;
  background-image: url("../assets/foothead.png");
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.money-text {
  margin-right: 10px;
  font-weight: 600;
  font-size: 21px;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  transition: color 0.1s;
  transition: padding 0.1s;
}

.money-text-mobile {
  margin-right: 10px;
  font-weight: 600;
  font-size: 16px;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  transition: color 0.1s;
  transition: padding 0.1s;
}

.wheel-money-container {
  position: absolute;
  bottom: 10px;
  width: 120px;
  height: 120px;
  z-index: -1;
  overflow: hidden;
}

.wheel-money-container-mobile {
  position: absolute;
  bottom: 30px;
  width: 80px;
  height: 80px;
  z-index: -1;
  overflow: hidden;
}

.wheel-money {
  height: 100%;
  width: 100%;
}

.coin {
  width: 19px;
  height: 19px;
  background: #ffad0d;
  border-radius: 50%;
  transition: margin 0.1s;
}

.coin-mobile {
  width: 10px;
  height: 10px;
  background: #ffad0d;
  border-radius: 50%;
  transition: margin 0.1s;
}

.price-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-left: 10px;
}

.price-tag-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-left: 4px;
}

.price-tag-value {
  font-weight: 600;
  font-size: 21px;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  margin-right: 10px;
}

.price-tag-value-mobile {
  font-weight: 600;
  font-size: 14px;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  margin-right: 2px;
}

.footer-mobile {
  width: 10px;
}

.footer {
  width: 10px;
}
</style>