<template>
  <div class="dark-background">
    <div :class="{'copy' : true , 'container' : !isMobile , 'containerMobile' : isMobile}">
      <div class="choiceCopy container">
        <img
          src="../assets/copyMachine/scan.png"
          alt="scanner"
          class="copy image"
        />
        <Button :size="isMobile ? 'small' : null" @click="scan()">Scan</Button>
      </div>
      <div class="choiceCopy container">
        <Card
          v-if="getCardScan"
          :card="{ id: getCardScan.id, rare: getCardScan.rare }"
          :class="{'cardScan' : !isMobile ,'cardScanMobile' : isMobile}"
        />
        <img
          src="../assets/copyMachine/print.png"
          alt="printer"
          class="copy image"
        />

        <Button :size="isMobile ? 'small' : null" :active="!!getCardScan" @click="print()"> Print </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import infoCard from "@/assets/card.json";
import Card from "@/components/card";
import Button from "@/components/button";
import mobile from "is-mobile";
export default {
  props: {
    next: Function,
    steps: Object,
  },
  components: {
    Card,
    Button,
  },
  methods: {
    ...mapActions(["setFlagAndTurn", "burnCard", "saveCard"]),
    scan() {
      var callback = (cards) => {
        this.setFlagAndTurn({ name: "SCAN_CARD", value: cards[0] });
        this.burnCard(cards[0]);
        this.burnCard(this.steps.card);
        return {
          message: "This card is saved in the printer, you can now print it.",
          cards: [cards[0]],
        };
      };

      var selector = {
        filter: (c) => c.id != 53 && c.normal + c.rare > 0,
        nb: 1,
        message: "Choose a card you want to save, then print it later.",
      };

      this.next({ selector, callback });
    },
    print() {
      var callback = ({ cards, nb }) => {
        this.burnCard(this.steps.card);
        cards.forEach((card) => {
          this.burnCard(card);
        });

        var cardsDisplay = [];
        for (var i = 0; i < nb; i++) {
          this.saveCard(this.getCardScan);
          cardsDisplay.push(this.getCardScan);
        }
        return { message: "fresh from the printer!", cards: cardsDisplay };
      };

      var selector = {
        filter: (c) => c.normal + c.rare > 0 && c.id != 53 && c.id != 19 && c.id != 31,
        nb: 100000,
        moneySum: true,
        message: `Select Cards that together are worth at least the value of the card ${
          infoCard[this.getCardScan.id].name
        }`,
      };
      this.next({ selector, callback: callback });
    },
  },
  computed: {
    ...mapGetters(["getCardScan"]),
    isMobile() {
      return mobile();
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy.container {
  max-width: 800px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.copy.containerMobile {
  max-width: 800px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.choiceCopy.container {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.buttonCopy {
  width: 70%;
  height: 46px;
  color: black;
  cursor: pointer;
  background: coral;
  border-radius: 8px;
  font-size: 22px;
  font-family: "Balsamiq Sans", cursive;
}

.buttonCopy.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.copy.image {
  width: 100%;
  z-index: 2;
}

.cardScanMobile {
  position: absolute;
  height: 30vw;
}

.cardScan {
  position: absolute;
  height: 220px;
}
</style>