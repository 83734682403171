<template>
  <div class="dark-background">
    <div class="pastContainer" :class="{'mobile' : isMobile}">
      <img
        src="../assets/booster5.png"
        alt="boost"
        class="pastBooster"
        id="boosterPast"
      />
        <card
          :card="cards[0]"
          id="cardFirst"
          v-if="cardsDisplayed"
          class="pastCard cardUp"
        />

        <card
          :card="cards[1]"
          id="cardSecond"
          v-if="cardsDisplayed"
          class="pastCard cardUp"
        />

        <card
          :card="cards[2]"
          id="cardThird"
          v-if="cardsDisplayed"
          class="pastCard cardUp"
        />


      <img src="" alt="" />
      <img src="../assets/clock/smoke.png" alt="" class="smoke1" id="smoke1" />
      <img src="../assets/clock/smoke.png" alt="" class="smoke2" id="smoke2" />
      <img src="../assets/clock/clock.png" alt="" class="clock" id="clock3" />
      <img
        src="../assets/clock/clockBig.png"
        alt=""
        class="clock"
        id="clock1"
      />
      <img
        src="../assets/clock/clockSmall.png"
        alt=""
        class="clock"
        id="clock2"
      />
    </div>
  </div>
</template>

<script>
import card from "./card.vue";
import { mapActions } from "vuex";
import mobile from "is-mobile";
export default {
  components: { card },
  data() {
    return {
      cardsDisplayed: "something",
    };
  },
  props: {
    cards: Object,
    hide: Function,
    catalogSwitch: Function,
  },
  mounted() {
    var card1 = document.getElementById("cardFirst");
    var card2 = document.getElementById("cardSecond");
    var card3 = document.getElementById("cardThird");

    var clock1 = document.getElementById("clock1");
    var clock2 = document.getElementById("clock2");
    var clock3 = document.getElementById("clock3");
    var boosterPast = document.getElementById("boosterPast");
    var smoke1 = document.getElementById("smoke1");
    var smoke2 = document.getElementById("smoke2");

    setTimeout(() => {
      card1.classList.remove("cardUp");
    }, 1000);

    setTimeout(() => {
      card2.classList.remove("cardUp");
    }, 1500);

    setTimeout(() => {
      card3.classList.remove("cardUp");
    }, 2000);

    setTimeout(() => {
      clock1.style.animation = "spin2 3s infinite linear";
      clock2.style.animation = "spin2 1s infinite linear";
      clock3.style.animation = "spin2 50s infinite linear";
      boosterPast.style.animation = "spin2 50s infinite linear";

      this.cardsDisplayed = null;

      smoke1.style.opacity = 0.5;
      smoke2.style.opacity = 0.5;

      this.resetGame(this.cards);
    }, 3000);

    setTimeout(() => {
      this.catalogSwitch();
      this.hide();
    }, 6000);
  },
  methods: {
    ...mapActions(["resetGame"]),
  },
  computed:{
    isMobile() {
      return mobile();
    },
  }
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pastContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pastContainer.mobile {
  transform: scale(1.9);
}

.pastBooster {
  position: absolute;
  width: 20%;
  z-index: 1;
}

.pastCard {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 1s margin;
  height: 27vw;
  left: 50%;
  top: 50%;
}

.cardUp {
  margin-top: -100% !important;
}

.clock {
  position: absolute;
  z-index: 1;
  width: 25%;
}

.smoke2 {
  position: absolute;
  width: 40%;
  animation: spin 9s infinite linear;
  opacity: 0;
  transition: opacity 1s;
}

.smoke1 {
  position: absolute;
  width: 50%;
  animation: spin 15s infinite linear;
  opacity: 0;
  transition: opacity 1s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>