import { createStore } from 'vuex'
import * as workerTimers from 'worker-timers';
import local from './localSave'
import boosterGenerator from '../boosterGenerator'
import shopInfo from '../assets/shop.json'
import assetLoader from "../assetLoader";

export default createStore({
  state: {
    money: 35,
    intervalMoney: 14000,
    workerID: null,
    stageBoosterCards: [{ id: 29, rare: false, new: true }, { id: 14, rare: false, new: true }, { id: 5, rare: true, new: true }, { id: 4, rare: false, new: true }, { id: 0, rare: false, new: true }],
    boosterNb: 0,
    boosterState: false,
    shops: ['woodStand'],
    shopPrices: { woodStand: 30, chest: 50, stand: 70, single0: 0, bark: 0, final: 0 },
    loaded: false,
    cards: [],
    newCard: [],
    modifyBooster: null,
    modifyShop: null,
    resetBooster: null,
    filter: [],
    shopItems: ['venus', 'level2', 'moist'],
    safeCode: '    ',
    loadingScreen: true,
    loadedCardIllu: [],
    cheat: false,
    totalTime: 0,

    day: true,
    malediction: false,
    multiplyValueNb: 0,
    workerResetNb: null,
    factoryAccident: 0,
    diceLuck: 0,
    maxMoney: 100,
    dogBooster: -1,
    idRobot: null,
    blessing: null,
    excavation: null,
    cupBall: 5,
    cardScan: null,
    lastCard: false,
    endingMessage: false,
  },
  actions: {
    initData({ commit, dispatch, state }) {
      let cards = []
      if (!state.loaded) {
        var save = local.getSavedObjects()
        if (!save) {
          for (var i = 0; i <= 53; i++) {
            cards.push({ id: i, normal: 0, rare: 0, new: true, clue: false, boosters: [] })
          }
          let b1 = boosterGenerator.getPosibilitiesBoosterType(0);

          b1.forEach(card => {
            cards[card].boosters.push(0);
          });

          let b2 = boosterGenerator.getPosibilitiesBoosterType(1);

          b2.forEach(card => {
            cards[card].boosters.push(1);
          });

          let b3 = boosterGenerator.getPosibilitiesBoosterType(2);

          b3.forEach(card => {
            cards[card].boosters.push(2);
          });

          commit('SET_CARDS', cards)
          var possibilities = [1, 2, 3, 4, 5, 6, 7, 8, 9]
          var code = possibilities[Math.floor(Math.random() * possibilities.length)].toString()
          code += Math.floor(Math.random() * 10).toString()
          code += Math.floor(Math.random() * 10).toString()
          code += Math.floor(Math.random() * 10).toString()
          commit('SET_SAFE_CODE', code)
          dispatch('generateExcavation')
        }
        else {
          commit('LOAD_DATA', save)
        }
      }
      commit('SET_LOADED', true)
    },
    generateExcavation({ commit }) {
      var option = [
        [
          [1, 0, 0],
          [0, 1, 0],
          [0, 1, 0]
        ],
        [
          [0, 1, 1],
          [0, 0, 1],
          [0, 0, 1]
        ],
        [
          [1, 0, 0],
          [1, 1, 0],
          [0, 1, 1]
        ],
      ]

      var septreType = Math.floor(Math.random() * 3)
      var septreRotation = Math.floor(Math.random() * 4)
      var septreX = Math.floor(Math.random() * 3)
      var septreY = Math.floor(Math.random() * 3)

      for (var i = 0; i < septreRotation; i++) {
        option[septreType] = option[septreType][0].map((val, index) => option[septreType].map(row => row[index]).reverse())
      }

      var setup = {
        grid: [
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
        ],
        solution: [
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
        ],
        septre: {
          id: septreType,
          direction: septreRotation,
          position: { x: septreX, y: septreY },
        },
      }

      for (var x = 0; x < 3; x++) {
        for (var y = 0; y < 3; y++) {
          setup.solution[y + septreY][x + septreX] = option[septreType][y][x]
        }
      }

      var coin = false;
      while (!coin) {
        var xPositionCoin = Math.floor(Math.random() * 5)
        var YPositionCoin = Math.floor(Math.random() * 5)

        if (setup.solution[YPositionCoin][xPositionCoin] == 0) {
          coin = true
          setup.solution[YPositionCoin][xPositionCoin] = 3
        }
      }

      var diamond = false;
      while (!diamond) {
        var xPositionDiamond = Math.floor(Math.random() * 5)
        var YPositionDiamond = Math.floor(Math.random() * 5)

        if (setup.solution[YPositionDiamond][xPositionDiamond] == 0) {
          diamond = true
          setup.solution[YPositionDiamond][xPositionDiamond] = 2
        }
      }

      commit('SET_EXCAVATION', setup)
    },
    initMoneyTimer({ commit, state, dispatch }) {
      if (!state.workerID) {
        var intervalId = workerTimers.setInterval(() => {
          commit('SET_TOTAL_TIME', state.totalTime + state.intervalMoney)
          if (state.workerResetNb && state.workerResetNb > 0 && state.blessing != 'FAST_WORKER') {
            commit('SET_WORKER_RESET', state.workerResetNb - 1)
          }
          if (state.workerResetNb == 0 && state.blessing != 'FAST_WORKER') {
            commit('SET_INTERVAL_TIME', 10000)
            commit('SET_WORKER_RESET', null)
            dispatch('changeWorker')
          }
          if (state.maxMoney > state.money) {
            commit('SET_MONEY', state.money + 1)
          }
          if (state.dogBooster > 0) {
            if (state.dogBooster - state.intervalMoney <= 0) {
              commit('SET_DOG_BOOSTER', 3600000)
              if (!state.shops.includes('bark')) {
                dispatch('addItemShop', 'bark')
              }
              state.modifyShop()
            }
            else {
              commit('SET_DOG_BOOSTER', state.dogBooster - state.intervalMoney)
            }
          }
          local.save(state)
        }, state.intervalMoney);
        commit('SET_INTERVAL_ID', intervalId)
      }
      local.save(state)
    },
    save({ state }) {
      local.save(state)
    },
    changeWorker({ commit, state, dispatch }, intervalMoney) {
      workerTimers.clearInterval(state.workerID);
      if (intervalMoney) {
        commit('SET_INTERVAL_TIME', intervalMoney)
        commit('SET_WORKER_RESET', 120)
      }
      commit('SET_INTERVAL_ID', null)
      dispatch('initMoneyTimer')
    },
    loadIllu({ commit, state }, id) {
      commit("SET_LOADED_ILLU", [id, ...state.loadedCardIllu])
    },
    saveCard({ state, dispatch, commit }, { id, rare }) {
      let cardsTmp = [...state.cards]
      if (state.cards[id].new) {
        let newCardTmp = [...state.newCard]
        newCardTmp.push(id)
        cardsTmp[id].new = false
        commit('SET_NEW_CARD', newCardTmp)
      }
      if (rare) {
        cardsTmp[id].rare += 1
      }
      else {
        cardsTmp[id].normal += 1
      }
      commit('SET_CARDS', cardsTmp)

      var allOld = true
      state.cards.forEach(cardTmp => {
        if ((cardTmp.normal + cardTmp.rare) == 0 && cardTmp.id != 53) {
          allOld = false;
        }
      });
      if (allOld && !state.lastCard) {
        console.warn('state.lastCard', state.lastCard)
        commit('SET_LAST_CARD', true);
        commit('SET_ENDING_MESSAGE', true)
        dispatch('addItemShop', 'final');
        state.modifyShop();
      }
      local.save(state)
    },
    soldCard({ state, commit }, { id, rare, value }) {
      if ((rare && state.cards[id].rare > 0) || (!rare && state.cards[id].normal > 0)) {
        var cards = [...state.cards]
        if (rare) {
          cards[id].rare--
        }
        else {
          cards[id].normal--
        }

        var valueTmp = state.money + value
        if (state.multiplyValueNb > 0) {
          commit('SET_MULTIPLY', state.multiplyValueNb - 1)
        }
        //multiplyValueNb
        commit('SET_MONEY', (valueTmp >= state.maxMoney) ? state.maxMoney : valueTmp)
        commit('SET_CARDS', cards)
        local.save(state)
      }
    },
    burnCard({ state, commit }, { id, rare }) {
      if ((rare && state.cards[id].rare > 0) || (!rare && state.cards[id].normal > 0)) {
        var cards = [...state.cards]
        if (rare) {
          cards[id].rare--
        }
        else {
          cards[id].normal--
        }
        commit('SET_CARDS', cards)
        local.save(state)
      }
    },
    clueCard({ state, commit }, id) {
      var cards = [...state.cards]
      cards[id].clue = true
      commit('SET_CARDS', cards)
    },
    async buyShopItem({ state, commit, dispatch }, item) {
      const Loader = new assetLoader();
      var emeraldSpent = shopInfo[item].price
      switch (item) {
        case 'venus':
          dispatch('saveCard', { id: 34, rare: false })
          break;
        case 'moist':
          dispatch('saveCard', { id: 31, rare: false })
          break;
        case 'level2':
          await Loader.loadBox('chest')
          dispatch('addItemShop', 'chest')
          commit('SET_SHOP_ITEMS', state.shopItems.filter(item => item != 'level2'))
          state.modifyShop()
          break;
        case 'level3':
          await Loader.loadBox('stand')
          dispatch('addItemShop', 'stand')
          commit('SET_SHOP_ITEMS', state.shopItems.filter(item => item != 'level3'))
          state.modifyShop()
          break;
        case 'boosterShop':
          dispatch('addItemShop', 'single0')
          state.modifyShop()
          break;
      }
      if (emeraldSpent > 0) {
        var cmp = 0
        while (cmp < emeraldSpent) {
          cmp++
          if (state.cards[0].normal > 0) {
            dispatch('burnCard', { id: 0, rare: false })
          }
          else {
            dispatch('burnCard', { id: 0, rare: true })
          }
        }
      }
    },
    addMoney({ state, commit }, money) {
      if (money > 0) {
        if (state.maxMoney > state.money + money) {
          commit('SET_MONEY', state.money + money)
        }
        else {
          commit('SET_MONEY', state.maxMoney)
        }
      }
      else {
        if (state.money + money < 0) {
          commit('SET_MONEY', 0)
        }
        else {
          commit('SET_MONEY', state.money + money)
        }
      }
    },
    removeCardBooster({ state, dispatch, commit }) {
      var cardTmp = [...state.stageBoosterCards];
      var card = cardTmp.pop()
      dispatch('saveCard', card)
      commit('SET_STAGE_BOOSTER', cardTmp)
      local.save(state)
    },
    changeboosterCards({ commit }, cards) {
      commit('SET_STAGE_BOOSTER', cards)
    },
    addItemShop({ state, commit }, standName) {
      var order = ['woodStand', 'chest', 'stand', 'single0', 'bark', 'final'];
      var shopCpy = [...state.shops, standName]
      commit('SET_SHOPS', order.filter((stand) => {
        return shopCpy.includes(stand)
      }))
    },
    addMirageShop({ state, commit }) {
      if (!state.shopItems.includes('boosterShop')) {
        var shopItemsCpy = [...state.shopItems, 'level3', 'boosterShop']
        commit("SET_SHOP_ITEMS", shopItemsCpy)
      }

    },
    removeShop({ commit, state }, name) {
      var shopTmp = [...state.shops]
      for (var i = 0; i < state.shops.length; i++) {
        if (state.shops[i] == name) {
          shopTmp.splice(i, 1);
          commit('SET_SHOPS', shopTmp)
          return
        }
      }
			state.modifyShop()
    },
    stageBooster({ state, commit }, { boosterID, value }) {
      var cards;
      if (state.factoryAccident > 0) {
        cards = boosterGenerator.getBooster(boosterID, 7)
        commit('SET_FACTORY_ACCIDENT', state.factoryAccident - 1)
      }
      else {
        cards = boosterGenerator.getBooster(boosterID, 5)
      }

      cards = cards.map((c) => {
        return { ...c, new: state.cards[c.id].new }
      })

      commit('SET_MONEY', state.money - value)
      commit('SET_STAGE_BOOSTER', cards)
      commit('BOOSTER_STATE', false)
      commit('SET_BOOSTER_NB', boosterID)
      local.save(state)
    },
    openBooster({ state, commit }) {
      commit('BOOSTER_STATE', true)
      local.save(state)
    },
    resetNew({ commit, state }, id) {
      if (id) {
        commit('SET_NEW_CARD', state.newCard.filter((id1) => { return id1 !== id }))
      }
      else {
        commit('SET_NEW_CARD', [])
      }
    },
    modifyBooster({ state }, { card, position }) {
      state.modifyBooster(card, position)
    },
    setLoadingScreen({ commit }, value) {
      commit('SET_LOADING_SCREEN', value)
    },
    setCheat({ state, commit }) {
      commit('SET_CHEAT', !state.cheat)
    },
    resetGame({ state, dispatch, commit }, cards) {

      var save = {
        money: 35,
        intervalMoney: 14000,
        stageBoosterCards: [{ id: cards[0].id, rare: cards[0].rare, new: true }, { id: cards[1].id, rare: cards[1].rare, new: true }, { id: cards[2].id, rare: cards[2].rare, new: true }],
        boosterState: false,
        boosterNb: 5,
        shops: ['woodStand'],
        shopPrices: { woodStand: 30, chest: 50, stand: 70, single0: 0, bark: 0 },
        cards: [],
        workerResetNb: null,
        day: true,
        factoryAccident: 0,
        diceLuck: 0,
        maxMoney: 100,
        shopItems: ['venus', 'level2', 'moist'],
        safeCode: '    ',
        dogBooster: -1,
        filter: [],
        malediction: false,
        multiplyValueNb: 0,
        idRobot: null,
        excavation: null,
        blessing: null,
        cupBall: 5,
        cardScan: null,
        lastCard: false,
				totalTime: 0,
      }

      commit('LOAD_DATA', save);
      commit('SET_LOADED', false);
      localStorage.setItem('boosterGameObj', null)
      dispatch('initData');
      state.modifyShop();
      state.resetBooster();
    },
    setFlagAndTurn({ commit, state }, { value, name }) {
      switch (name) {
        case 'MULTIPLY':
          commit('SET_MULTIPLY', state.multiplyValueNb + value)
          break;
        case 'ACCIDENT':
          commit('SET_FACTORY_ACCIDENT', state.factoryAccident + value)
          break;
        case 'DAY':
          commit('SET_DAY', value)
          break;
        case 'MALEDICTION':
          commit('SET_MALEDICTION', value)
          break;
        case 'FILTER':
          commit('SET_FILTER', value)
          break;
        case 'DICE_LUCK':
          commit('SET_DICE_LUCK', value)
          break;
        case 'MAX_MONEY':
          commit('SET_MAX_MONEY', value)
          break;
        case 'DOG_BOOSTER':
          commit('SET_DOG_BOOSTER', value)
          break;
        case 'ID_ROBOT':
          commit('SET_ID_ROBOT', value)
          break;
        case 'BLESSING':
          commit('SET_BLESSING', value)
          break;
        case 'BALL_CUP':
          commit('SET_CUP_BALL', value)
          break;
        case 'WORKER_RESET':
          commit('SET_WORKER_RESET', value)
          break;
        case 'MODIFY_BOOSTER':
          commit('SET_MODIFY_BOOSTER', value)
          break;
        case 'MODIFY_SHOP':
          commit('SET_MODIFY_SHOP', value)
          break;
        case 'SCAN_CARD':
          commit('SET_SCAN_CARD', value)
          break;
        case 'RESET_BOOSTER':
          commit('SET_RESET_BOOSTER', value)
          break;
        case 'ENDING_MESSAGE':
          commit('SET_ENDING_MESSAGE', value)

      }
      local.save(state)
    }
  },
  mutations: {
    LOAD_DATA(state, save) {
      state.money = save.money
      state.maxMoney = save.maxMoney
      state.intervalMoney = save.intervalMoney
      state.stageBoosterCards = save.stageBoosterCards
      state.boosterState = save.boosterState
      state.boosterNb = save.boosterNb
      state.shops = save.shops
      state.shopPrices = save.shopPrices
      state.cards = save.cards
      state.workerResetNb = save.workerResetNb
      state.day = save.day
      state.factoryAccident = save.factoryAccident
      state.diceLuck = save.diceLuck
      state.shopItems = save.shopItems
      state.safeCode = save.safeCode
      state.dogBooster = save.dogBooster
      state.filter = save.filter
      state.malediction = save.malediction
      state.multiplyValueNb = save.multiplyValueNb
      state.idRobot = save.idRobot
      state.excavation = JSON.parse(save.excavation)
      state.blessing = save.blessing
      state.cupBall = save.cupBall
      state.cardScan = save.cardScan
      state.lastCard = save.lastCard
			state.totalTime = save.totalTime
    },
    SET_INTERVAL_ID(state, id) {
      state.workerID = id
    },
    SET_INTERVAL_TIME(state, intervalMoney) {
      state.intervalMoney = intervalMoney
    },
    SET_MONEY(state, money) {
      state.money = money
    },
    BOOSTER_STATE(state, open) {
      state.boosterState = open
    },
    SET_CARDS(state, cards) {
      state.cards = cards
    },
    SET_LOADED(state, loaded) {
      state.loaded = loaded
    },
    SET_STAGE_BOOSTER(state, cards) {
      state.stageBoosterCards = cards
    },
    SET_NEW_CARD(state, newCard) {
      state.newCard = newCard
    },
    SET_DAY(state, day) {
      state.day = day
    },
    SET_BOOSTER_NB(state, boosterNb) {
      state.boosterNb = boosterNb
    },
    SET_MULTIPLY(state, multiplyValueNb) {
      state.multiplyValueNb = multiplyValueNb
    },
    SET_WORKER_RESET(state, reset) {
      state.workerResetNb = reset
    },
    SET_FACTORY_ACCIDENT(state, factoryAccident) {
      state.factoryAccident = factoryAccident
    },
    SET_MODIFY_BOOSTER(state, func) {
      state.modifyBooster = func
    },
    SET_MALEDICTION(state, malediction) {
      state.malediction = malediction
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    SET_DICE_LUCK(state, diceLuck) {
      state.diceLuck = diceLuck
    },
    SET_MAX_MONEY(state, maxMoney) {
      state.maxMoney = maxMoney
    },
    SET_SHOP_ITEMS(state, shopItems) {
      state.shopItems = shopItems
    },
    SET_SHOPS(state, shops) {
      state.shops = shops
    },
    SET_MODIFY_SHOP(state, modifyShop) {
      state.modifyShop = modifyShop
    },
    SET_SAFE_CODE(state, safeCode) {
      state.safeCode = safeCode
    },
    SET_DOG_BOOSTER(state, dogBooster) {
      state.dogBooster = dogBooster
    },
    SET_ID_ROBOT(state, idRobot) {
      state.idRobot = idRobot
    },
    SET_LOADING_SCREEN(state, loadingScreen) {
      state.loadingScreen = loadingScreen
    },
    SET_BLESSING(state, blessing) {
      state.blessing = blessing
    },
    SET_EXCAVATION(state, excavation) {
      state.excavation = excavation
    },
    SET_CUP_BALL(state, cupBall) {
      state.cupBall = cupBall
    },
    SET_LOADED_ILLU(state, loadedCardIllu) {
      state.loadedCardIllu = loadedCardIllu
    },
    SET_SCAN_CARD(state, cardScan) {
      state.cardScan = cardScan
    },
    SET_CHEAT(state, cheat) {
      state.cheat = cheat
    },
    SET_RESET_BOOSTER(state, resetBooster) {
      state.resetBooster = resetBooster
    },
    SET_LAST_CARD(state, lastCard) {
      state.lastCard = lastCard
    },
    SET_ENDING_MESSAGE(state, endingMessage) {
      state.endingMessage = endingMessage
    },
    SET_TOTAL_TIME(state, totalTime) {
      state.totalTime = totalTime
    }
  },
  getters: {
    getMoney: (state) => {
      return state.money
    },
    getMaxMoney: (state) => {
      return state.maxMoney
    },
    getStageBoosterCard: (state) => {
      return state.stageBoosterCards
    },
    getBoosterState: (state) => {
      return state.boosterState
    },
    getShops: (state) => {
      return state.shops
    },
    getShopPrices: (state) => {
      return state.shopPrices
    },
    getCards: (state) => {
      return state.cards
    },
    getNewCard: (state) => {
      return state.newCard
    },
    getDay: (state) => {
      return state.day
    },
    getBoosterNb: (state) => {
      return state.boosterNb
    },
    getMultiplyNb: (state) => {
      return state.multiplyValueNb
    },
    getFactoryAccident: (state) => {
      return state.factoryAccident
    },
    getShopItems: (state) => {
      return state.shopItems
    },
    getWorkerResetNb: (state) => {
      return state.workerResetNb
    },
    getSafeCode: (state) => {
      return state.safeCode
    },
    getLoadingScreen: (state) => {
      return state.loadingScreen
    },
    getIdRobot: (state) => {
      return state.idRobot
    },
    getBlessing: (state) => {
      return state.blessing
    },
    getExcavation: (state) => {
      return state.excavation
    },
    getMalediction: (state) => {
      return state.malediction
    },
    getCupBall: (state) => {
      return state.cupBall
    },
    getLoadedCardIllu: (state) => {
      return state.loadedCardIllu
    },
    getCardScan: (state) => {
      return state.cardScan
    },
    getCheat: (state) => {
      return state.cheat
    },
    getResetBooster: (state) => {
      return state.resetBooster
    },
    getLastCard: (state) => {
      return state.lastCard
    },
    getEndingMessage: (state) => {
      return state.endingMessage
    },
    getTotalTime: (state) => {
      return state.totalTime
    }
  },
  modules: {
  }
})
