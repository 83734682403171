<template>
  <div class="selector mainContainer">
    <span class="selector title main">{{ this.steps.selector.message }}</span>
    <div class="selector wrapper">
      <div class="selectorLoading" v-if="steps.selector.moneySum">
        <div class="loadBar container">
          <div class="loadBar bar" id="loadingBar"></div>
        </div>
        <div class="cardValue threeValue">
          <span>{{ getCardValue * 3 }}</span>
          <div class="selector coin"></div>
        </div>
        <div class="cardValue twoValue">
          <span>{{ getCardValue * 2 }}</span>
          <div class="selector coin"></div>
        </div>
        <div class="cardValue oneValue">
          <span>{{ getCardValue }}</span>
          <div class="selector coin"></div>
        </div>
        <div class="cardTotal">
          <span>{{ getSum }}</span>
          <div class="selector cardTotalCoin coin"></div>
        </div>
      </div>
      <div class="selector cardList">
        <span class="selector title">Cards</span>
        <div class="selector scrollBox">
          <div
            class="selector cardWrapper"
            v-for="card in cards"
            :key="card.key"
            :class="{
              rare: card.rare,
              remove: card.nb == 0,
              add: card.visible && card.nb >= 1,
              hideCard: !card.visible,
            }"
            @click="select(card.key, true)"
          >
            <div class="selector id">
              {{ card.id }}
            </div>
            <div class="selector name">
              <span v-if="steps.selector.moneySum">{{
                card.rare
                  ? getInfos[card.id].value * 2
                  : getInfos[card.id].value
              }}</span>
              <div v-if="steps.selector.moneySum" class="selector coin"></div>
              <span>{{
                (steps.selector.clue && card.new
                  ? "??????"
                  : getInfos[card.id].name) + (card.rare ? "-rare" : "")
              }}</span>
            </div>
            <div
              class="selector numberWrapper"
              v-if="!steps.selector.noMultiple"
            >
              <span>X{{ card.nb }}</span>
            </div>
          </div>
        </div>
        <div class="selector btnWrapper left">
          <Button size="small" :filled="false" @click="hide">Cancel</Button>
        </div>
      </div>
      <img src="../assets/right.png" alt="left arrow" />
      <div class="selector cardList">
        <span class="selector title">Selected cards</span>
        <div class="selector scrollBox">
          <div
            class="selector cardWrapper"
            v-for="card in selected"
            :key="card.key"
            :class="{
              rare: card.rare,
              remove2: card.nb == 0,
              add2: card.visible && card.nb >= 1,
              hideCard: !card.visible,
            }"
            @click="select(card.key, false)"
          >
            <div class="selector id">
              {{ card.id }}
            </div>
            <div class="selector name">
              <span v-if="steps.selector.moneySum">{{
                getInfos[card.id].value
              }}</span>
              <div v-if="steps.selector.moneySum" class="selector coin"></div>
              <span>{{
                (steps.selector.clue && card.new
                  ? "??????"
                  : getInfos[card.id].name) + (card.rare ? "-rare" : "")
              }}</span>
            </div>
            <div
              class="selector numberWrapper"
              v-if="!steps.selector.noMultiple"
            >
              <span>X{{ card.nb }}</span>
            </div>
          </div>
        </div>
        <div class="selector btnWrapper right">
          <Button @click="validate()" :active="getValidationSelector == true">
            Validate
          </Button>
          <span
            v-if="
              steps.selector.nb - nbCardSelected && !steps.selector.moneySum > 0
            "
            >{{ steps.selector.nb - nbCardSelected }} card remaining</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import infoCard from "@/assets/card.json";
//import Card from "@/components/card";
import Button from "@/components/button";
import mobile from "is-mobile";
export default {
  data() {
    return {
      selected: [],
    };
  },
  components: {
    Button,
  },
  props: {
    steps: Object,
    hide: Function,
    validateCards: Function,
    cards: Array,
  },
  mounted() {
    this.updateBar();
  },
  methods: {
    validate() {
      this.validateCards(this.selected);
    },
    select(key, direction) {
      var cards1;
      var cards2;

      if (direction) {
        cards1 = this.cards;
        cards2 = this.selected;
      } else {
        cards1 = this.selected;
        cards2 = this.cards;
      }

      var card = cards1.find((card) => card.key === key);

      if (
        card.nb > 0 &&
        (this.steps.selector.nb - this.nbCardSelected > 0 ||
          this.steps.selector.moneySum ||
          !direction)
      ) {
        card.nb--;
        var cardSelected = cards2.find((card) => card.key === key);
        if (cardSelected) {
          cardSelected.nb++;
          cardSelected.visible = true;
        } else {
          cards2.push({
            key: card.key,
            id: card.id,
            rare: card.rare,
            nb: 1,
            visible: card.visible,
            new: card.new,
          });
        }
        if (card.nb == 0) {
          setTimeout(() => (card.visible = false), 200);
        }
      }
      this.updateBar();
    },
    updateBar() {
      var sum = 0;
      this.selected.forEach((card) => {
        if (card.nb > 0) {
          sum +=
            (card.rare
              ? infoCard[card.id].value * 2
              : infoCard[card.id].value) * card.nb;
        }
      });
      try {
        document.getElementById("loadingBar").style.height =
          Math.floor((126 * sum) / this.getCardValue) + "px";
      } catch (e) {
        this.e = e;
      }
    },
  },
  computed: {
    ...mapGetters(["getCards", "getCardScan"]),
    isMobile() {
      return mobile();
    },
    getInfos() {
      return infoCard;
    },
    endingCards() {
      return this.ending.cards.map((e, index) => {
        return index == 1 ? { ...e, arrow: true } : e;
      });
    },
    nbCardSelected() {
      var nb = 0;
      this.selected.forEach((c) => {
        nb += c.nb;
      });
      return nb;
    },
    getSum() {
      var sum = 0;
      this.selected.forEach((card) => {
        if (card.nb > 0) {
          sum +=
            (card.rare
              ? infoCard[card.id].value * 2
              : infoCard[card.id].value) * card.nb;
        }
      });
      return sum;
    },
    getCardValue() {
      return this.getCardScan.rare
        ? infoCard[this.getCardScan.id].value * 2
        : infoCard[this.getCardScan.id].value;
    },
    getValidationSelector() {
      return (
        this.steps.selector.nb - this.nbCardSelected == 0 ||
        (this.steps.selector.moneySum && this.getSum >= this.getCardValue)
      );
    },
  },
};
</script>

<style>
</style>