class localSave {
  getSavedObjects() {
    var retrievedObject = localStorage.getItem('boosterGameObj')
    return JSON.parse(retrievedObject)
  }

  save(state) {
    var save = {
      money: state.money,
      intervalMoney: state.intervalMoney,
      stageBoosterCards: state.stageBoosterCards,
      boosterState: state.boosterState,
      boosterNb: state.boosterNb,
      shops: state.shops,
      shopPrices: state.shopPrices,
      cards: state.cards,
      workerResetNb: state.workerResetNb,
      day: state.day,
      factoryAccident: state.factoryAccident,
      diceLuck: state.diceLuck,
      maxMoney: state.maxMoney,
      shopItems: state.shopItems,
      safeCode: state.safeCode,
      dogBooster: state.dogBooster,
      filter: state.filter,
      malediction: state.malediction,
      multiplyValueNb: state.multiplyValueNb,
      idRobot: state.idRobot,
      excavation: JSON.stringify(state.excavation),
      blessing: state.blessing,
      cupBall: state.cupBall,
      cardScan: state.cardScan,
      lastCard: state.lastCard,
			totalTime: state.totalTime,
    }
    localStorage.setItem('boosterGameObj', JSON.stringify(save))
  }
}

export default new localSave()
