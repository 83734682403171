<template>
  <div class="dark-background" id="bg">
    <div class="shop-container">
      <div class="shop-sign">
        <p class="sign-text">
          {{ description }}
        </p>
      </div>
      <img
        src="@/assets/arrow-shop.png"
        alt=""
        class="arrow-left arrow-shop"
        @click="moveChoice(-1)"
        v-if="index != 0"
      />
      <img
        src="@/assets/arrow-shop.png"
        alt=""
        class="arrow-right arrow-shop"
        @click="moveChoice(1)"
        v-if="index != shopElements.filter((c) => c != 'none').length - 1"
      />
      <img src="../assets/shop/shop-back.png" class="shop-image" />
      <img
        src="../assets/shop/shop-front.png"
        alt=""
        class="shop-image-front"
      />
      <div class="shop-item-container-overflow">
        <div class="shop-item-container" id="item-container">
          <div
            v-for="(item, index_) in shopElements"
            :key="item"
            class="shop-item"
            :class="{ 'scale-up': index == index_, hidden: item == 'none' }"
            :id="index_ + '-item'"
          >
            <img
              :src="require(`@/assets/shop/${item}.png`)"
              alt="shop item"
              v-if="item != 'none'"
              class="item-shop-image"
            />
          </div>
        </div>
      </div>

      <div class="btn-shop-container">
        <div class="price-shop">
          {{ getCards[0].normal + getCards[0].rare }}
          <img src="@/assets/emerald.png" alt="" class="price-shop-image" />
        </div>
        <Button
          :size="''"
          :active="!(itemInfo.price > getCards[0].normal + getCards[0].rare)"
          @click="BuyShop(shopElements[index])"
        >
          {{ "Buy " + itemInfo.price }}
          <img src="@/assets/emerald.png" alt="" class="price-shop-image" />
        </Button>
        <Button :filled="false" :size="''" @click="leave">Leave</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ShopInfo from "@/assets/shop.json";
import Button from "@/components/button";
export default {
  data() {
    return {
      index: 0,
      venus: 0,
      moist: 0,
      buy: false,
    };
  },
  components: {
    Button,
  },
  props: {
    next: Function,
    card: Object,
  },
  mounted() {
    this.itemContainer = document.getElementById("item-container");
    this.itemContainer.style.marginLeft =
      (23 - this.index * 23).toString() + "vh";
  },
  methods: {
    ...mapActions(["buyShopItem", "burnCard"]),
    //move and update items to match the index
    moveChoice(val) {
      this.index += val;
      this.itemContainer.style.marginLeft =
        (23 - this.index * 23).toString() + "vh";
    },
    async BuyShop(item) {
      //destroy card once the user start bying
      if (!this.buy) {
        this.buy = true;
        this.burnCard(this.card);
      }

      if (item == "venus") {
        this.venus++;
      }
      if (item == "moist") {
        this.moist++;
      }
      if (item == "boosterShop") {
        this.moveChoice(-1);
      }

      //drop down effect
      if (item == "moist" || item == "venus") {
        var itemDom = document.getElementById(this.index + "-item");
        itemDom.style.transition = "margin 0.01s";
        itemDom.style.marginBottom = "400px";
        setTimeout(async () => {
          itemDom.style.transition = "margin 0.5s";
          itemDom.style.marginBottom = "0px";

          await this.buyShopItem(item);
        }, 100);
      } else {
        await this.buyShopItem(item);
      }
    },
    leave() {
      if (this.venus + this.moist == 0) {
        this.next();
        return;
      }
      var callback = () => {
        var message = "";
        if (this.moist > 0 && this.venus > 0) {
          message = `You've bought the card Venus and ${this.moist} card moist chameleon.`;
          return {
            message,
            cards: [
              { id: 31, rare: false },
              { id: 34, rare: false },
            ],
          };
        } else if (this.moist > 0) {
          message = `You've bought ${this.moist} card${
            this.moist > 1 ? "s" : ""
          } moist chameleon.`;
          return { message, cards: [{ id: 31, rare: false }] };
        } else if (this.venus > 0) {
          message = `You've bought  the card Venus.`;
          return { message, cards: [{ id: 34, rare: false }] };
        }
      };
      var data = {
        selector: null,
        images: null,
        callback: callback,
        sounds: null,
      };
      this.next(data);
    },
  },
  computed: {
    ...mapGetters(["getShopItems", "getCards", "getShops"]),
    scaleActive(e) {
      return e.index == this.index;
    },
    shopElements() {
      var elementTmp = [...this.getShopItems];

      elementTmp = elementTmp.filter((c) => {
        return !(c == "boosterShop" && this.getShops.includes("single0"));
      });
      if (elementTmp.length < 5) {
        for (var i = 0; i < 6 - elementTmp.length; i++) {
          elementTmp.push("none");
        }
      }
      return elementTmp;
    },
    itemInfo() {
      if (this.shopElements[this.index]) {
        return ShopInfo[this.shopElements[this.index]];
      }
      return { message: "", price: 0 };
    },
    description() {
      return `${this.itemInfo.name}\n\t${this.itemInfo.message}`;
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-item {
  width: 23vh;
  height: 100%;
  transition: all 0.7s;
  transform: scale(0.8);
}

.scale-up {
  transform: scale(0.9);
}

.hidden {
  visibility: hidden;
}

.price-shop {
  display: flex;
  align-items: center;
  font-size: 4vh;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  z-index: 5;
  height: 100%;
  width: 20vh;
}

.price-shop-image {
  height: 80%;
  margin-left: 10%;
}

.shop-item-container {
  position: absolute;
  width: 115vh;
  left: 0px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 0;
  transition: margin 0.7s;
}

.shop-item-container-overflow {
  position: absolute;
  overflow: hidden;
  width: 80%;
  left: 12%;
  bottom: 25vh;
  height: 30%;
}

.shop-image {
  height: 100%;
}

.shop-image-front {
  position: absolute;
  height: 100%;
  z-index: 2;
  top: 0px;
  left: 0px;
}

.shop-sign {
  position: absolute;
  top: 3%;
  width: 50vh;
  height: 12%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  padding: 0px 10px;
  text-align: center;
}

.sign-text {
  font-family: "Balsamiq Sans", cursive;
  font-size: 2.5vh;
  margin: 0px;
  white-space: pre-line;
  text-align: center;
}

.btn-shop-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 85%;
  height: 8vh;
  font-size: 3vh;
}

.btn-shop {
  width: 25vh;
  height: 100%;
  background-color: bisque;
  border-radius: 8px;
  font-family: "Balsamiq Sans", cursive;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactive {
  cursor: none;
  background-color: rgb(114, 114, 114);
  pointer-events: none;
  cursor: context-menu !important;
}

.shop-container {
  height: 90vh;
  overflow: hidden;
  background-size: cover;
  position: relative;
}

.item-shop-image {
  height: 100%;
}

.arrow-left {
  left: 26%;
}

.arrow-shop {
  position: absolute;
  top: 55%;
  height: 12vh;
  cursor: pointer;
  z-index: 3;
}

.arrow-right {
  left: 64%;
  transform: rotate(180deg);
}
</style>