<template>
  <div class="card-dimension" :id="idCard">
    <span
      class="card-number"
      :style="{ 'font-size': heightCard * 0.06 + 'px' }"
      >{{ numberCorrector(infos.id) }}</span
    >
    <span
      class="card-name"
      :style="{ 'font-size': heightCard * 0.045 + 'px' }"
      >{{ infos.name }}</span
    >
    <p
      class="card-description"
      :style="{ 'font-size': heightCard * 0.034 + 'px' }"
    >
      {{
        infos[
          card.rare
            ? infos.descriptionRare
              ? "descriptionRare"
              : "description"
            : "description"
        ]
      }}
    </p>
    <img
      :src="getImage"
      alt="card illustration"
      v-if="card.id != 43"
      class="card-illu"
    />
    <canvas
      id="canvas-card"
      v-if="card.id == 43"
      class="card-illu-canvas"
      width="360"
      height="220"
    ></canvas>
    <img :src="getBackground" alt="card-back" class="card-img" />
  </div>
</template>

<script>
import cardInfo from "@/assets/card";
export default {
  props: {
    card: Object,
  },
  data() {
    return {
      idCard: Math.random().toString(),
      heightCard: 10,
    };
  },
  mounted() {
    try {
      this.heightCard = document.getElementById(this.idCard).offsetHeight;
			document.getElementById(this.idCard).style.width = Math.floor(this.heightCard * 0.7125)+"px";
    } catch (e) {
      this.heightCard = 10;
    }
    window.addEventListener("resize", () => {
      this.heightCard = document.getElementById(this.idCard).offsetHeight;
			try {
				this.heightCard = document.getElementById(this.idCard).offsetHeight;
				document.getElementById(this.idCard).style.width = Math.floor(this.heightCard * 0.7125)+"px";
			} catch (e) {
				this.heightCard = 10;
			}
    });
    if (this.card.id == 43) {
      this.canvas = document.getElementById("canvas-card");
      this.ctx = this.canvas.getContext("2d");
      var dataURL = localStorage.getItem("canvas-booster-master");
      var img = new Image();
      img.src = dataURL;
      img.onload = () => {
        this.ctx.drawImage(img, 0, 0);
      };
    }
  },
  updated() {
    if (this.card.id == 43) {
      this.canvas = document.getElementById("canvas-card");
      this.ctx = this.canvas.getContext("2d");
      var dataURL = localStorage.getItem("canvas-booster-master");
      var img = new Image();
      img.src = dataURL;
      img.onload = () => {
        this.ctx.drawImage(img, 0, 0);
      };
    }
  },
  computed: {
    infos() {
      return cardInfo[this.card.id];
    },
    getImage() {
      if (this.infos.illu == "none") {
        return require(`../assets/card-illustration/undefinedCard.png`);
      }
      return require(`../assets/card-illustration/${this.infos.illu}.png`);
    },
    getBackground() {
      if (this.card.id < 18) {
        return require(`../assets/cards/copper${
          this.card.rare ? "_shiny.gif" : "_normal.png"
        }`);
      } else if (this.card.id < 36) {
        return require(`../assets/cards/silver${
          this.card.rare ? "_shiny.gif" : "_normal.png"
        }`);
      }
      return require(`../assets/cards/gold${
        this.card.rare ? "_shiny.gif" : "_normal.png"
      }`);
    },
  },
  methods: {
    numberCorrector(n) {
      return n < 10 ? "0" + n : n;
    },
  },
};
</script>

<style>
.card-dimension {
  position: relative;
  min-height: 10px;
}

.card-number {
  left: 8.5%;
  top: 4.5%;
  color: white;
  position: absolute;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 1000;
  /* font-size: 1.7vh; */
}

.card-name {
  top: 6%;
  left: 30%;
  position: absolute;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 1000;
  font-size: 1.2vh;
}

.card-description {
  top: 55%;
  left: 8%;
  width: 84%;
  position: absolute;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 1000;
  font-size: 0.91vh;
  white-space: pre-line;
}

.card-img {
  height: 100%;
}

.card-illu {
  top: 15%;
  left: 7%;
  position: absolute;
  width: 86%;
}

.card-illu-canvas {
  top: 15%;
  left: 7%;
  position: absolute;
  width: 86%;
  background: white;
  border-radius: 5px;
}
</style>