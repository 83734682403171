import store from '@/store'
import cardInfo from '@/assets/card.json'

export default class cardAction {

  static play(id, rare) {
    var unobtainableCard = [7, 8, 16, 17, 25, 26, 34, 35, 42, 43, 44, 51, 52, 53]
    switch (id) {
      case 2: //bubbles
        // image of bubble exploding
        var bubble1 = "bubble_1.png";
        var bubble2 = "bubble_2.png";
        var bubble3 = "bubble_3.png";

        var callback2 = () => {
          store.dispatch('burnCard', { id, rare })
          return { message: 'You see the bubble pop but sadly nothing happen.' }
        }

        return { images: { list: [bubble1, bubble2, bubble3], speed: 700 }, message: 'You see the bubbles pop but saldy nothing happen', callback: callback2 };
      case 3: //dwarf worker
        var callback3 = () => {
          var nbCard = store.state.cards[3].normal
          // if there is 7 normals dwarf they will be used
          if (nbCard >= 7) {
            for (var i = 0; i < 7; i++) {
              store.dispatch('burnCard', { id: 3, rare: false })
            }
          }
          else {
            //burn all dwarf normal card
            for (var j = 0; j < 7 - nbCard; j++) {
              store.dispatch('burnCard', { id: 3, rare: true })
            }
            //burn remaining rare dwarf 
            for (var k = 0; k < nbCard; k++) {
              store.dispatch('burnCard', { id: 3, rare: false })
            }
          }

          store.dispatch('saveCard', { id: 8, rare: false })

          return { message: 'The Queen awakes from her very long slumber to be greeted by 7 identical dwarves.', cards: [{ id: 3, rare: false }, { id: 8, rare: false }], compare: true };
        }

        return { selector: null, images: null, callback: callback3, sounds: null };
      case 4: //fortune cookie
        //choose 1 card with out rare or normal specified
        var selector4 = {
          nb: 1,
          message: "Choose the card you need help for.",
          filter: () => true,
          noMultiple: true,
          clue: true,
        }

        var callback4 = (cards) => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('clueCard', cards[0].id)
          return { message: `Clue for the card ${cards[0].id > 9 ? cards[0].id : '0' + cards[0].id}: ${cardInfo[cards[0].id].name}:\n\n ${cardInfo[cards[0].id].detail}` }
        }

        return { selector: selector4, images: null, callback: callback4, sounds: null };
      case 5://luck coin
        var callback5 = () => {
          store.dispatch('setFlagAndTurn', { name: 'MULTIPLY', value: rare ? 5 : 3 })
          store.dispatch('burnCard', { id, rare })
          return { message: `The next 3 cards you sell will have ${rare ? 5 : 3} time the value.` }
        }

        return { selector: false, images: null, callback: callback5 };
      case 6:// factory accident
        var callback6 = () => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('setFlagAndTurn', { name: 'ACCIDENT', value: rare ? 5 : 3 })
          return { message: `Due to an accident the next ${rare ? 5 : 3} boosters you have 7 cards` };
        }

        return { selector: null, images: null, callback: callback6, sounds: null };
      case 11:// soapy water
        var message11 = '';
        var cards = null;
        var cardsBooster = [...store.state.stageBoosterCards]

        var cardShiny = cardsBooster.filter((c) => {
          return !c.rare || c.id == 1
        });
        var rndIndex = -1
        var compare = false
        if (cardShiny.length > 0) {
          while (!cards) {
            rndIndex = Math.floor(Math.random() * cardsBooster.length)
            if (!cardsBooster[rndIndex].rare || cardsBooster[rndIndex].id == 1) {
              cards = [cardsBooster[rndIndex]]
              if (cards[0].id == 1) {
                cards[0].id = 7
                cards = [{ id: 1, rare: cards[0].rare }, cards[0]]
                cardsBooster[rndIndex].id = 7
                compare = true
                message11 = 'The greasy cauldron turned into a crystal vase when you used the soap.';
              }
              else {
                cardsBooster[rndIndex].rare = true;
                message11 = 'This card is now shiny.';
              }
            }
          }
        }
        else {
          message11 = 'All the card are already shiny.';
        }

        var callback11 = () => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('changeboosterCards', cardsBooster)
          if (rndIndex != -1) {
            store.dispatch('modifyBooster', { card: cards[cards.length - 1], position: rndIndex })
          }
          return { message: message11, cards: cards, compare }
        }

        return { selector: null, images: null, callback: callback11, sounds: null };
      case 12://generous magpie
        var cardPossible = [{ id: 23, rare: false }, { id: 17, rare: false }, { id: 5, rare: false }]
        var leave = 'pie_leave.png'
        var returnPie;
        var nb = Math.floor(Math.random() * (rare ? 3 : 4))
        switch (nb) {
          case 0:
            returnPie = 'pie_diamond.png'
            break;
          case 1:
            returnPie = 'pie_necklace.png'
            break;
          case 2:
            returnPie = 'pie_coin.png'
            break;
          case 3:
            returnPie = 'pie_branch.png'
            break;
        }

        var callback12 = () => {
          store.dispatch('burnCard', { id, rare })
          if (nb != 3) {
            store.dispatch('saveCard', cardPossible[nb])
          }
          return { message: nb != 3 ? 'The magpie has returned with a gift' : "The magpie din't returned", cards: nb == 3 ? null : [cardPossible[nb]] }
        }
        return { selector: null, images: { list: [leave, returnPie], speed: 1000 }, callback: callback12, sounds: null };
      case 13:// moon rock
        var callback13 = () => {
          store.dispatch('burnCard', { id, rare })
          var cards37 = []
          for (var i = 0; i < (rare ? 5 : 3); i++) {
            store.dispatch('saveCard', { id: 0, rare: false })
            cards37.push({ id: 0, rare: false })
          }
          return { message: `You've won ${rare ? 5 : 3} emerald card, cards: cards37` }
        }
        return { selector: null, images: null, callback: callback13, sounds: null };
      case 14:// fast worker
        var callback14 = () => {
          store.dispatch('setFlagAndTurn', { name: 'WORKER_RESET', value: rare ? 172 : 86 })
          store.dispatch('changeWorker', 7000)
          store.dispatch('burnCard', { id, rare })
          return { message: `You will now make money twice as fast from ${rare ? '20': '10'} min` };
        }
        return { selector: null, images: null, callback: callback14, sounds: null };
      case 15:// king of dice
        if (store.state.day) {
          var callback15 = () => {
            store.dispatch('setFlagAndTurn', { name: 'DICE_LUCK', value: rare ? 3 : 1 })
            store.dispatch('burnCard', { id, rare })
            return { message: `The kings has always been lucky with dice. He will throw the next ${rare ? '3 dices' : 'dice'} for you.` }
          }
          return { selector: null, images: null, callback: callback15, sounds: null };
        }
        else {
          var callback15Bis = () => {
            store.dispatch('saveCard', { id: 16, rare: rare })
            store.dispatch('burnCard', { id, rare })
            return { message: 'The king has turned into a werewolf.', cards: [{ id: 15, rare: rare }, { id: 16, rare: rare }], compare: true }
          }
          return { selector: null, images: null, callback: callback15Bis, sounds: null };
        }
      case 18://x-ray glasses
        var image18 = null
        if (store.state.stageBoosterCards.filter((c) => { return c.id == 28 }).length > 0) {
          image18 = { list: ["x_ray_1.png", "x_ray_2.png", "x_ray_3.png", "x_ray_4.png", "x_ray_5.png"], speed: 1000 }
        }
        var callback18 = () => {
          store.dispatch('burnCard', { id, rare })
          if (store.state.stageBoosterCards.filter((c) => { return c.id == 28 }).length > 0) {
            var cardBooster18 = store.state.stageBoosterCards.map((c, index) => {
              if (c.id == 28) {
                store.dispatch('modifyBooster', { card: { id: 35, rare: c.rare }, position: index })
              }
              return c.id == 28 ? { id: 35, rare: c.rare } : c
            })
            store.dispatch('changeboosterCards', cardBooster18)
            return { message: 'The x-ray glasses revealed who is the mistery man.', cards: cardBooster18 }
          }
          return { message: 'Here are the card inside the booster you bought', cards: store.state.stageBoosterCards }
        }
        return { selector: null, images: image18, callback: callback18, sounds: null };
      case 19:
        return { copy: true, card: { id, rare } };
      case 21:// forgotten safe
        store.dispatch('burnCard', { id, rare })
        return { card: { id, rare }, safe: true };
      case 22:// risky dice
        var possibilities = [0, 1, 2, 3, 3, 4, 4, 4, 5, 5, 5]
        if (rare) {
          possibilities = [0, 0, 1, 1, 2, 2, ...possibilities]
        }
        if (store.state.diceLuck > 0) {
          store.dispatch('setFlagAndTurn', { name: 'DICE_LUCK', value: store.state.diceLuck - 1 })
          possibilities = possibilities.filter((nb) => nb < 3)
        }
        var rnd = Math.floor(Math.random() * possibilities.length)
        var choice = possibilities[rnd]

        var images22 = [`dice_step_1.png`, `dice_step_2.png`, `dice_${choice + 1}.png`]
        var callback22 = () => {
          store.dispatch('burnCard', { id, rare })
          switch (choice) {
            case 0:
              store.dispatch('saveCard', { id: 25, rare: rare })
              return { message: "1: You have won the gambler's watch.", cards: [{ id: 25, rare: rare }] }
            case 1:
              store.dispatch('addMoney', 100)
              return { message: "2: You won 100 coins" }
            case 2:
              store.dispatch('addMoney', 50)
              return { message: "3: You won 50 coins" }
            case 3:
              store.dispatch('addMoney', -1000)
              return { message: "4: Lose all money." }
            case 4:
              var cards22 = store.state.cards.filter((elmt) => { return elmt.normal + elmt.rare > 0 && elmt.id != 53 })
              var lostCard = cards22[Math.floor(Math.random() * cards22.length)]
              var card4List = [];
              if (lostCard.normal > 0 && lostCard.rare <= 0) {
                store.dispatch('burnCard', { id: lostCard.id, rare: false })
                card4List = [{ id: lostCard.id, rare: false }]
              }
              else if (lostCard.normal <= 0 && lostCard.rare > 0) {
                store.dispatch('burnCard', { id: lostCard.id, rare: true })
                card4List = [{ id: lostCard.id, rare: true }]
              }
              else {
                var choice22 = Math.random() > 0.5
                store.dispatch('burnCard', { id: lostCard.id, rare: choice22 })
                card4List = [{ id: lostCard.id, rare: choice22 }]
              }
              return { message: "5: You've lost a random card.", cards: card4List }
            case 5:
              var oldsState = store.state.malediction
              store.dispatch('setFlagAndTurn', { name: 'MALEDICTION', value: true })
              return { message: oldsState ? "You already have the malediction." : "Shiny card wont appear." }
          }
          return { message: "error" }
        }
        return { selector: null, images: { list: images22, speed: 1000 }, callback: callback22, sounds: null };
      case 24:// Scrary night
        var callback24 = () => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('setFlagAndTurn', { name: 'DAY', value: false })
          return { message: 'The night has fallen.' }
        }
        return { selector: null, images: { list: ['nuit.gif'], speed: 3000 }, callback: callback24, sounds: null };
      case 26:// time traveler
        var callback26 = () => {
          var cardDelete = [10, 44]
          cardDelete.forEach((id) => {
            var normalNb = store.state.cards[id].normal
            if (normalNb >= 2) {
              store.dispatch('burnCard', { id, rare: false })
              store.dispatch('burnCard', { id, rare: false })
            }
            else {
              for (var i = 0; i < normalNb; i++) {
                store.dispatch('burnCard', { id, rare: false })
              }
              for (var j = 0; j < 2 - normalNb; j++) {
                store.dispatch('burnCard', { id, rare: true })
              }
            }
          })
          store.dispatch('burnCard', { id: 26, rare })
          store.dispatch('saveCard', { id: 52, rare })
          return { message: 'You now have the card. squelton blessing.', cards: [{ id: 52, rare }] }
        }
        return { selector: null, images: { list: ["skeleton_1.png", "skeleton_2.png"], speed: 1000 }, callback: callback26, sounds: null };
      case 27:// Mirage Shop
        return { selector: null, images: null, callback: () => { }, sounds: null, shop: true };
      case 29://black list
        var selector29 = {
          nb: 5,
          message: "Choose 5 cards that wont appear in your next.",
          filter: (c) => { return !c.new && !unobtainableCard.includes(c.id) },
          noMultiple: true,
          clue: true
        }

        var callback29 = (cards) => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('setFlagAndTurn', { name: 'FILTER', value: cards.map((e) => e.id) })
          return { message: `This cards wont appear in the next booster you buy.`, cards: cards }
        }
        return { selector: selector29, images: null, callback: callback29, sounds: null };
      case 30: // rich man wallet
        var callback30 = () => {
          store.dispatch('burnCard', { id, rare })
          var maxMoney = store.state.maxMoney > 100 ? store.state.maxMoney + 50 : 200
          store.dispatch('setFlagAndTurn', { name: 'MAX_MONEY', value: maxMoney })
          if (store.state.maxMoney === 200) {
            return { message: 'Your wallet will now be able to containe twice as much.' }
          }
          if (store.state.maxMoney > 200) {
            return { message: 'Your wallet can now hold 50 more coins.' }
          }
        }
        return { selector: null, images: null, callback: callback30, sounds: null };
      case 31:// moist chameleon
        var selector31 = {
          nb: 1,
          message: "Choose the card to duplicate.",
          filter: (c) => { return c.normal + c.rare > 0 && c.id != 31 },
        }

        var callback31 = (cards) => {
          store.dispatch('burnCard', { id, rare })
          var cards31 = [cards[0]]
          store.dispatch('saveCard', cards[0])
          if (rare) {
            cards31.push(cards[0])
            store.dispatch('saveCard', cards[0])
          }
          return { message: `You now have two ${cards[0].id > 9 ? cards[0].id : '0' + cards[0].id}: ${cardInfo[cards[0].id].name} `, compare: true, cards: [{ id, rare }, ...cards31] }
        }
        return { selector: selector31, images: null, callback: callback31, sounds: null };
      case 32://artist kit
        var callback32 = () => {
          store.dispatch('burnCard', { id, rare })
          if (store.state.cards[42].normal > 0) {
            store.dispatch('burnCard', { id: 42, rare: false })
          }
          else {
            store.dispatch('burnCard', { id: 42, rare: true })
          }
          store.dispatch('burnCard', { id, rare })
          store.dispatch('saveCard', { id: 43, rare })
          return { message: 'Critics all agree. The is the most influancial piece of this century.', cards: [{ id: 43, rare }] }
        }
        return { selector: null, images: null, callback: callback32, sounds: null, painting: true };
      case 33:// back halley game
        store.dispatch('burnCard', { id, rare })
        return { card: { id, rare }, callback: () => { }, sounds: null, cup: true };
      case 35://L3-38
        var selector35 = {
          nb: 1,
          message: "Choose a card you want to attract.",
          filter: (c) => !unobtainableCard.includes(c.id),
          noMultiple: true,
          clue: true
        }
        var callback35 = (card) => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('setFlagAndTurn', { name: 'ID_ROBOT', value: { id: card[0].id, multiply: rare ? 3 : 2 } })
          return { message: `The card ${cardInfo[card[0].id].name} is now ${rare ? 'three times' : 'twice'} as likely to appear in a booster.` }
        }
        return { selector: selector35, images: null, callback: callback35, sounds: null };
      case 37://Emerald mine
        var callback37 = () => {
          store.dispatch('burnCard', { id, rare })
          var cards37 = []
          var nbCard = rare ? 9 : 7
          for (var i = 0; i < nbCard; i++) {
            store.dispatch('saveCard', { id: 0, rare: false })
            cards37.push({ id: 0, rare: false })
          }
          return { message: `You've won ${nbCard} emerald card`, cards: cards37 }
        }
        return { selector: null, images: null, callback: callback37, sounds: null };
      case 38://sunny day
        var callback38 = () => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('setFlagAndTurn', { name: 'DAY', value: true })
          return { message: "It's day time now." }
        }
        return { selector: null, images: { list: ['jour.gif'], speed: 3000 }, callback: callback38, sounds: null };
      case 39://Piece of gum
        var gum1 = "gum1.png";
        var gum2 = "gum2.png";
        var callback39 = () => {
          store.dispatch('burnCard', { id, rare })
          var cards39 = store.state.cards.filter((elmt) => { return elmt.normal + elmt.rare > 0 && elmt.id != 53 })
          var lostCard = cards39[Math.floor(Math.random() * cards39.length)]
          if (lostCard.normal > 0 && lostCard.rare <= 0) {
            store.dispatch('burnCard', { id: lostCard.id, rare: false })
            return { message: "You fool! you fell for it! You've lost random card.", cards: [{ id: lostCard.id, rare: false }] }
          }
          else if (lostCard.normal <= 0 && lostCard.rare > 0) {
            store.dispatch('burnCard', { id: lostCard.id, rare: true })
            return { message: "You fool! you fell for it! You've lost random card.", cards: [{ id: lostCard.id, rare: true }] }
          }
          else {
            var choice22 = Math.random() > 0.5
            store.dispatch('burnCard', { id: lostCard.id, rare: choice22 })
            return { message: "You fool! you fell for it! You've lost random card.", cards: [{ id: lostCard.id, rare: choice22 }] }
          }
        }

        return { selector: null, images: { list: [gum1, gum2], speed: 1000 }, callback: callback39, sounds: null };
      case 40:// restricted section
        var callback40 = () => {
					var valueChinese = ['必','西','	弟','衣','记','爱','挨','恩','披','酷']
          store.dispatch('burnCard', { id, rare })
					var code  = store.state.safeCode.split('')
					console.log(code)
					var message40 = ""
					message40+= `0 =${valueChinese[0]}\n`
					message40+= `1 =${valueChinese[1]}\n`
					message40+= `2 =${valueChinese[2]}\n`
					message40+= `3 =${valueChinese[3]}\n`
					message40+= `4 =${valueChinese[4]}\n`
					message40+= `5 =${valueChinese[5]}\n`
					message40+= `6 =${valueChinese[6]}\n`
					message40+= `7 =${valueChinese[7]}\n`
					message40+= `8 =${valueChinese[8]}\n`
					message40+= `9 =${valueChinese[9]}\n`
					message40+= `The code of the safe is : ${valueChinese[parseInt(code[0])] + valueChinese[parseInt(code[1])] +valueChinese[parseInt(code[2])] +valueChinese[parseInt(code[3])]}`
          return { message: message40 }
        }
        return { selector: null, images: null, callback: callback40, sounds: null };
      case 41:// barky boy
        var callback41 = () => {
          if (!store.state.shops.includes('bark')) {
            store.dispatch('burnCard', { id, rare })
            store.dispatch('addItemShop', 'bark')
            store.dispatch('setFlagAndTurn', { name: 'DOG_BOOSTER', value: 1200000 })
            store.state.modifyShop()
          }
          return { message: 'This dog will periodically bring you a doggy edition booster.' }
        }
        return { selector: null, images: null, callback: callback41, sounds: null };
      case 45: //chemist kit
        var cardNb45 = rare ? 3 : 5

        var selector45 = {
          nb: 1,
          message: "Choose the cards that will be turn into a shiny one.",
          filter: (c) => { return c.normal >= cardNb45 && c.id != 45 },
          noMultiple: true,
          clue: true
        }
        var callback45 = (c) => {
          store.dispatch('burnCard', { id, rare })
          for (var i = 0; i < cardNb45; i++) {
            store.dispatch('burnCard', { id: c[0].id, rare: false })
          }
          store.dispatch('saveCard', { id: c[0].id, rare: true })

          return { message: `You now have the rare version of the card ${cardInfo[c[0].id].name}`, cards: [{ id: c[0].id, rare: true }] }
        }
        return { selector: selector45, images: null, callback: callback45, sounds: null };
      case 46:// holy vaccine
        store.dispatch('burnCard', { id, rare })
        store.dispatch('setFlagAndTurn', { name: 'MALEDICTION', value: false })
        return { selector: null, images: null, callback: () => { return { message: "You've been cured." } }, sounds: null };
      case 47:// archeological dig
        store.dispatch('burnCard', { id, rare })
        return { excavation: true, nbBrush: rare ? 7 : 5 };
      case 48://commercial road
        var callback48 = () => {
          store.dispatch('burnCard', { id, rare })
          store.dispatch('addMirageShop')
          return { message: 'A new road has been builed and with it come new product for the shop.' }
        }
        return { selector: null, images: null, callback: callback48, sounds: null };
      case 49://magician trick
        var callback49 = () => {
          store.dispatch('burnCard', { id, rare })
          var cardNb49 = rare ? 5 : 3
          var cards49 = store.state.cards.filter((c) => { return c.id > 35 && (c.normal + c.rare) > 0 && c.id != 49 })
          var card49 = cards49[Math.floor(Math.random() * cards49.length)]
          var cardsDisplay49 = [card49, card49]
          for (var i = 0; i < cardNb49 - 1; i++) {
            store.dispatch('saveCard', card49)
            cardsDisplay49.push(card49)
          }
          return { message: `The card ${cardInfo[card49.id].name} has been ${rare ? "Quintuple" : "tripled"}.`, cards: cardsDisplay49, compare: true }
        }
        return { selector: null, images: null, callback: callback49, sounds: null };
      case 52:
        store.dispatch('burnCard', { id, rare })
        return { blessing: true };
      case 53:
        var selector53 = {
          nb: 3,
          message: "Choose 3 cards to keep in your next run.",
          filter: (c) => { return (c.normal + c.rare) >= 0 && c.id != 53 },
          noMultiple: true
        }

        var callback53 = (cards) => {
          store.dispatch('burnCard', { id, rare })
          return { message: `It took you ${this.msToTime(store.state.totalTime)} to complete the game`, cards }
        }

        return { selector: selector53, images: null, callback: callback53, sounds: null, past: true };

    }
  }

	static msToTime(duration) {
			var seconds = Math.floor((duration / 1000) % 60);
			var minutes = Math.floor((duration / (1000 * 60)) % 60);
			var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;

		var dateString = ""
		if(hours && hours>0)  dateString =+ hours + " hours "
		if(minutes && minutes>0)  dateString =+ minutes + " minutes "
		if(seconds && seconds>0)  dateString =+ seconds + " seconds "
		return dateString
	}

  static playPosibility(id, rare) {
    switch (id) {
      case 2:
        return true;
      case 3:
        return store.state.cards[3].normal + store.state.cards[3].rare >= 7;
      case 4:
        return true;
      case 5:
        return true;
      case 6:
        return true;
      case 11:
        return store.state.boosterState == false;
      case 12:
        return true;
      case 13:
        return true;
      case 14:
        return true;
      case 15:
        return true;
      case 18:
        return store.state.boosterState == false;
      case 19:
        return true;
      case 21:
        return true;
      case 22:
        return true;
      case 24:
        return store.state.day == true;
      case 26:
        return store.state.cards[10].normal + store.state.cards[10].rare > 1 && store.state.cards[44].normal + store.state.cards[44].rare > 1;
      case 27:
        return true;
      case 29:
        var cardNb = 0
        store.state.cards.forEach(element => {
          cardNb += element.normal
          cardNb += element.rare
        });
        return cardNb >= 6;
      case 30:
        return true;
      case 31:
        return true;
      case 32:
        return store.state.cards[42].normal + store.state.cards[42].rare > 0 && store.state.cards[32].normal + store.state.cards[32].rare > 0;
      case 33:
        return store.state.money >= 10;
      case 35:
        return true;
      case 37:
        return true;
      case 38:
        return store.state.day == false;
      case 39:
        return true;
      case 40:
        return true;
      case 41:
        return true;
      case 45:
        var answer = false
        store.state.cards.forEach(element => {
          var nb = rare ? 3 : 5
          if (element.normal >= nb) {
            answer = true
          }
        });
        return answer
      case 46:
        return store.state.malediction == true;
      case 47:
        return true;
      case 48:
        return true;
      case 49:
        return true;
      case 51:
        var answer51 = false
        for (var i = 36; i <= 53; i++) {
          if (store.state.cards[i].normal + store.state.cards[i].rare > 0) {
            answer51 = true;
          }
        }
        return answer51
      case 52:
        return true;
      case 53:
        return true;
    }
    return false
  }
}