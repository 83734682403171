import * as PIXI from 'pixi.js'
import store from '../store'

export default class boosterShop {
  constructor(app, boosterName, standName, price, stageBooster , throwError) {
    this.app = app
    this.standName = standName
    this.boosterName = boosterName
    this.stageBooster = stageBooster
    this.loader = PIXI.Loader.shared
    this.throwError = throwError

    //settings
    this.animationSpeed = 0.3
    this.standWidth = 200
    this.price = price
    this.style = new PIXI.TextStyle({
      fontSize: 20,
      fill: 'white'
    })

    //states
    this.state = this.idleState
    this.full = true

    //twitch state
    this.scaleSpeed = 0.01
    this.scaleSaveX = 0
    this.scaleSavey = 0

    //texture
    let standTextureArray = []
    for (let i = 0; i <= 10; i++) {
      standTextureArray.push(this.loader.resources[`${standName}${i}`].texture)
    }

    //pixi object
    this.container = new PIXI.Container()
    this.boosterS = new PIXI.Sprite(this.loader.resources[this.boosterName].texture)
    this.standS = new PIXI.AnimatedSprite(standTextureArray)
    this.standFrontS = new PIXI.Sprite(this.loader.resources.chest.texture)

    this.standS.animationSpeed = this.animationSpeed
    this.standS.loop = false
    this.standS.width = 395
    this.standS.height = 100

    this.standFrontS.width = 395
    this.standFrontS.height = 100

    this.standFrontS.y = 0

    this.boosterS.width = 50
    this.boosterS.height = 80
    this.boosterS.x = 75
    this.boosterS.y = this.full ? -30 : 120
    this.boosterS.anchor.set(0.5)

    this.container.sortableChildren = true
    this.container.interactive = true
    this.container.buttonMode = true;

    this.container.on('pointerdown', () => {
      if (this.full) {
        this.buyBooster()
      }
    })

    this.container.addChild(this.standS)    
    this.container.addChild(this.boosterS)
    this.container.addChild(this.standFrontS)


    this.app.ticker.add((delta) => this.gameLoop(delta))
  }

  gameLoop(delta) {
    this.state(delta)
  }

  idleState() {}

  twitchState(delta) {
    this.boosterS.scale.x += delta * this.scaleSpeed
    this.boosterS.scale.y += delta * this.scaleSpeed

    if (this.boosterS.scale.x >= 0.1 && this.scaleSpeed > 0) {
      this.scaleSpeed *= -1
    }
    if (this.scaleSpeed < 0 && this.boosterS.scale.x <= this.scaleSaveX) {
      this.boosterS.scale.x = this.scaleSaveX
      this.boosterS.scale.y = this.scaleSaveY
      this.scaleSpeed = 0.01
      this.state = this.idleState
      this.boosterS.y = 200
      store.dispatch('stageBooster', {boosterID : 1 , value : this.price})
      this.stageBooster()
      setTimeout(()=>{
        this.reset()
      } , 500)
    }
  }

  moveUpState(delta) {
    this.boosterS.y -= delta * 5
    if (this.boosterS.y <= -30) {
      this.boosterS.y = -30
      this.state = this.idleState
      this.full = true
    }
  }

  reset() {
      this.standS.gotoAndPlay(0)
      this.state = this.moveUpState
  }

  buyBooster() {
    if (store.state.money >= this.price && store.state.stageBoosterCards.length == 0) {
      this.full = false
      this.scaleSaveX = this.boosterS.scale.x
      this.scaleSaveY = this.boosterS.scale.y
      this.state = this.twitchState
    }
    else if(store.state.money <= this.price){
      this.throwError('MONEY')
    }
  }

  getContainer() {
    return this.container
  }

  setPosition(x, y) {
    this.container.x = x
    this.container.y = y
  }

  destroy() {
    this.container.parent.removeChild(this.container)
  }
}
