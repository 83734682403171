import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import "firebase/compat/analytics";

// firebase init
const config = {
  apiKey: "AIzaSyAoy6VdPAyPTGqyqUOMKGeYEEq8lcfFn50",
  authDomain: "booster-master-f137c.firebaseapp.com",
  projectId: "booster-master-f137c",
  storageBucket: "booster-master-f137c.appspot.com",
  messagingSenderId: "578288057723",
  appId: "1:578288057723:web:a6c45d9ef3707db5dcd1de",
  measurementId: "G-SH1JDXRGSN"
}

firebase.initializeApp(config)
firebase.analytics();

// firebase db
const db = firebase.firestore()
var functions = firebase.functions();

// firebase auth
const auth = firebase.auth()

export default {
  auth,
  db,
  firebase,
  functions
}