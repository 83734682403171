import * as PIXI from 'pixi.js'
import card from '@/assets/card.json'

const widthCard = 288 
const heightCard = 400 

export default class Card {
  constructor(
    app,
    number,
    imageTexture,
    isNew,
    rare = false,
    hidden = true,
  ) {
    this.rotatingDirection = -0.16

    this.styleName = new PIXI.TextStyle({
      fontSize: 18,
      fill: 'black',
      fontFamily: "Montserrat Alternates",
      fontWeight: 900
    })

    this.styleNumber = new PIXI.TextStyle({
      fontSize: 26,
      fill: 'white',
      fontFamily: 'Montserrat Alternates',
      fontWeight: 900
    })

    this.styleDescription = new PIXI.TextStyle({
      fontSize: 14,
      fill: 'black',
      fontFamily: 'Montserrat Alternates',
      wordWrap: true,
      wordWrapWidth: 233,
      lineHeight: 17,
      fontWeight: 900
    })

    //settings
    this.app = app
    this.position = number
    this.hidden = hidden
    this.loader = PIXI.Loader.shared
    this.rare = rare
    this.isNew = isNew

    //game state
    this.state = this.idleState

    //texture
    var fram = []
    if (number < 18) {
      this.textureF = this.loader.resources.copperNormal.texture
      this.textureB = PIXI.Texture.from(require('../assets/cards/copper_back.png'))
      if (rare) {
        for (let i = 0; i <= 30; i++) {
          if (i > 16) {
            fram.push(this.loader.resources[`copper${16}`].texture)
          }
          else {
            fram.push(this.loader.resources[`copper${i}`].texture)
          }
        }
      }
    }
    else if (number <= 35) {
      this.textureF = this.loader.resources.silverNormal.texture
      this.textureB = PIXI.Texture.from(require('../assets/cards/silver_back.png'))
      if (rare) {
        for (let i = 0; i <= 30; i++) {
          if (i > 16) {
            fram.push(this.loader.resources[`silver${16}`].texture)
          }
          else {
            fram.push(this.loader.resources[`silver${i}`].texture)
          }
        }
      }
    }
    else {
      this.textureF = this.loader.resources.goldNormal.texture
      this.textureB = PIXI.Texture.from(require('../assets/cards/gold_back.png'))
      if (rare) {
        for (let i = 0; i <= 30; i++) {
          if (i > 16) {
            fram.push(this.loader.resources[`gold${16}`].texture)
          }
          else {
            fram.push(this.loader.resources[`gold${i}`].texture)
          }
        }
      }
    }

    this.imageTexture = imageTexture

    //pixi object
    this.container = new PIXI.Container()
    this.number = new PIXI.Text(card[number].id, this.styleNumber)
    this.name = new PIXI.Text(card[number].name, this.styleName)
    this.description = new PIXI.Text(card[number][rare ? (card[number].descriptionRare ? 'descriptionRare' : "description") : "description"], this.styleDescription)//AnimatedSprite

    this.spriteB = new PIXI.Sprite(this.textureB)
    if (rare) {
      this.spriteF = new PIXI.AnimatedSprite(fram)
      this.spriteF.animationSpeed = 0.4
      this.spriteF.play()
    }
    else {
      this.spriteF = new PIXI.Sprite(this.textureF)
    }

    this.image = new PIXI.Sprite(this.imageTexture)

    if (this.isNew) {
      this.newTag = new PIXI.Sprite(
        this.loader.resources['newTag'].texture
      )

      this.newTag.height = 90
      this.newTag.width = 90

      this.newTag.x = widthCard - 190
      this.newTag.y = -heightCard / 2 - 30
    }


    this.spriteB.height = heightCard
    this.spriteB.width = widthCard
    this.spriteB.anchor.set(0.5)

    this.spriteF.height = heightCard
    this.spriteF.width = 0
    this.spriteF.anchor.set(0.5)

    this.container.height = heightCard
    this.container.width = widthCard

    this.image.height = 150
    this.image.width = 250

    this.image.x = -this.image.width / 2
    this.image.y = -139

    this.number.x = -118
    this.number.y = -183

    this.name.x = -62
    this.name.y = -177

    this.description.x = -115
    this.description.y = 33

    this.container.sortableChildren = true

    //game loop
    this.app.ticker.add((delta) => this.gameLoop(delta))


    this.container.addChild(this.spriteF)
    this.container.addChild(this.spriteB)

    if (!hidden) {
      this.container.addChild(this.name)
      this.container.addChild(this.number)
      this.container.addChild(this.description)
      this.container.addChild(this.image)
    }
  }
  flip() {
    this.state = this.flipAnimation
  }

  gameLoop(delta) {
    this.state(delta)
  }

  idleState() { }

  flipAnimation() {
    this.container.scale.x += this.rotatingDirection
    if (this.container.scale.x < 0 && this.rotatingDirection < 0) {
      this.rotatingDirection *= -1
      this.spriteB.zIndex = -1
      this.spriteB.width = 0;
      if (this.hidden) {
        this.spriteF.width = widthCard;
        this.container.addChild(this.name)
        this.container.addChild(this.number)
        this.container.addChild(this.image)
        this.container.addChild(this.description)
        if (this.isNew) {
          this.container.addChild(this.newTag)
        }
      }
    }
    if (this.container.scale.x >= 1 && this.rotatingDirection > 0) {
      this.container.scale.x = 1
      this.rotatingDirection *= -1
      this.hidden = !this.hidden
      this.state = this.idleState
    }
  }

  getContainer() {
    return this.container
  }

  getNumber() {
    return this.position
  }

  getShiny() {
    return this.rare
  }

  setPosition(x, y) {
    this.container.x = x
    this.container.y = y
  }

  destroy() {
    this.container.parent.removeChild(this.container)
  }
}
