<template>
  <div class="dark-background-blessing">
    <div class="hand-container">
      <Button class="choice" @click="choose(1)">
        Fast worker effect is permanent
      </Button>
      <img
        src="../assets/scene_image/hand.png"
        alt=""
        class="hand-img left-hand"
      />
    </div>
    <div class="hand-container">
      <Button class="choice" @click="choose(2)">
        Card always sell for 3 more coins
      </Button>
      <img src="../assets/scene_image/hand.png" alt="" class="hand-img" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "@/components/button";
export default {
  props: {
    next: Function,
  },
  components: {
    Button,
  },
  methods: {
    ...mapActions(["setFlagAndTurn", "changeWorker"]),
    choose(choice) {
      if (choice == 1) {
        this.setFlagAndTurn({ name: "BLESSING", value: "FAST_WORKER" });
        this.changeWorker(7000);
        this.next({
          selector: null,
          images: null,
          callback: () => {
            return {
              message:
                "The effect of the card fast worker is permanent. Money will arrive twice as fast",
            };
          },
          sounds: null,
        });
      } else {
        this.setFlagAndTurn({ name: "BLESSING", value: "COIN" });
        this.next({
          selector: null,
          images: null,
          callback: () => {
            return {
              message: "All of the card will always sell for 3 more coins.",
            };
          },
          sounds: null,
        });
      }
    },
  },
};
</script>

<style>
.dark-background-blessing {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.hand-container {
  width: 500px;
  position: relative;
}

.choice {
  width: 300px;
  height: 60px;
  border-radius: 8px;
  background-color: rgb(146, 29, 255);
  color: white;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 16px;
  z-index: 2;
  cursor: pointer;
}

.hand-img {
  width: 100%;
  margin-bottom: 4vh;
}

.left-hand {
  transform: scaleX(-1);
}
</style>