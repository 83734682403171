<template>
  <div class="dark-background">
    <div :class="{'cup-wrapper' : !isMobile , 'cup-wrapper-mobile' : isMobile}">
      <span class="message-cup">{{ getMessage }}</span>
      <img
        src="../assets/ballCup/back.png"
        alt="background"
        :class="{'background-cup' : !isMobile , 'background-cup-mobile' : isMobile}"
      />
      <img
        src="../assets/ballCup/cup.png"
        alt="cup"
        class="cup"
        id="cup-0"
        @click="checkCup(0)"
      />
      <img
        src="../assets/ballCup/cup.png"
        alt="cup"
        class="cup"
        id="cup-1"
        @click="checkCup(1)"
      />
      <img
        src="../assets/ballCup/cup.png"
        alt="cup"
        class="cup"
        id="cup-2"
        @click="checkCup(2)"
      />
      <div :class="{'ball-cup' : !isMobile , 'ball-cup-mobile' : isMobile}" id="ball-cup"></div>
      <div class="cup-money-choice" v-if="showOption">
        <Button :size=" isMobile ? 'small' :'xl'" :active="getMoney >= 10" @click="chooseValue(10)">
          <span>10</span>
          <div class="coin-cup"></div>
        </Button>
        <Button :size=" isMobile ? 'small' :'xl'" :active="getMoney >= 20" @click="chooseValue(20)">
          <span>20</span>
          <div class="coin-cup"></div>
        </Button>
        <Button :size=" isMobile ? 'small' :'xl'" :active="getMoney >= 30" @click="chooseValue(30)">
          <span>30</span>
          <div class="coin-cup"></div>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/button";
import mobile from "is-mobile";
export default {
  data() {
    return {
      message: "Choose how much you want to bet.",
      showOption: true,
      end: false,
      choose: true,
    };
  },
  components: {
    Button,
  },
  props: {
    hide: Function,
    steps: Object,
  },
  mounted() {
    this.stepNb = this.getCupBall;
    this.speed = (2.5 / this.stepNb).toString();
    this.stepsCup = [];
    this.solutionPosition = 1;

    for (var step = 0; step < this.stepNb; step++) {
      var cups = [0, 1, 2];
      var cupA = cups[Math.floor(Math.random() * 3)];
      cups = cups.filter((id) => id != cupA);
      var cupB = cups[Math.floor(Math.random() * 2)];
      this.stepsCup.push({ a: cupA, b: cupB });

      if (this.solutionPosition === cupA) {
        this.solutionPosition = cupB;
      } else if (this.solutionPosition === cupB) {
        this.solutionPosition = cupA;
      }
    }

    this.cups = new Array(3);
    this.cups[0] = document.getElementById("cup-0");
    this.cups[1] = document.getElementById("cup-1");
    this.cups[2] = document.getElementById("cup-2");

    this.cups.forEach((cup, index) => {
      cup.style.marginLeft = `${index * 29}%`;
      cup.style.transition = `margin ${this.speed}s`;
    });

    this.setFlagAndTurn({ name: "BALL_CUP", value: this.stepNb + 2 });
  },

  methods: {
    ...mapActions(["addMoney", "setFlagAndTurn"]),
    switchCup(a, b, time) {
      setTimeout(() => {
        var last = [0, 1, 2].filter((nb) => nb != a && nb != b)[0];
        var aMargin = this.cups[a].style.marginLeft;
        this.cups[a].style.marginLeft = this.cups[b].style.marginLeft;
        this.cups[b].style.marginLeft = aMargin;
        this.cups[a].style.zIndex = 3;
        this.cups[last].style.zIndex = 2;
        this.cups[b].style.zIndex = 1;
        this.cups[a].style.animation = `${this.speed}s swicthFront`;
        this.cups[b].style.animation = `${this.speed}s swicthBack`;
      }, time);
    },
    start() {
      this.showBall(1);
      setTimeout(() => {
        this.stepsCup.forEach((move, index) => {
          this.switchCup(move.a, move.b, index * this.speed * 1000);
        });
      }, 2500);
      setTimeout(() => {
        this.end = true;
        this.message = "Choose the right cup.";
      }, this.stepsCup.length * this.speed * 1000 + 2500);
    },
    showBall(nb) {
      if (nb == 1 || !this.end) {
        var ball = document.getElementById("ball-cup");
        ball.style.marginLeft = `${
          (!this.end ? 1 : this.solutionPosition) * 29 + 17
        }%`;
        setTimeout(() => (ball.style.marginLeft = "200%"), 2000);
      }
      this.cups[nb].style.zIndex = 2;
      this.cups[nb].style.animation = "2s showBall";
    },
    checkCup(nb) {
      if (this.end && this.choose) {
        this.choose = false;
        this.showBall(parseInt(nb));
        if (nb == 1) {
          this.message = `You won ${
            (this.steps.card.rare ? 3 : 2) * this.value
          } coin!`;
          this.addMoney((this.steps.card.rare ? 3 : 2) * this.value);
        } else {
          this.message = `You lost ${this.value} coin`;
        }
        setTimeout(() => this.hide(), 2000);
      }
    },
    chooseValue(value) {
      this.showOption = false;
      this.message = "";
      this.value = value;
      this.addMoney(-value);
      this.start();
    },
  },
  computed: {
    ...mapGetters(["getMoney", "getCupBall"]),
    getMessage() {
      return this.message;
    },
    isMobile() {
      return mobile();
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-cup {
  height: 100%;
}

.background-cup-mobile {
  width: 100%;
}

.cup-money-choice {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-cup {
  width: 15%;
  height: 40%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-cup.inactive {
  background-color: gray;
  pointer-events: none;
}

.btn-cup span {
  font-family: "Balsamiq Sans", cursive;
  font-size: 3vh;
  margin-right: 10px;
}

.ball-cup {
  width: 10vh;
  height: 10vh;
  background-color: honeydew;
  bottom: 25%;
  margin-left: 200%;
  border-radius: 50%;
  position: absolute;
}

.ball-cup-mobile {
  width: 5vh;
  height: 5vh;
  background-color: honeydew;
  bottom: 25%;
  margin-left: 200%;
  border-radius: 50%;
  position: absolute;
}

.coin-cup {
  background-color: #ffad0d;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border-radius: 50%;
}

.cup-wrapper {
  position: relative;
  height: 100%;
}

.cup-wrapper-mobile {
  position: relative;
  width: 100%;
}

.cup {
  position: absolute;
  bottom: 23%;
  left: 9%;
  width: 24%;
}

.message-cup {
  font-family: "Balsamiq Sans", cursive;
  font-size: 4vh;
  text-align: center;
  color: white;
  position: absolute;
  width: 100%;
  top: 10%;
}

.hide-choice {
  visibility: hidden;
}

@keyframes swicthFront {
  from {
    transform: scale(1);
  }
  50% {
    padding-bottom: -4%;
    transform: scale(1.1);
  } /* ignorée */
  to {
    transform: scale(1);
  }
}

@keyframes swicthBack {
  from {
    transform: scale(1);
  }
  50% {
    padding-bottom: 4%;
    transform: scale(0.9);
  } /* ignorée */
  to {
    transform: scale(1);
  }
}

@keyframes showBall {
  from {
    margin-bottom: 0%;
  }
  50% {
    margin-bottom: 10%;
  } /* ignorée */
  to {
    margin-bottom: 0%;
  }
}
</style>