<template>
  <div class="dark-background">
    <div class="info-container" v-if="card">
      <div class="big-Card-column">
        <div class="card-container-viewer">
          <div :id="'hover' + idRnd" class="hover-container"></div>
          <div class="card-effect-container" :id="'container' + idRnd">
            <Card :card="cardDisplayed" class="card-obj" />
            <div :id="'gradient' + idRnd" class="gradient"></div>
          </div>
        </div>

        <div class="swicth">
          Normal
          <div
            class="switch-body"
            @click="switchState"
            :class="{ 'switch-innactive': hideSwitch }"
          >
            <div
              class="circle-switch"
              :class="{
                'circle-margin': rare,
                'circle-switch-innactive': hideSwitch,
              }"
            ></div>
          </div>
          Rare
        </div>
      </div>
      <div class="card-info-paraph">
        <div class="padding-container-viewer">
          <span class="name-viewer">{{
            `${numberCorrector(infos.id)}:  ${infos.name} ${
              rare ? "- rare" : ""
            }`
          }}</span>
          <br />
          <span class="quantity-viewer">{{
            `Normal: ${card.normal} - ${"rare"}: ${card.rare}`
          }}</span>
          <p class="description-viewer">
            {{
              infos[
                rare
                  ? infos.descriptionRare
                    ? "descriptionRare"
                    : "description"
                  : "description"
              ]
            }}
          </p>
          <p class="detail-viewer" v-if="card.clue == true">
            {{ infos.detail }}
          </p>
        </div>
        <div class="btn-container">
          <div class="btn-sell-container">
            <div
              class="number-card-btn"
              :class="{
                'not-visible': card.id == 53 && card.normal + card.rare == 1,
              }"
            >
              {{ `x${rare ? card.rare : card.normal}` }}
            </div>
            <Button
              :class="{
                'gold-price': getMultiplyNb > 0,
                'btn-shine': rare,
                'not-visible': card.id == 53 && card.normal + card.rare == 1,
              }"
              @click="sell"
              id="btn-coin"
            >
              {{ `Sell ${getValue}` }}
              <div class="coin-btn"></div>
              <span
                class="money-text-message"
                v-if="
                  getMoney +
                    (getMultiplyNb > 0 ? infos.value * 3 : infos.value) >
                  getMaxMoney
                "
                >{{
                  getMoney == getMaxMoney
                    ? "wallet is full"
                    : "wallet almost full"
                }}</span
              >
            </Button>
          </div>
          <Button
            @click="play"
            :active="getAction == true"
            :class="{ 'not-visible': !infos.playable }"
          >
            Play
          </Button>
        </div>
      </div>
      <img
        src="../assets/close.svg"
        alt="close btn"
        class="cross-viewer"
        @click="hide"
      />
    </div>
  </div>
</template>

<script>
import Card from "@/components/card";
import Button from "@/components/button";
import cardInfo from "@/assets/card.json";
import { mapActions, mapGetters } from "vuex";
import CardAction from "@/cardActions";
export default {
  data() {
    return {
      rare: false,
      idRnd: Math.random().toString(),
      lastClicked: Date.now(),
      action: false,
    };
  },
  components: {
    Card,
    Button,
  },
  props: {
    card: Object,
    hide: Function,
    playCard: Function,
  },
  mounted() {
    this.init();
    this.rare = this.card.normal == 0;
  },
  computed: {
    ...mapGetters(["getMoney", "getMaxMoney", "getMultiplyNb", "getBlessing"]),
    infos() {
      return cardInfo[this.card.id];
    },
    hideSwitch() {
      return this.card.normal == 0 || this.card.rare == 0;
    },
    cardDisplayed() {
      return { id: this.card.id, rare: this.rare };
    },
    getValue() {
      var valueTmp = this.rare ? this.infos.value * 2 : this.infos.value;
      valueTmp = this.getMultiplyNb > 0 ? valueTmp * 3 : valueTmp;
      return this.getBlessing == "COIN" ? valueTmp + 3 : valueTmp;
    },
    getAction() {
      return CardAction.playPosibility(this.card.id, this.rare);
    },
  },
  methods: {
    ...mapActions(["soldCard"]),
    numberCorrector(n) {
      return n < 10 ? "0" + n : n;
    },
    sell() {
      var cardNb = this.card.normal + this.card.rare;
      var cardNb2 = this.rare ? this.card.rare : this.card.normal;

      var value = this.getValue;

      for (var i = 0; i < value; i++) {
        this.coinBursed();
      }

      this.soldCard({
        value,
        id: this.infos.id,
        rare: this.rare,
      });
      if (
        ((this.rare && this.card.normal == 0) ||
          (!this.rare && this.card.rare == 0)) &&
        cardNb == 1
      ) {
        this.hide();
      }
      if (cardNb2 == 1) {
        this.rare = !this.rare;
      }
    },
    coinBursed() {
      var btn = document.getElementById("btn-coin");
      var newDiv = document.createElement("div");
      newDiv.style.position = "absolute";
      newDiv.style.zIndex = -1;
      newDiv.style.left =
        (Math.floor(Math.random() * 100) + 120).toString() + "px";
      newDiv.style.transition = "margin 0.4s , opacity 0.4s";
      newDiv.classList = ["coin-btn"];
      btn.appendChild(newDiv);
      setTimeout(() => {
        newDiv.style.marginBottom =
          (Math.floor(Math.random() * 100) + 150).toString() + "px";
        newDiv.style.opacity = "0.7";
        var direction = Math.random() > 0.5;
        if (direction) {
          newDiv.style.marginLeft =
            Math.floor(Math.random() * 30 + 10).toString() + "px";
        } else {
          newDiv.style.marginRight =
            Math.floor(Math.random() * 30 + 10).toString() + "px";
        }
      }, 10);
      setTimeout(() => {
        newDiv.remove();
        newDiv.style.opacity = "300";
      }, 300);
    },
    play() {
      this.playCard(this.card.id, this.rare);
    },
    switchState() {
      this.rare = !this.rare;
    },
    init() {
      this.containerRotate = document.getElementById("container" + this.idRnd);
      this.container = document.getElementById("hover" + this.idRnd);
      this.gradient = document.getElementById("gradient" + this.idRnd);
      this.gradient.style.background = "";
      this.container.addEventListener("mousemove", (e) => {
        // e = Mouse click event.
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top; //y position within the element.
        var angle = 0; //Math.floor(Math.atan((y-(450/2))/(x-324/2)))
        var xOrigin = x - 289 / 2;
        var yOrigin = y - 405 / 2;
        if (xOrigin < 0) {
          angle = 270 - (Math.atan(yOrigin / -xOrigin) * 180) / Math.PI;
        } else {
          angle = 90 + (Math.atan(yOrigin / xOrigin) * 180) / Math.PI;
        }
        angle = (angle + 180) % 360;
        if (Date.now() - this.lastClicked > 10) {
          this.lastClicked = Date.now();

          this.gradient.style.background =
            "linear-gradient(" +
            angle +
            "deg, rgba(255,255,255,255) 0%,rgba(255,255,255,0) 90%)";
        }
        var style = `rotateX( ${(y / 460) * 40 - 20}deg ) rotateY( ${-(
          (x / 350) * 40 -
          20
        )}deg )`;
        this.containerRotate.style.webkitTransform = style;
        this.containerRotate.style.mozTranform = style;
        this.containerRotate.style.msTransform = style;
        this.containerRotate.style.oTransform = style;
        this.containerRotate.style.transform = style;
      });
      this.container.addEventListener("mouseleave", () => {
        this.gradient.style.background = "";
        this.containerRotate.style.transition = "transform .5s ease-in-out";
        var style = `rotateX( 0deg ) rotateY( 0deg )`;
        this.containerRotate.style.webkitTransform = style;
        this.containerRotate.style.mozTranform = style;
        this.containerRotate.style.msTransform = style;
        this.containerRotate.style.oTransform = style;
        this.containerRotate.style.transform = style;
      });
      this.container.addEventListener("mouseenter", () => {
        this.containerRotate.style.transition = "";
      });
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.money-text-message {
  position: absolute;
  font-size: 18px;
  bottom: -30px;
  color: white;
}

.number-card-btn {
  width: 30px;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 4px;
}

.btn-sell-container {
  display: flex;
}

.hide {
  visibility: hidden;
}

.gradient {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  opacity: 0.5;
  border-radius: 20px;
}

.hover-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.cross-viewer {
  position: absolute;
  color: white;
  width: 27px;
  margin: 10px;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.card-obj {
  height: 100%;
}

.info-container {
  position: relative;
  width: 850px;
  height: 450px;
  display: flex;
}

.name-viewer {
  font-family: "Balsamiq Sans", cursive;
  font-size: 22px;
  color: white;
}

.gold-price {
  color: gold !important;
}

.quantity-viewer {
  font-family: "Balsamiq Sans", cursive;
  font-size: 19px;
  color: white;
}

.description-viewer {
  font-family: "Balsamiq Sans", cursive;
  font-size: 18px;
  color: white;
}

.detail-viewer {
  font-family: "Balsamiq Sans", cursive;
  font-size: 18px;
  color: rgb(204, 255, 238);
}

.card-container-viewer {
  height: 90%;
  position: relative;
  perspective: 1000px;
}

.padding-container-viewer {
  margin: 10px;
  height: 100%;
}

.btn-container {
  height: 20%;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin-bottom: 45px;
}

.big-Card-column {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.card-effect-container {
  width: 289px;
  height: 405px;
  transform-style: preserve-3d;
}

.not-visible {
  visibility: hidden;
}

.swicth {
  height: 10%;
  width: 100%;
  font-size: 18px;
  font-family: "Balsamiq Sans", cursive;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.switch-body {
  height: 30px;
  width: 70px;
  border-radius: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: rgb(255, 150, 131);
}

.switch-innactive {
  background-color: rgba(117, 117, 117, 0.644);
  pointer-events: none;
}

.circle-margin {
  margin-left: 42px !important;
}

.circle-switch {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin: 3px;
  transition: margin 0.3s;
  background-color: rgb(248, 248, 248);
}

.circle-switch-innactive {
  background-color: rgb(180, 180, 180);
}

.btn-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.btn-action-viewer {
  height: 60px;
  width: 200px;
  background-color: teal;
  border-radius: 8px;
  cursor: pointer;
}

.coin-btn {
  margin-left: 5px;
  width: 19px;
  height: 19px;
  background: #ffad0d;
  border-radius: 50%;
}

.btn-shine {
  background-image: url("../assets/btn-shine.png");
  background-color: rgb(255, 78, 78);
}

.btn-action.inactive {
  opacity: 0.5;
}

.card-info-paraph {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>