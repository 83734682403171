<template>
  <div class="catalog" :class="{ catalogDark: !getDay }">
    <CardViewer
      ref="viewer"
      v-if="popinDisplayed == 'viewer' && !isMobile"
      :card="this.cardSelected"
      :hide="hide"
      :playCard="play"
    />
    <CardViewerMobile
      ref="viewer"
      v-if="popinDisplayed == 'viewer' && isMobile"
      :card="this.cardSelected"
      :hide="hide"
      :playCard="play"
    />
    <CardActionStep
      :hide="hide"
      ref="action"
      v-if="popinDisplayed == 'action'"
      :steps="steps"
      :next="goToPast"
    />
    <Painting
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'painting'"
      :steps="steps"
    />
    <Shop
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'shop'  && !isMobile"
      :steps="steps"
      :card="cardSelected"
    />
    <ShopMobile
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'shop'  && isMobile"
      :steps="steps"
      :card="cardSelected"
    />
    <Safe
      :next="
        (data) => {
          popinDisplayed = 'action';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'safe'"
      :steps="steps"
      :card="cardSelected"
    />
    <Blessing
      :next="
        (data) => {
          popinDisplayed = 'action';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'blessing'"
      :steps="steps"
    />
    <Excavation
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'excavation'  && !isMobile"
      :steps="steps"
      :card="cardSelected"
    />
    <ExcavationMobile
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      v-if="popinDisplayed == 'excavation'  && isMobile"
      :steps="steps"
      :card="cardSelected"
    />
    <Cup
      :hide="hide"
      v-if="popinDisplayed == 'cup'"
      :steps="steps"
      :card="cardSelected"
    />
    <CopyMachine
      :next="
        (data) => {
          popinDisplayed = data ? 'action' : 'none';
          steps = data;
        }
      "
      :steps="steps"
      v-if="popinDisplayed == 'copy'"
    />
    <ReturnToPast
      v-if="popinDisplayed == 'past'"
      :cards="endInfo"
      :hide="hide"
      :catalogSwitch="catalogSwitch"
    />
    <div class="endingMessage" v-if="getEndingMessage && popinDisplayed == 'none'" >
      <span>The final booster has appeared.</span>
      <Button size="small" @click="hideEndingMessage">Done</Button>
    </div>
    <div
      :class="{
        'ratio-container': !isMobile,
        'ratio-container-mobile': isMobile,
      }"
    >
      <Page
        v-if="!isMobile"
        :orientationLeft="true"
        :cards="cardsForPage[0]"
        :navigate="navigate"
        :page="page"
        :showCard="showCard"
        :hint="hint"
      />
      <Page
        :orientationLeft="isMobile ? null : false"
        :cards="cardsForPage[1]"
        :navigate="navigate"
        :page="page + 1"
        :showCard="showCard"
        :hint="hint"
      />
    </div>
  </div>
</template>

<script>
import Page from "@/components/page";
import cardInfo from "@/assets/card.json";
import { mapGetters, mapActions } from "vuex";
import CardViewer from "@/components/card-viewer";
import CardViewerMobile from "@/components/cardViewerMobile";
import CardActionStep from "@/components/card-action";
import Painting from "@/components/painting";
import Safe from "@/components/safe";
import Shop from "@/components/shop";
import ShopMobile from "@/components/shop-mobile";
import Blessing from "@/components/blessing";
import Excavation from "@/components/excavation";
import ExcavationMobile from "@/components/excavationMobile"
import Cup from "@/components/cup";
import CopyMachine from "@/components/copyMachine";
import CardAction from "@/cardActions";
import ReturnToPast from "@/components/returnToPast";
import mobile from "is-mobile";
import Button from "@/components/button";
export default {
  data() {
    return {
      page: 0,
      popinDisplayed: "none",
      cardSelected: null,
      steps: null,
      endInfo: null,
    };
  },

  props: {
    catalogSwitch: Function,
  },

  components: {
    Page,
    CardViewer,
    CardActionStep,
    Painting,
    Shop,
    ShopMobile,
    Safe,
    Blessing,
    Excavation,
    Cup,
    CopyMachine,
    ReturnToPast,
    CardViewerMobile,
    ExcavationMobile,
    Button,
  },
  methods: {
    ...mapActions(["resetNew","setFlagAndTurn"]),
    navigate(direction) {
      if (
        (direction < 0 && this.page > (this.isMobile ? -1 : 0)) ||
        (direction > 0 && this.page < 4)
      ) {
        this.page += direction * (this.isMobile ? 1 : 2);
      }
    },
    showCard(card) {
      this.resetNew(card.id);
      this.cardSelected = card;
      this.popinDisplayed = "viewer";
    },
    hide() {
      this.popinDisplayed = "none";
    },
    hideEndingMessage(){
      this.setFlagAndTurn({ name: "ENDING_MESSAGE", value: false });
    },
    goToPast(cards) {
      this.endInfo = cards;
      this.popinDisplayed = "past";
    },
    hint(nb) {
      this.steps = {
        callback: () => {
          return {
            message: `Clue for the card ${nb > 9 ? nb : "0" + nb}: ${
              cardInfo[nb].name
            }:\n\n ${cardInfo[nb].detail}`,
          };
        },
      };
      this.popinDisplayed = "action";
    },
    play(id, rare) {
      this.steps = CardAction.play(id, rare);
      if (this.steps.painting) {
        this.popinDisplayed = "painting";
        return;
      }
      if (this.steps.shop) {
        this.popinDisplayed = "shop";
        return;
      }
      if (this.steps.safe) {
        this.popinDisplayed = "safe";
        return;
      }
      if (this.steps.blessing) {
        this.popinDisplayed = "blessing";
        return;
      }
      if (this.steps.excavation) {
        this.popinDisplayed = "excavation";
        return;
      }
      if (this.steps.cup) {
        this.popinDisplayed = "cup";
        return;
      }
      if (this.steps.copy) {
        this.popinDisplayed = "copy";
        return;
      }
      this.popinDisplayed = "action";
    },
  },
  computed: {
    ...mapGetters(["getCards", "getDay" , "getEndingMessage"]),
    cardsForPage() {
      let cardPage = [[], []];
      this.getCards.forEach((card, index) => {
        if (index < 9 + this.page * 9 && index >= this.page * 9) {
          cardPage[0].push(card);
        } else if (
          index < 9 + (this.page + 1) * 9 &&
          index >= (this.page + 1) * 9
        ) {
          cardPage[1].push(card);
        }
      });
      return cardPage;
    },
    isMobile() {
      return mobile();
    },
  },
};
</script>

<style>
.catalog {
  height: calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.catalog-container {
  width: 100%;
  padding-bottom: 55%;
  background: green;
}

.endingMessage {
  width: 100%;
  height: 100%;
  color: white;
  font-family: "Balsamiq Sans", cursive;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.911);
}

.ratio-container {
  width: 100vw;
  height: 48.9vw; /* 90/50.61 = 1.778 */
  background-image: url("../assets/leather.png");
  background-size: 100% 100%;
  border-radius: 8px;
  max-height: 87vh;
  max-width: 177.78vh; /* 16/9 = 1.778 */
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
}

.ratio-container-mobile {
  width: 100%;
  height: 70%; /* 90/50.61 = 1.778 */
  background-image: url("../assets/leather.png");
  background-size: 100% 100%;
  border-radius: 8px;
  max-height: 87vh;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
}
</style>