<template>
  <div class="dark-background-shop-mobile">
    <div class="shop-mobile_description">
      <div class="shop-mobile-pannel">{{ description }}</div>
    </div>
    <div class="shop-mobile_front">
      <img
        src="../assets/shop/shop-front-mobile.png"
        alt="store front"
        class="shop-mobile_front-image"
      />
    </div>
    <div class="shop-mobile-item">
      <img
        :src="require(`@/assets/shop/${shopElements[index]}.png`)"
        alt="shop item"
        class="shop-mobile-item_image"
        id="image-shop"
      />
    </div>
    <div class="shop-mobile_button">
      <Button
        :size="'small'"
        @click="BuyShop(shopElements[index])"
        :active="!(itemInfo.price > getCards[0].normal + getCards[0].rare)"
      >
        {{ "Buy " + itemInfo.price }}
        <img src="@/assets/emerald.png" alt="" class="price-shop-image"
      /></Button>
      <Button :filled="false" :size="'small'" @click="leave">Leave</Button>
    </div>
    <img
      src="@/assets/arrow-shop.png"
      alt=""
      class="shop-mobile_arrow-left"
      @click="moveChoice(-1)"
      v-if="index != 0"
    />
    <img
      src="@/assets/arrow-shop.png"
      alt=""
      class="shop-mobile_arrow-right"
      @click="moveChoice(1)"
      v-if="index != shopElements.filter((c) => c != 'none').length - 1"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ShopInfo from "@/assets/shop.json";
import Button from "@/components/button";
export default {
  data() {
    return {
      index: 0,
      venus: 0,
      moist: 0,
      buy: false,
    };
  },

  props: {
    next: Function,
    card: Object,
  },

  components: {
    Button,
  },

  computed: {
    ...mapGetters(["getShopItems", "getCards", "getShops"]),
    shopElements() {
      var elementTmp = [...this.getShopItems];

      elementTmp = elementTmp.filter((c) => {
        return !(c == "boosterShop" && this.getShops.includes("single0"));
      });
      if (elementTmp.length < 5) {
        for (var i = 0; i < 6 - elementTmp.length; i++) {
          elementTmp.push("none");
        }
      }
      return elementTmp;
    },
    itemInfo() {
      if (this.shopElements[this.index]) {
        return ShopInfo[this.shopElements[this.index]];
      }
      return { message: "", price: 0 };
    },
    description() {
      return `${this.itemInfo.name}\n\n\t${this.itemInfo.message}`;
    },
  },

  methods: {
    ...mapActions(["buyShopItem", "burnCard"]),
    moveChoice(val) {
      this.index += val;
    },
    async BuyShop(item) {
      //destroy card once the user start bying
      if (!this.buy) {
        this.buy = true;
        this.burnCard(this.card);
      }

      if (item == "venus") {
        this.venus++;
      }
      if (item == "moist") {
        this.moist++;
      }
      if (item == "boosterShop") {
        this.moveChoice(-1);
      }

      //drop down effect
      if (item == "moist") {
        var itemDom = document.getElementById("image-shop");
        itemDom.style.transition = "margin 0.01s";
        itemDom.style.marginBottom = "-100vh";
        setTimeout(async () => {
          itemDom.style.transition = "margin 0.5s";
          itemDom.style.marginBottom = "0px";

          await this.buyShopItem(item);
        }, 100);
      } else {
        await this.buyShopItem(item);
      }
    },
    leave() {
      if (this.venus + this.moist == 0) {
        this.next();
        return;
      }
      var callback = () => {
        var message = "";
        if (this.moist > 0 && this.venus > 0) {
          message = `You've bought the card Venus and ${this.moist} card moist chameleon.`;
          return {
            message,
            cards: [
              { id: 31, rare: false },
              { id: 34, rare: false },
            ],
          };
        } else if (this.moist > 0) {
          message = `You've bought ${this.moist} card${
            this.moist > 1 ? "s" : ""
          } moist chameleon.`;
          return { message, cards: [{ id: 31, rare: false }] };
        } else if (this.venus > 0) {
          message = `You've bought  the card Venus.`;
          return { message, cards: [{ id: 34, rare: false }] };
        }
      };
      var data = {
        selector: null,
        images: null,
        callback: callback,
        sounds: null,
      };
      this.next(data);
    },
  },
};
</script>

<style>
.dark-background-shop-mobile {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shop-mobile_description {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-mobile-pannel {
  font-family: "Balsamiq Sans", cursive;
  height: 70%;
  width: 70%;
  background-color: white;
  border-radius: 20px;
  border: saddlebrown solid 5px;
  padding: 10px;
  white-space: pre-line;
  text-align: center;
  line-height: 15px;
}

.shop-mobile_front {
  width: 100%;
  height: calc(100% - 100px);
  position: relative;
  overflow: hidden;
}

.shop-mobile_front-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.shop-mobile-item {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-mobile-item_image {
  height: 30%;
  margin-top: 100px;
}

.shop-mobile_button {
  width: 80%;
  height: 20%;
  position: absolute;
  z-index: 2;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.shop-mobile_arrow-left {
  position: absolute;
  bottom: 40%;
  left: 10px;
  z-index: 1;
}

.shop-mobile_arrow-right {
  position: absolute;
  bottom: 40%;
  right: 10px;
  z-index: 1;
  transform: rotate(180deg);
}
</style>