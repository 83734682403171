<template>
    <div class="selectorMobile mainContainer">
      <div class="selectorMobile wrapper">
      <span class="selectorMobile title main">{{ this.steps.selector.message }}</span>
      <span
        class="selectorMobile remaining"
        :class="{'invisible' : !(steps.selector.nb - nbCardSelected &&!steps.selector.moneySum > 0)}"
      >{{ steps.selector.nb - nbCardSelected }} card remaining</span>
        <div class="selectorLoadingMobile" id="loaddingBarContainer" v-if="steps.selector.moneySum">
          <div class="loadBarMobile container">
            <div class="loadBarMobile bar" id="loadingBar"></div>
          </div>
          <span v-if="getSum>0" class="barValue">{{getSum}}</span><div v-if="getSum>0" class="coin"></div>
          <div class="loadBarMobile hint"></div>
        </div>
        <div class="selectorMobile cardList">
          <span class="selectorMobile title">Cards</span>
          <div class="selectorMobile scrollBox">
            <div
              class="selectorMobile cardWrapper"
              v-for="card in cards"
              :key="card.key"
              :class="{
                rare: card.rare,
                remove: card.nb == 0,
                add: card.visible && card.nb >= 1,
                hideCard: !card.visible,
              }"
              @click="select(card.key, true)"
            >
              <div class="selectorMobile id">
                {{ card.id }}
              </div>
              <div class="selectorMobile name">
                <span v-if="steps.selector.moneySum">{{
                  card.rare
                    ? getInfos[card.id].value * 2
                    : getInfos[card.id].value
                }}</span>
                <div v-if="steps.selector.moneySum" class="selector coin"></div>
                <span>{{
                  (steps.selector.clue && card.new
                    ? "??????"
                    : getInfos[card.id].name) + (card.rare ? "-rare" : "")
                }}</span>
              </div>
              <div
                class="selectorMobile numberWrapper"
                v-if="!steps.selector.noMultiple"
              >
                <span>X{{ card.nb }}</span>
              </div>
            </div>
          </div>
        </div>
        <img src="../assets/right.png" alt="left arrow rotate" class="rotate"/>
        <div class="selectorMobile cardList">
          <span class="selectorMobile title">Selected cards</span>
          <div class="selectorMobile scrollBox">
            <div
              class="selectorMobile cardWrapper"
              v-for="card in selected"
              :key="card.key"
              :class="{
                rare: card.rare,
                remove2: card.nb == 0,
                add2: card.visible && card.nb >= 1,
                hideCard: !card.visible,
              }"
              @click="select(card.key, false)"
            >
              <div class="selectorMobile id">
                {{ card.id }}
              </div>
              <div class="selectorMobile name">
                <span v-if="steps.selector.moneySum">{{
                  getInfos[card.id].value
                }}</span>
                <div v-if="steps.selector.moneySum" class="selectorMobile coin"></div>
                <span>{{
                  (steps.selector.clue && card.new
                    ? "??????"
                    : getInfos[card.id].name) + (card.rare ? "-rare" : "")
                }}</span>
              </div>
              <div
                class="selectorMobile numberWrapper"
                v-if="!steps.selector.noMultiple"
              >
                <span>X{{ card.nb }}</span>
              </div>
            </div>
          </div>

        </div>
          <div class="selectorMobile btnWrapper right">
            <Button size="small" :filled="false" @click="hide">Cancel</Button>
            <Button
              @click="validate()"
              :active="getValidationSelector == true"
            >
              {{steps.selector.moneySum ?`${Math.floor(getSum/getCardValue)} card${Math.floor(getSum/getCardValue)>0?'s':''}` : 'validate'}}
            </Button>
          </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import infoCard from "@/assets/card.json";
//import Card from "@/components/card";
import Button from "@/components/button";
import mobile from "is-mobile";
export default {
  data() {
    return {
      selected: [],
      width: 0,
    };
  },
  components:{
    Button
  },
  mounted(){
    if(this.steps.selector.moneySum)this.width = document.getElementById("loaddingBarContainer").offsetWidth
    this.updateBar()
  },
  props: {
    steps: Object,
    hide: Function,
    validateCards: Function,
    cards: Array,
  },
  methods:{
    validate(){
      this.validateCards(this.selected)
    },
    select(key, direction) {
      var cards1;
      var cards2;

      if (direction) {
        cards1 = this.cards;
        cards2 = this.selected;
      } else {
        cards1 = this.selected;
        cards2 = this.cards;
      }

      var card = cards1.find((card) => card.key === key);

      if (
        card.nb > 0 &&
        (this.steps.selector.nb - this.nbCardSelected > 0 ||
          this.steps.selector.moneySum ||
          !direction)
      ) {
        card.nb--;
        var cardSelected = cards2.find((card) => card.key === key);
        if (cardSelected) {
          cardSelected.nb++;
          cardSelected.visible = true;
        } else {
          cards2.push({
            key: card.key,
            id: card.id,
            rare: card.rare,
            nb: 1,
            visible: card.visible,
            new: card.new,
          });
        }
        if (card.nb == 0) {
          setTimeout(() => (card.visible = false), 200);
        }
      }
      this.updateBar();
    },
    updateBar() {
      try { 
        document.getElementById("loadingBar").style.width =
          Math.floor(((this.width *this.getSum) /(this.getCardValue * 3))) + "px";
      } catch (e) {
        this.e = e;
      }
    },
  },
  computed:{
    ...mapGetters(["getCards", "getCardScan"]),
    isMobile() {
      return mobile();
    },
    getInfos() {
      return infoCard;
    },
    endingCards() {
      return this.ending.cards.map((e, index) => {
        return index == 1 ? { ...e, arrow: true } : e;
      });
    },
    nbCardSelected() {
      var nb = 0;
      this.selected.forEach((c) => {
        nb += c.nb;
      });
      return nb;
    },
    getSum() {
      var sum = 0;
      this.selected.forEach((card) => {
        if (card.nb > 0) {
          sum +=
            (card.rare
              ? infoCard[card.id].value * 2
              : infoCard[card.id].value) * card.nb;
        }
      });
      return sum;
    },
    getCardValue() {
      return this.getCardScan.rare
        ? infoCard[this.getCardScan.id].value * 2
        : infoCard[this.getCardScan.id].value;
    },
    getValidationSelector() {
      return (
        this.steps.selector.nb - this.nbCardSelected == 0 ||
        (this.steps.selector.moneySum && this.getSum >= this.getCardValue)
      );
    },
  }
}
</script>

<style>
.selectorMobile.mainContainer {
  height: 100%;
  width: 100%;
  font-family: "Balsamiq Sans", cursive;
}

.selectorMobile.title {
  font-size: 24px;
  color: white;
}

.selectorMobile.wrapper {
  height: 100%;
  width: 95%;
  border-radius: 8px;
  background: #22222265;
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.selectorMobile.cardList {
  height: 30vh;
  width: 100%;
  border-radius: 8px;
}

.selectorMobile.cardWrapper {
  height: 46px;
  width: 95%;
  border-radius: 8px;
  margin: 8px;
  margin-bottom: 0px;
  background-color: beige;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.selectorMobile.cardWrapper.rare {
  background-color: rgb(255, 181, 196);
}

.rotate {
  transform: rotate(90deg);
}

.selectorMobile.scrollBox {
  height: 80%;
  width: 100%;
  background: #d8bc893b;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selectorMobile.hideCard {
  display: none;
}

.selectorMobile.numberWrapper {
  width: 46px;
  height: 70%;
  border-radius: 8px;
  background-color: black;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectorMobile.numberWrapper span {
  color: white;
  font-size: 18px;
  font-family: "Balsamiq Sans", cursive;
}

.selectorMobile.id {
  width: 36px;
  height: 100%;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectorMobile.name {
  flex: 1;
  height: 90%;
  color: black;
  font-size: 17px;
  border-left: 1px solid;
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.selectorMobile.cardWrapper img {
  width: 46px;
}

.selectorMobile.wrapper img {
  width: 30px;
  height: 30px;
}

.selectorMobile.remaining {
  color: white;
}

.selectorMobile.btnWrapper {
  height: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.selectorMobile.selectorMobile.btnWrapper span {
  color: white;
  margin-right: 10px;
  font-size: 18px;
}

.selectorMobile button {
  height: 40px;
  width: 147px;
  border-radius: 8px;
  font-size: 18px;
  font-family: "Balsamiq Sans", cursive;
  cursor: pointer;
}

.selectorMobile.coin {
  margin-right: 5px;
  margin-left: 5px;
}

.selectorLoadingMobile {
  height: 10px;
  width: 90%;
  top: 0px;
  right: -60px;
  margin-bottom: 5px;
  display: flex;
}

.invisible {
  visibility: hidden;
}

.cardValueMobile.oneValue {
  left: 40%;
}

.cardValueMobile.twoValue {
  left: 65%;
}

.cardValueMobile.threeValue {
  left: 90%;
}

.loadBarMobile.bar {
  height: 100%;
  width: 100%;
  background-color: aqua;
}

.loadBarMobile.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: solid 1px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.barValue {
  color: white;
  font-size: 22px;
  font-family: "Balsamiq Sans", cursive;
  margin-left: 5px;
}

.loadBarMobile.hint {
  width: 28%;
  position: absolute;
  height: 15px;
  left: calc(24px + 29%);
  border-left: 2px solid white;
  border-right: 2px solid white;
  z-index: 2;
}
</style>