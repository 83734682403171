import * as PIXI from 'pixi.js'
import newtag from './assets/new.png'
import store from '@/store'

import booster0 from './assets/booster0.png'
import booster1 from './assets/booster1.png'
import booster2 from './assets/booster2.png'
import booster3 from './assets/booster3.png'
import booster4 from './assets/booster4.png'
import booster5 from './assets/booster5.png'
import booster6 from './assets/booster6.png'

import copperBack from './assets/cards/copper_back.png'
import silverBack from './assets/cards/silver_back.png'
import goldBack from './assets/cards/gold_back.png'

import copperNormal from './assets/cards/copper_normal.png'
import silverNormal from './assets/cards/silver_normal.png'
import goldNormal from './assets/cards/gold_normal.png'

import copper0 from './assets/cards/copper-anim/copper0.gif'
import copper1 from './assets/cards/copper-anim/copper1.gif'
import copper2 from './assets/cards/copper-anim/copper2.gif'
import copper3 from './assets/cards/copper-anim/copper3.gif'
import copper4 from './assets/cards/copper-anim/copper4.gif'
import copper5 from './assets/cards/copper-anim/copper5.gif'
import copper6 from './assets/cards/copper-anim/copper6.gif'
import copper7 from './assets/cards/copper-anim/copper7.gif'
import copper8 from './assets/cards/copper-anim/copper8.gif'
import copper9 from './assets/cards/copper-anim/copper9.gif'
import copper10 from './assets/cards/copper-anim/copper10.gif'
import copper11 from './assets/cards/copper-anim/copper11.gif'
import copper12 from './assets/cards/copper-anim/copper12.gif'
import copper13 from './assets/cards/copper-anim/copper13.gif'
import copper14 from './assets/cards/copper-anim/copper14.gif'
import copper15 from './assets/cards/copper-anim/copper15.gif'
import copper16 from './assets/cards/copper-anim/copper16.gif'

import silver0 from './assets/cards/silver-anim/silver0.gif'
import silver1 from './assets/cards/silver-anim/silver1.gif'
import silver2 from './assets/cards/silver-anim/silver2.gif'
import silver3 from './assets/cards/silver-anim/silver3.gif'
import silver4 from './assets/cards/silver-anim/silver4.gif'
import silver5 from './assets/cards/silver-anim/silver5.gif'
import silver6 from './assets/cards/silver-anim/silver6.gif'
import silver7 from './assets/cards/silver-anim/silver7.gif'
import silver8 from './assets/cards/silver-anim/silver8.gif'
import silver9 from './assets/cards/silver-anim/silver9.gif'
import silver10 from './assets/cards/silver-anim/silver10.gif'
import silver11 from './assets/cards/silver-anim/silver11.gif'
import silver12 from './assets/cards/silver-anim/silver12.gif'
import silver13 from './assets/cards/silver-anim/silver13.gif'
import silver14 from './assets/cards/silver-anim/silver14.gif'
import silver15 from './assets/cards/silver-anim/silver15.gif'
import silver16 from './assets/cards/silver-anim/silver16.gif'

import gold0 from './assets/cards/gold-anim/gold0.gif'
import gold1 from './assets/cards/gold-anim/gold1.gif'
import gold2 from './assets/cards/gold-anim/gold2.gif'
import gold3 from './assets/cards/gold-anim/gold3.gif'
import gold4 from './assets/cards/gold-anim/gold4.gif'
import gold5 from './assets/cards/gold-anim/gold5.gif'
import gold6 from './assets/cards/gold-anim/gold6.gif'
import gold7 from './assets/cards/gold-anim/gold7.gif'
import gold8 from './assets/cards/gold-anim/gold8.gif'
import gold9 from './assets/cards/gold-anim/gold9.gif'
import gold10 from './assets/cards/gold-anim/gold10.gif'
import gold11 from './assets/cards/gold-anim/gold11.gif'
import gold12 from './assets/cards/gold-anim/gold12.gif'
import gold13 from './assets/cards/gold-anim/gold13.gif'
import gold14 from './assets/cards/gold-anim/gold14.gif'
import gold15 from './assets/cards/gold-anim/gold15.gif'
import gold16 from './assets/cards/gold-anim/gold16.gif'

import woodStand0 from './assets/woodStand/woodStand-0.png'
import woodStand1 from './assets/woodStand/woodStand-1.png'
import woodStand2 from './assets/woodStand/woodStand-2.png'
import woodStand3 from './assets/woodStand/woodStand-3.png'
import woodStand4 from './assets/woodStand/woodStand-4.png'
import woodStand5 from './assets/woodStand/woodStand-5.png'
import woodStand6 from './assets/woodStand/woodStand-6.png'
import woodStand7 from './assets/woodStand/woodStand-7.png'
import woodStand8 from './assets/woodStand/woodStand-8.png'
import woodStand9 from './assets/woodStand/woodStand-9.png'
import woodStand10 from './assets/woodStand/woodStand-10.png'

import chest from './assets/chestStand/chest.png'

import chest0 from './assets/chestStand/chest-0.png'
import chest1 from './assets/chestStand/chest-1.png'
import chest2 from './assets/chestStand/chest-2.png'
import chest3 from './assets/chestStand/chest-3.png'
import chest4 from './assets/chestStand/chest-4.png'
import chest5 from './assets/chestStand/chest-5.png'
import chest6 from './assets/chestStand/chest-6.png'
import chest7 from './assets/chestStand/chest-7.png'
import chest8 from './assets/chestStand/chest-8.png'
import chest9 from './assets/chestStand/chest-9.png'
import chest10 from './assets/chestStand/chest-10.png'

import ironChest from './assets/chestIron/chest.png'

import ironChest0 from './assets/chestIron/chest-0.png'
import ironChest1 from './assets/chestIron/chest-1.png'
import ironChest2 from './assets/chestIron/chest-2.png'
import ironChest3 from './assets/chestIron/chest-3.png'
import ironChest4 from './assets/chestIron/chest-4.png'
import ironChest5 from './assets/chestIron/chest-5.png'
import ironChest6 from './assets/chestIron/chest-6.png'
import ironChest7 from './assets/chestIron/chest-7.png'
import ironChest8 from './assets/chestIron/chest-8.png'
import ironChest9 from './assets/chestIron/chest-9.png'
import ironChest10 from './assets/chestIron/chest-10.png'

import single0 from './assets/single0.png'
import bark from './assets/bark.png'

import cardIllu0 from './assets/card-illustration/emerald.png'
import cardIllu1 from './assets/card-illustration/dirty-cauldron.png'
import cardIllu2 from './assets/card-illustration/bubble.png'
import cardIllu3 from './assets/card-illustration/dwarf.png'
import cardIllu4 from './assets/card-illustration/biscuit-chinois.png'
import cardIllu5 from './assets/card-illustration/luckycoin.png'
import cardIllu6 from './assets/card-illustration/factory-accident.png'
import cardIllu7 from './assets/card-illustration/crystal-vase.png'
import cardIllu8 from './assets/card-illustration/queen.png'
import cardIllu9 from './assets/card-illustration/coconut-cake.png'
import cardIllu10 from './assets/card-illustration/old-donjon.png'
import cardIllu11 from './assets/card-illustration/soapywater.png'
import cardIllu12 from './assets/card-illustration/generous-magpie.png'
import cardIllu13 from './assets/card-illustration/moon rock.png'
import cardIllu14 from './assets/card-illustration/fast-worker.png'
import cardIllu15 from './assets/card-illustration/kingofdice.png'
import cardIllu16 from './assets/card-illustration/werewolf.png'
import cardIllu17 from './assets/card-illustration/necklace.png'
import cardIllu18 from './assets/card-illustration/xray-glasses.png'
import cardIllu19 from './assets/card-illustration/printer.png'
import cardIllu20 from './assets/card-illustration/frog-council.png'
import cardIllu21 from './assets/card-illustration/forgotten-safe.png'
import cardIllu22 from './assets/card-illustration/risky-dice.png'
import cardIllu23 from './assets/card-illustration/diamant.png'
import cardIllu24 from './assets/card-illustration/scary-night.png'
import cardIllu25 from './assets/card-illustration/gambler-watch.png'
import cardIllu26 from './assets/card-illustration/time-traveler.png'
import cardIllu27 from './assets/card-illustration/mariage-shop-card.png'
import cardIllu28 from './assets/card-illustration/mystery-man.png'
import cardIllu29 from './assets/card-illustration/black-list.png'
import cardIllu30 from './assets/card-illustration/rich-man-wallet.png'
import cardIllu31 from './assets/card-illustration/moist-camaleon.png'
import cardIllu32 from './assets/card-illustration/artist-kit.png'
import cardIllu33 from './assets/card-illustration/cup-and-ball.png'
import cardIllu34 from './assets/card-illustration/venus-statue.png'
import cardIllu35 from './assets/card-illustration/L3-38.png'
import cardIllu36 from './assets/card-illustration/red-riding-hood.png'
import cardIllu37 from './assets/card-illustration/emerald-mine.png'
import cardIllu38 from './assets/card-illustration/sunny-day.png'
import cardIllu39 from './assets/card-illustration/pieceofgum.png'
import cardIllu40 from './assets/card-illustration/restricted-section.png'
import cardIllu41 from './assets/card-illustration/barky-boy.png'
import cardIllu42 from './assets/card-illustration/blank-canva.png'
import cardIllu44 from './assets/card-illustration/back-scratcher.png'
import cardIllu45 from './assets/card-illustration/chemist-kit.png'
import cardIllu46 from './assets/card-illustration/holy-vaccine.png'
import cardIllu47 from './assets/card-illustration/archeological-dig.png'
import cardIllu48 from './assets/card-illustration/commercial-road.png'
import cardIllu49 from './assets/card-illustration/magician-trick.png'
import cardIllu50 from './assets/card-illustration/fallen-star.png'
import cardIllu51 from './assets/card-illustration/septre.png'
import cardIllu52 from './assets/card-illustration/squeleton-blessing.png'
import cardIllu53 from './assets/card-illustration/time_machine.png'

import undefinedCard from './assets/card-illustration/undefinedCard.png'

const CardsIllu = [cardIllu0, cardIllu1, cardIllu2, cardIllu3, cardIllu4, cardIllu5, cardIllu6, cardIllu7, cardIllu8, cardIllu9, cardIllu10, cardIllu11, cardIllu12, cardIllu13, cardIllu14, cardIllu15, cardIllu16, cardIllu17, cardIllu18, cardIllu19, cardIllu20, cardIllu21, cardIllu22, cardIllu23, cardIllu24, cardIllu25, cardIllu26, cardIllu27, cardIllu28, cardIllu29, cardIllu30, cardIllu31, cardIllu32, cardIllu33, cardIllu34, cardIllu35, cardIllu36, cardIllu37, cardIllu38, cardIllu39, cardIllu40, cardIllu41, cardIllu42, null, cardIllu44, cardIllu45, cardIllu46, cardIllu47, cardIllu48, cardIllu49, cardIllu50, cardIllu51, cardIllu52, cardIllu53]

export default class boosterShop {
  constructor() {
    this.loader = PIXI.Loader.shared
  }

  async load(callback) {
    this.loader.add('newTag', newtag)

    this.loader.add('booster0', booster0)
    this.loader.add('booster1', booster1)
    this.loader.add('booster2', booster2)
    this.loader.add('booster3', booster3)
    this.loader.add('booster4', booster4)
    this.loader.add('booster5', booster5)
    this.loader.add('booster6', booster6)

    this.loader.add('copperNormal', copperNormal)
    this.loader.add('copperBack', copperBack)

    this.loader.add('silverNormal', silverNormal)
    this.loader.add('silverBack', silverBack)

    this.loader.add('goldNormal', goldNormal)
    this.loader.add('goldBack', goldBack)

    this.loader.add('copper0', copper0)
    this.loader.add('copper1', copper1)
    this.loader.add('copper2', copper2)
    this.loader.add('copper3', copper3)
    this.loader.add('copper4', copper4)
    this.loader.add('copper5', copper5)
    this.loader.add('copper6', copper6)
    this.loader.add('copper7', copper7)
    this.loader.add('copper8', copper8)
    this.loader.add('copper9', copper9)
    this.loader.add('copper10', copper10)
    this.loader.add('copper11', copper11)
    this.loader.add('copper12', copper12)
    this.loader.add('copper13', copper13)
    this.loader.add('copper14', copper14)
    this.loader.add('copper15', copper15)
    this.loader.add('copper16', copper16)

    this.loader.add('silver0', silver0)
    this.loader.add('silver1', silver1)
    this.loader.add('silver2', silver2)
    this.loader.add('silver3', silver3)
    this.loader.add('silver4', silver4)
    this.loader.add('silver5', silver5)
    this.loader.add('silver6', silver6)
    this.loader.add('silver7', silver7)
    this.loader.add('silver8', silver8)
    this.loader.add('silver9', silver9)
    this.loader.add('silver10', silver10)
    this.loader.add('silver11', silver11)
    this.loader.add('silver12', silver12)
    this.loader.add('silver13', silver13)
    this.loader.add('silver14', silver14)
    this.loader.add('silver15', silver15)
    this.loader.add('silver16', silver16)

    this.loader.add('gold0', gold0)
    this.loader.add('gold1', gold1)
    this.loader.add('gold2', gold2)
    this.loader.add('gold3', gold3)
    this.loader.add('gold4', gold4)
    this.loader.add('gold5', gold5)
    this.loader.add('gold6', gold6)
    this.loader.add('gold7', gold7)
    this.loader.add('gold8', gold8)
    this.loader.add('gold9', gold9)
    this.loader.add('gold10', gold10)
    this.loader.add('gold11', gold11)
    this.loader.add('gold12', gold12)
    this.loader.add('gold13', gold13)
    this.loader.add('gold14', gold14)
    this.loader.add('gold15', gold15)
    this.loader.add('gold16', gold16)

    this.loader.add('woodStand0', woodStand0)
    this.loader.add('woodStand1', woodStand1)
    this.loader.add('woodStand2', woodStand2)
    this.loader.add('woodStand3', woodStand3)
    this.loader.add('woodStand4', woodStand4)
    this.loader.add('woodStand5', woodStand5)
    this.loader.add('woodStand6', woodStand6)
    this.loader.add('woodStand7', woodStand7)
    this.loader.add('woodStand8', woodStand8)
    this.loader.add('woodStand9', woodStand9)
    this.loader.add('woodStand10', woodStand10)

    if (store.state.shops.includes('chest')) {
      this.loader.add('chest0', chest0)
      this.loader.add('chest1', chest1)
      this.loader.add('chest2', chest2)
      this.loader.add('chest3', chest3)
      this.loader.add('chest4', chest4)
      this.loader.add('chest5', chest5)
      this.loader.add('chest6', chest6)
      this.loader.add('chest7', chest7)
      this.loader.add('chest8', chest8)
      this.loader.add('chest9', chest9)
      this.loader.add('chest10', chest10)
    }

    if (store.state.shops.includes('stand')) {
      this.loader.add('stand0', ironChest0)
      this.loader.add('stand1', ironChest1)
      this.loader.add('stand2', ironChest2)
      this.loader.add('stand3', ironChest3)
      this.loader.add('stand4', ironChest4)
      this.loader.add('stand5', ironChest5)
      this.loader.add('stand6', ironChest6)
      this.loader.add('stand7', ironChest7)
      this.loader.add('stand8', ironChest8)
      this.loader.add('stand9', ironChest9)
      this.loader.add('stand10', ironChest10)
    }

    this.loader.add('chest', chest)

    this.loader.add('stand', ironChest)

    this.loader.add('single0', single0)
    this.loader.add('bark', bark)
    this.loader.add('final', single0)

    this.loader.add('undefinedCard', undefinedCard)

    await this.loader.load(() => {
      callback()
    })
  }

  async loadCards(ids) {
    ids.forEach(id => { this.loader.add('cardIllu' + id, CardsIllu[id]) });

    return new Promise((resolve) => {
      this.loader.load(() => {
        resolve(true)
      })
    });
  }

  async loadBoxs() {
    store.state.shops.forEach(async name => {
      await this.loadBox(name)
    })

  }


  async loadBox(name) {
		try{
			switch (name) {
				case 'chest':
					this.loader.add('chest0', chest0)
					this.loader.add('chest1', chest1)
					this.loader.add('chest2', chest2)
					this.loader.add('chest3', chest3)
					this.loader.add('chest4', chest4)
					this.loader.add('chest5', chest5)
					this.loader.add('chest6', chest6)
					this.loader.add('chest7', chest7)
					this.loader.add('chest8', chest8)
					this.loader.add('chest9', chest9)
					this.loader.add('chest10', chest10)
					break
				case 'stand':
					this.loader.add('stand0', ironChest0)
					this.loader.add('stand1', ironChest1)
					this.loader.add('stand2', ironChest2)
					this.loader.add('stand3', ironChest3)
					this.loader.add('stand4', ironChest4)
					this.loader.add('stand5', ironChest5)
					this.loader.add('stand6', ironChest6)
					this.loader.add('stand7', ironChest7)
					this.loader.add('stand8', ironChest8)
					this.loader.add('stand9', ironChest9)
					this.loader.add('stand10', ironChest10)
					break
			}
		}
		catch(error){
			return
		}

    
    return new Promise((resolve) => {
      this.loader.load(() => {
        resolve(true)
      })
    });
  }
}
