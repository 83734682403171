<template>
  <router-view class="noselect" />
</template>
<script>
export default {
  components: {},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Yusei+Magic&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap'); */
/* font-family: 'Montserrat Alternates', sans-serif; */
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}
</style>
