<template>
  <div class="dark-background">
    <div class="excavationMobile-container">
      <img
        src="../assets/excavation/background.png"
        alt="foreground excavation"
        class="backgroundMobile"
      />
      <img
        src="../assets/excavation/foregroundMobile.png"
        alt="foreground excavation"
        class="foregroundMobile"
      />
      <img
        src="../assets/excavation/brush.png"
        alt="foreground excavation"
        class="brushMobile"
      />
      <span class="nbBrushMobile">{{ nbBrush }}</span>
      <div class="gridMobile">
        <img
          :src="
            require(`../assets/excavation/septre${this.getExcavation.septre.id}.png`)
          "
          alt="septre"
          class="septre"
          id="septre"
        />
        <div class="row" v-for="(grid, y) in this.getExcavation.grid" :key="y">
          <div
            class="square"
            :class="{ active: value == 0 }"
            v-for="(value, x) in grid"
            :key="x"
            @click="select(x, y)"
          >
            <img
              v-if="this.getExcavation.grid[y][x] != 0"
              :src="
                require(`../assets/excavation/cutout/${type(x, y).img}.png`)
              "
              alt="objects"
              class="cutout"
              :class="{
                one: type(x, y).rotation == 1,
                two: type(x, y).rotation == 2,
                three: type(x, y).rotation == 3,
              }"
            />
            <img
              v-if="
                this.getExcavation.solution[y][x] > 1 &&
                this.getExcavation.grid[y][x] != 0
              "
              :src="
                require(`../assets/excavation/obj${this.getExcavation.solution[y][x]}.png`)
              "
              alt="objects"
              class="object"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      setup: null,
      nbBrush: 0,
      diamond: 0,
      coin: 0,
    };
  },
  props: {
    next: Function,
    steps: Object,
  },
  mounted() {
    this.setup = { ...this.getExcavation };
    this.nbBrush = this.steps.nbBrush;
    var septre = document.getElementById("septre");
    septre.style.left = `${(
      this.getExcavation.septre.position.x * 20
    ).toString()}%`;
    septre.style.top = `${(
      this.getExcavation.septre.position.y * 20
    ).toString()}%`;
    septre.style.transform = `rotate(${
      this.getExcavation.septre.direction * 90
    }deg)`;
  },
  methods: {
    ...mapActions(["saveCard", "generateExcavation", "save"]),
    select(x, y) {
      if (this.nbBrush > 0 && this.setup.grid[y][x] == 0) {
        this.nbBrush--;
        this.setup.grid[y][x] = 1;
        if (this.setup.solution[y][x] == 2) {
          this.diamond++;
        }
        if (this.setup.solution[y][x] == 3) {
          this.coin++;
        }
        if (this.nbBrush == 0 || this.checkEmpty(this.setup.grid)) {
          setTimeout(() => {
            var cards = [];
            for (var i = 0; i < this.diamond; i++) {
              cards.push({ id: 23, rare: false });
              this.saveCard({ id: 23, rare: false });
            }
            for (var j = 0; j < this.coin; j++) {
              cards.push({ id: 5, rare: false });
              this.saveCard({ id: 5, rare: false });
            }
            var result = this.checkVictory();
            if (result) {
              cards.push({ id: 51, rare: false });
              this.saveCard({ id: 51, rare: false });
            }
            if (cards.length > 0) {
              this.next({
                callback: () => {
                  if (result) {
                    this.generateExcavation();
                  }
                  this.save();
                  return {
                    message: `You found this card${
                      cards.length > 1 ? "s" : ""
                    } while digging.`,
                    cards,
                  };
                },
              });
            } else {
              this.next();
            }
          }, 2000);
        }
      }
    },
    checkEmpty(grid) {
      for (let x = 0; x < 5; x++) {
        for (let y = 0; y < 5; y++) {
          if (grid[x][y] == 0) {
            return false;
          }
        }
      }
      return true;
    },
    leave() {
      this.save();
      this.next();
    },
    type(x, y) {
      var nbHole = 0;
      nbHole = this.filled(x - 1, y) ? nbHole : nbHole + 1;
      nbHole = this.filled(x, y - 1) ? nbHole : nbHole + 1;
      nbHole = this.filled(x + 1, y) ? nbHole : nbHole + 1;
      nbHole = this.filled(x, y + 1) ? nbHole : nbHole + 1;

      switch (nbHole) {
        case 0:
          return { img: 4 };
        case 1:
          if (!this.filled(x - 1, y)) {
            return { img: 3, rotation: 1 };
          } else if (!this.filled(x, y - 1)) {
            return { img: 3, rotation: 2 };
          } else if (!this.filled(x + 1, y)) {
            return { img: 3, rotation: 3 };
          }
          return { img: 3 };
        case 2:
          if (!this.filled(x - 1, y)) {
            if (!this.filled(x + 1, y)) {
              return { img: "2bis", rotation: 1 };
            } else if (!this.filled(x, y - 1)) {
              return { img: "2", rotation: 2 };
            } else {
              return { img: "2", rotation: 1 };
            }
          } else if (!this.filled(x + 1, y)) {
            if (!this.filled(x, y - 1)) {
              return { img: "2", rotation: 3 };
            } else {
              return { img: "2", rotation: 0 };
            }
          }

          return { img: "2bis", rotation: 0 };

        case 3:
          if (this.filled(x - 1, y)) {
            return { img: "1", rotation: 0 };
          } else if (this.filled(x, y - 1)) {
            return { img: "1", rotation: 1 };
          } else if (this.filled(x + 1, y)) {
            return { img: "1", rotation: 2 };
          }

          return { img: "1", rotation: 3 };

        case 4:
          return { img: 0 };
      }

      return { img: ((x + y) % 4) + 1 };
    },
    filled(x, y) {
      if (x < 0 || x > 4 || y < 0 || y > 4) {
        return true;
      }
      if (this.getExcavation.grid[y][x] > 0) {
        return false;
      }
      return true;
    },
    checkVictory() {
      for (var x = 0; x < 5; x++) {
        for (var y = 0; y < 5; y++) {
          if (this.setup.solution[y][x] == 1 && this.setup.grid[y][x] != 1) {
            return false;
          }
        }
      }
      return true;
    },
  },
  computed: {
    ...mapGetters(["getExcavation"]),
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.excavationMobile-container {
  height: 100%;
  position: relative;
  margin-top: 50%;
}

.foregroundMobile {
  width: 100%;
  height: auto;
}

.backgroundMobile {
  width: 95%;
  height: auto;
  position: absolute;
  left: 4%;
  top: 2%;
}

.gridMobile {
  position: absolute;
  height: 90vw;
  width: 90vw;
  z-index: 2;
  left: 5vw;
  top: 4.8vw;
}

.row {
  display: flex;
  height: 20%;
  width: 100%;
}

.square {
  border: solid 1px;
  height: 100%;
  width: 20%;
  position: relative;
}

.active.square {
  background-color: rgb(245, 220, 151);
}

.septre {
  position: absolute;
  width: 60%;
  z-index: -1;
}

.object {
  width: 100%;
  height: 100%;
}

.cutout {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.one {
  transform: rotate(90deg);
}

.two {
  transform: rotate(180deg);
}

.three {
  transform: rotate(270deg);
}

.brushMobile {
  position: absolute;
  left: 60vw;
  top: 100vw;
  height: 15%;
}

.nbBrushMobile {
  position: absolute;
  font-family: "Balsamiq Sans", cursive;
  font-size: 50px;
  left: 50vw;
  top: 105vw;
  height: 30%;
}
</style>