import * as PIXI from 'pixi.js'
import store from '../store'

export default class singleBooster {
  constructor(app, boosterName, standName, price, stageBooster , throwError , boosterId) {
    this.app = app
    this.standName = standName
    this.boosterName = boosterName
    this.stageBooster = stageBooster
    this.loader = PIXI.Loader.shared
    this.throwError = throwError
    this.boosterId = boosterId

    //settings
    this.animationSpeed = 0.3
    this.standWidth = 200
    this.price = price
    this.style = new PIXI.TextStyle({
      fontSize: 20,
      fill: 'white'
    })

    //states
    this.state = this.idleState
    this.full = true

    //twitch state
    this.scaleSpeed = 0.01
    this.scaleSaveX = 0
    this.scaleSavey = 0



    //pixi object
    this.container = new PIXI.Container()
    this.boosterS = new PIXI.Sprite(this.loader.resources[boosterName].texture)
    this.standS = new PIXI.Sprite(this.loader.resources[standName].texture)

    this.standS.width = 156
    this.standS.height = 120
    this.standS.y = -15

    this.boosterS.width = 50
    this.boosterS.height = 80
    this.boosterS.x = 75
    this.boosterS.y = -30
    this.boosterS.anchor.set(0.5)

    this.container.sortableChildren = true
    this.container.interactive = true
    this.container.buttonMode = true;

    this.container.on('pointerdown', () => {
      if (this.full) {
        this.buyBooster()
      }
    })

    this.container.addChild(this.boosterS)
    this.container.addChild(this.standS)

    this.app.ticker.add((delta) => this.gameLoop(delta))
  }

  gameLoop(delta) {
    this.state(delta)
  }

  idleState() {}

  twitchState(delta) {
    this.boosterS.scale.x += delta * this.scaleSpeed
    this.boosterS.scale.y += delta * this.scaleSpeed

    if (this.boosterS.scale.x >= 0.1 && this.scaleSpeed > 0) {
      this.scaleSpeed *= -1
    }
    if (this.scaleSpeed < 0 && this.boosterS.scale.x <= this.scaleSaveX) {
      this.boosterS.scale.x = this.scaleSaveX
      this.boosterS.scale.y = this.scaleSaveY
      this.scaleSpeed = 0.01
      this.state = this.idleState
      this.boosterS.y = 200
      store.dispatch('stageBooster', {boosterID : this.boosterId , value : this.price})
      this.stageBooster()
      setTimeout(()=>{
        this.state = this.moveDownState
      } , 500)
    }
  }

  moveUpState(delta) {
    this.boosterS.y -= delta * 5
    if (this.boosterS.y <= -10) {
      this.boosterS.y = -10
      this.state = this.idleState
      this.full = true
    }
  }

  moveDownState(delta) {
    this.standS.y += delta * 5
    if (this.standS.y > 300) {
      this.boosterS.y = -10
      store.dispatch('removeShop' , this.standName)
			store.state.modifyShop()
      this.full = true
			this.state = this.idleState
    }
  }

  buyBooster() {
    if (store.state.money >= this.price && store.state.stageBoosterCards.length == 0) {
      this.full = false
      this.scaleSaveX = this.boosterS.scale.x
      this.scaleSaveY = this.boosterS.scale.y
      this.state = this.twitchState
    }
    else if(store.state.money <= this.price){
      this.throwError('MONEY')
    }
  }

  getContainer() {
    return this.container
  }

  setPosition(x, y) {
    this.container.x = x
    this.container.y = y
  }

  destroy() {
    this.container.parent.removeChild(this.container)
  }
}
