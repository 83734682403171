<template>
  <div class="dark-background" id="bg">
    <img src="../assets/painting.png" alt="painting" class="painting-image" />
    <canvas
      class="canvas-black"
      :class="{ canvas: !isMobile, 'canvas-mobile': isMobile }"
      id="canvas"
    ></canvas>
    <div :class="{ 'palette-mobile': isMobile, 'color-palette': !isMobile }">
      <div
        class="square-palette"
        :class="{ selected: color == 'blue' }"
        style="background: blue"
        @click="changeColor('blue')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'red' }"
        style="background: red"
        @click="changeColor('red')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'green' }"
        style="background: green"
        @click="changeColor('green')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'brown' }"
        style="background: #832715"
        @click="changeColor('brown')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'yellow' }"
        style="background: yellow"
        @click="changeColor('yellow')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'white' }"
        style="background: white"
        @click="changeColor('white')"
      ></div>
      <div
        class="square-palette"
        :class="{ selected: color == 'black' }"
        style="background: black"
        @click="changeColor('black')"
      ></div>
    </div>
  </div>
  <div
    :class="{
      'action-container-palette': !isMobile,
      'action-container-palette-mobile': isMobile,
    }"
  >
    <Button size="small" :filled="false" @click="next(null)">Cancel</Button>
    <Button :size="isMobile ? 'small' : null" @click="reset">Erase</Button>
    <Button :size="isMobile ? 'small' : null" @click="save">Validate</Button>
  </div>
</template>
<script>
import Button from "@/components/button";
import mobile from "is-mobile";
import firebase from "./../../firebaseConfig.js";

export default {
  data() {
    return {
      drawing: false,
      lastClicked: Date.now(),
      canvasH: 0,
      canvasW: 0,
      color: "black",
    };
  },
  components: {
    Button,
  },
  props: {
    next: Function,
    steps: Object,
  },
  mounted() {
    this.bg = document.getElementById("bg");
    var widthBg = 360;
    var heightBg = 220;

    this.canvas = document.getElementById("canvas");
    this.canvas.height = heightBg.toString();
    this.canvas.width = widthBg.toString();
    this.ctx = this.canvas.getContext("2d");
    this.ctx.imageSmoothingEnabled = true;
    this.ctx.strokeStyle = "black";
    this.bg.addEventListener(
      this.isMobile ? "touchstart" : "mousedown",
      (event) => {
        this.x = Math.floor(
          ((event.clientX - this.canvas.offsetLeft) * widthBg) /
            this.canvas.offsetWidth
        );
        this.y = Math.floor(
          ((event.clientY - this.canvas.offsetTop) * heightBg) /
            this.canvas.offsetHeight
        );
        this.drawing = true;
        this.ctx.beginPath();
        this.ctx.lineWidth = 7;
        this.ctx.lineJoin = "round";
        this.ctx.moveTo(this.x, this.y);
        this.ctx.arc(this.x, this.y, 0.5, 0, 2 * Math.PI, false);
        this.ctx.lineWidth = 3;
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.lineWidth = 7;
      }
    );

    this.bg.addEventListener(this.isMobile ? "touchend" : "mouseup", () => {
      this.drawing = false;
    });

    this.bg.addEventListener(
      this.isMobile ? "touchmove" : "mousemove",
      (event) => {
        let position = null;
        if (this.isMobile) {
          var touch = event.touches[0];
          position = { x: touch.pageX, y: touch.pageY };
        } else {
          position = { x: event.clientX, y: event.clientY };
        }
        var x = Math.floor(
          ((position.x - this.canvas.offsetLeft) * widthBg) /
            this.canvas.offsetWidth
        );
        var y = Math.floor(
          ((position.y - this.canvas.offsetTop) * heightBg) /
            this.canvas.offsetHeight
        );
        if (this.drawing && Date.now() - this.lastClicked > 40) {
          this.lastClicked = Date.now();
          this.ctx.lineJoin = "round";
          this.ctx.lineTo(x, y);
          this.x = x;
          this.y = y;
          this.ctx.moveTo(this.x, this.y);
          this.ctx.stroke();
          this.ctx.arc(this.x, this.y, 0.5, 0, 2 * Math.PI, false);
          this.ctx.lineWidth = 3;
          this.ctx.fill();
          this.ctx.stroke();
          this.ctx.lineWidth = 7;
        }
      }
    );
  },
  methods: {
    changeColor(color) {
      this.color = color;
      switch (color) {
        case "blue":
          this.ctx.strokeStyle = "#0000FF";
          break;
        case "red":
          this.ctx.strokeStyle = "#ff0000";
          break;
        case "green":
          this.ctx.strokeStyle = "#008000";
          break;
        case "brown":
          this.ctx.strokeStyle = "#832715";
          break;
        case "yellow":
          this.ctx.strokeStyle = "#FFFF00";
          break;
        case "white":
          this.ctx.strokeStyle = "#FFFFFF";
          break;
        case "black":
          this.ctx.strokeStyle = "#000000";
          break;
      }
    },
    reset() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    save() {
      localStorage.setItem("canvas-booster-master", this.canvas.toDataURL());
      firebase.functions
        .httpsCallable("saveDrawing")({
          image: this.canvas.toDataURL(),
        })


      this.next(this.steps);
    },
  },
  computed: {
    isMobile() {
      return mobile();
    },
  },
};
</script>

<style>
.dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.painting-image {
  height: 100%;
}

.canvas {
  position: absolute;
  height: 60%;
  width: 40%;
  background: white;
}

.canvas-mobile {
  position: absolute;
  height: 40%;
  width: 90%;
  background: white;
}

.canvas-black {
  cursor: url("./../assets/brush/black.png"), auto;
}

.color-palette {
  width: 100px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 2%;
}

.palette-mobile {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 3%;
}

.square-palette {
  width: 70px;
  height: 70px;
  background-color: bisque;
  border: 1px solid white;
}

.action-container-palette {
  height: 60px;
  width: 600px;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.action-container-palette-mobile {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.btn-palette {
  height: 50px;
  width: 130px;
  background: rgb(17, 8, 8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-family: "Balsamiq Sans", cursive;
  cursor: pointer;
}

.selected {
  border: 5px solid rgb(255, 40, 40);
}
</style>