<template>
  <div
    class="btn_container btn_container--modifier"
    :class="{
      btn_container_filled: filled,
      btn_container_hollow: !filled,
      btn_container_small: size == 'small',
      btn_innactive: !active,
      btn_active: active,
      btn_container_xl: size == 'xl',
      btn_container_xxl: size == 'xxl',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "normal",
    },
    active: {
      type: Boolean,
      default: true,
    },
    giant: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.btn_container {
  width: 170px;
  height: 48px;
  border-radius: 8px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Balsamiq Sans", cursive;
}

.btn_container_small {
  width: 106px;
  height: 36px;
}

.btn_container_xl {
  width: 210px;
  height: 52px;
}

.btn_container_xxl {
  width: 260px;
  height: 60px;
}

.btn_container_filled {
  background-color: white;
  color: black;
}

.btn_container_hollow {
  background-color: black;
  color: white;
  border: solid white 2px;
}

.btn_container--modifier.btn_active:hover {
  border-radius: 18px;
}

.btn_innactive {
  opacity: 0.5;
  pointer-events: none;
}

.btn_active {
  cursor: pointer;
}
</style>