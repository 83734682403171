<template>
  <div class="home" :class="{ homeDark: !getDay }">
    <img src="../assets/tree.png" alt="" class="tree" v-if="!getDay" />
    <div class="loading" v-if="getLoadingScreen">
      <div class="homme-progress-bar-body">
        <div class="homme-progress-bar-inside" id="progressBar"></div>
      </div>
    </div>
    <NavBar
      :switchCatalog="switchCatalog"
      :catalogState="catalogState"
      class="homeBar"
    />
    <div
      id="canvas-container"
      :class="{ notVisible: catalogState || getLoadingScreen }"
    ></div>
    <div
      id="canvas-container-shop"
      :class="{ notVisible: catalogState || getLoadingScreen }"
    ></div>
    <div class="clickOnBooster" v-if="getDay && !getLoadingScreen">
      <p>clic on a booster to buy it</p>
    </div>
    <Catalog
      id="catalog"
      v-if="!getLoadingScreen"
      :class="{ catalogHide: !catalogState }"
      :catalogSwitch="switchCatalog"
    />
    <FooterBar :catalogState="catalogState" ref="footer" class="homeBar" />
    <div class="temporary-modal" v-if="getCheat">
      <input type="text" v-model="cardTmp" />
      <input type="checkbox" id="checkbox" v-model="rareTmp" />
      <button @click="add">add</button>
      <button @click="addAll">add all</button>
    </div>
    <div class="blobs" id="day-gradiant" :class="{ 'blob-day': getDay }"></div>
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import Catalog from "@/components/catalog";
import NavBar from "@/components/nav-bar";
import FooterBar from "@/components/footer-bar";
import assetLoader from "../assetLoader";
import Booster from "@/pixi_obj/booster";
import BoosterShop from "@/pixi_obj/booster-shop";
import BoosterShop2 from "@/pixi_obj/booster-shop2";
import BoosterShop3 from "@/pixi_obj/booster-shop3";
import singleBooster from "@/pixi_obj/singleBooster";
import WebFont from "webfontloader";
import mobile from "is-mobile";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      catalogState: false,
      test: false,
      app: null,
      stands: [],
      cardTmp: null,
      rareTmp: false,
      progress: 0,
    };
  },
  components: {
    Catalog,
    NavBar,
    FooterBar,
  },
  created() {
    document.title = "Moist Chameleon";
  },
  mounted() {
    WebFont.load({
      google: {
        families: ["Montserrat Alternates"],
      },
      active: () => {
        this.initData();
        this.loadAsset(() => {
          this.initMoneyTimer();
          this.setupBooster();
          this.setupShop();
          this.setLoadingScreen(false);
        });
      },
    });
    var grad = document.getElementById("day-gradiant");
    if (!this.isMobile) {
      document.onmousemove = (e) => {
        var mouseXpercentage = Math.round((e.clientX / grad.offsetWidth) * 100);
        var mouseYpercentage = Math.round(
          (e.clientY / grad.offsetHeight) * 100
        );

        if (this.getDay) {
          grad.style.background =
            "radial-gradient(at " +
            mouseXpercentage +
            "% " +
            mouseYpercentage +
            "%, #F8EEF8, #ffffff , #ffffff)";
        } else {
          grad.style.background =
            "radial-gradient(at " +
            mouseXpercentage +
            "% " +
            mouseYpercentage +
            "%, #ffffff00, #ffffff00, #000000FF, #000000FF)";
        }
      };
    }
  },
  methods: {
    ...mapActions([
      "initMoneyTimer",
      "initData",
      "saveCard",
      "setLoadingScreen",
      "setFlagAndTurn",
    ]),
    switchCatalog(state = null) {
      if (state == false) {
        return (this.catalogState = false);
      }
      this.catalogState = !this.catalogState;
    },
    add() {
      this.saveCard({ id: parseInt(this.cardTmp), rare: this.rareTmp });
    },
    addAll() {
      for (var i = 0; i < 52; i++) {
        this.saveCard({ id: i, rare: this.rareTmp });
      }
    },
    async loadAsset(callBack) {
      const canvas = document.getElementById("canvas-container");
      const canvasShop = document.getElementById("canvas-container-shop");
      if (this.isMobile) {
        this.app = new PIXI.Application({
          width: 300,
          height: 400,
          antialias: true,
          transparent: true,
        });

        this.appShop = new PIXI.Application({
          width: 300,
          height: 90,
          antialias: true,
          transparent: true,
        });
      } else {
        this.app = new PIXI.Application({
          width: 400, //320,
          height: 500, //460,
          antialias: true,
          transparent: true,
        });

        this.appShop = new PIXI.Application({
          width: 820,
          height: 180,
          antialias: true,
          transparent: true,
        });
      }

      canvas.appendChild(this.app.view);
      canvasShop.appendChild(this.appShop.view);

      const Loader = new assetLoader();
      Loader.loader.onProgress.add((val) => {
        this.progress = Math.floor(val.progress);
        if (this.getLoadingScreen) {
          document.getElementById("progressBar").style.width =
            this.progress.toString() + "%";
        }
      });
      await Loader.load(callBack);
    },
    setupBooster() {
      this.boosterObj = new Booster(
        this.app,
        this.getStageBoosterCard,
        this.getBoosterState, // is booster open
        () => {},
        "booster" + this.getBoosterNb
      );

      if (this.isMobile) {
        this.boosterObj.setPosition(
          (this.app.screen.width - 300 * 0.8) / 2,
          (this.app.screen.height - 460 * 0.8) / 2
        );
      } else {
        this.boosterObj.setPosition(
          (this.app.screen.width - 300) / 2,
          (this.app.screen.height - 460) / 2
        );
      }

      if (this.isMobile) this.boosterObj.getContainer().scale.x = 0.8;
      if (this.isMobile) this.boosterObj.getContainer().scale.y = 0.8;
      this.app.stage.addChild(this.boosterObj.getContainer());
      this.setFlagAndTurn({
        name: "MODIFY_BOOSTER",
        value: (card, position) => {
          this.boosterObj.modifyCard(card, position);
        },
      });
      this.setFlagAndTurn({
        name: "RESET_BOOSTER",
        value: () => {
          this.boosterObj.destroy();
          this.setupBooster();
        },
      });
    },
    setupShop() {
      this.getShops.forEach((standName, index) => {
        var shop = null;
        switch (standName) {
          case "chest":
            shop = new BoosterShop2(
              this.appShop,
              "booster1",
              standName,
              this.getShopPrices["chest"],
              this.setupBooster,
              this.error
            );
            this.stands.push(shop);
            break;
          case "woodStand":
            shop = new BoosterShop(
              this.appShop,
              "booster0",
              standName,
              this.getShopPrices["woodStand"],
              this.setupBooster,
              this.error
            );
            this.stands.push(shop);
            break;
          case "stand":
            shop = new BoosterShop3(
              this.appShop,
              "booster2",
              standName,
              this.getShopPrices["stand"],
              this.setupBooster,
              this.error
            );
            this.stands.push(shop);
            break;
          case "single0":
            shop = new singleBooster(
              this.appShop,
              "booster3",
              "single0",
              0,
              this.setupBooster,
              this.error,
              3
            );
            this.stands.push(shop);
            break;
          case "bark":
            shop = new singleBooster(
              this.appShop,
              "booster4",
              "bark",
              0,
              this.setupBooster,
              this.error,
              4
            );
            this.stands.push(shop);
            break;
          case "final":
            shop = new singleBooster(
              this.appShop,
              "booster6",
              "final",
              0,
              this.setupBooster,
              this.error,
              6
            );
            this.stands.push(shop);
            break;
        }
        if (this.isMobile)
          shop.setPosition(-30 + 45 * index, this.appShop.screen.height - 10);
        else
          shop.setPosition(10 + 160 * index, this.appShop.screen.height - 100);

        if (this.isMobile) shop.getContainer().scale.x = 0.8;
        if (this.isMobile) shop.getContainer().scale.y = 0.8;
        this.appShop.stage.addChild(shop.getContainer());
      });
      this.setFlagAndTurn({
        name: "MODIFY_SHOP",
        value: () => {
          this.stands.forEach((stand) => {
            stand.destroy();
          });
          this.stands = [];
          this.setupShop();
        },
      });
    },
    error(ERROR) {
      switch (ERROR) {
        case "MONEY":
          this.$refs.footer.moneyError(true);
          break;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getStageBoosterCard",
      "getBoosterState",
      "getShops",
      "getCards",
      "getDay",
      "getBoosterNb",
      "getShopPrices",
      "getLoadingScreen",
      "getCheat",
    ]),

    isMobile() {
      return mobile();
    },
  },
};
</script>
<style>
.clickOnBooster {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: "Balsamiq Sans", cursive;
  color: rgb(251, 251, 243);
  pointer-events: none;
}

.clickOnBooster p {
  width: 160px;
}

.home {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: color 1s;
  overflow: hidden;
  background: url("../assets/test.png");
  mix-blend-mode: multiply;
}

.homeDark {
  background: url("../assets/test-night.png");
  display: flex;
  justify-content: center;
}

.blobs {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  z-index: -1;
  opacity: 0.8;
}

.blob-day {
  opacity: 1 !important;
  mix-blend-mode: multiply;
}

.tree {
  height: 100vh;
  position: absolute;
  mix-blend-mode: multiply;
  z-index: -2;
}

.catalogHide {
  left: 100%;
}

.black-screen {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
}

.text {
  color: aliceblue;
  font-size: 300px;
}

.temporary-modal {
  height: 100px;
  width: 200px;
  position: absolute;
  top: 100px;
  background-color: blue;
}

.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadImg {
  height: 60px;
}

.homme-progress-bar-body {
  width: 200px;
  height: 20px;
  border: 1px solid rgb(22, 22, 22);
  border-radius: 10px;
  overflow: hidden;
}

.homme-progress-bar-inside {
  width: 0px;
  height: 100%;
  background-color: gold;
}

#canvas-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#canvas-container-shop {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-bottom: 35px;
}

.notVisible {
  visibility: hidden;
}

.hideCatalog {
  width: 0px;
  overflow: hidden;
}
</style>
