<template>
  <div class="nav-bar-container">
    <img
      id="settingsBtn"
      src="../assets/cog.svg"
      alt="sound-controller"
      class="settings-icon"
      :class="{ active: settings }"
      @click="settings ? switchSettings(false) : switchSettings(true)"
    />
    <div
      class="popin-settings"
      :class="{
        'popin-settings': !isMobile,
        'popin-settings-mobile': isMobile,
        big: settings,
      }"
      id="settings"
    >
      <div class="reset-game-container">
        <p @click="cheat">Do you want to reset the game?</p>
        <div class="settings-btn" @click="resetGame">Reset</div>
      </div>
      <div class="spacer"></div>
      <p>Developement: Jean Joulia</p>
      <p>jean.joulia.france@gmail.com</p>
      <div class="spacer"></div>
      <p>Illustration: Mélissa ledoux</p>
      <p>
        <a href="https://www.melissaledoux.fr">https://www.melissaledoux.fr</a>
      </p>
    </div>
    <div
      :class="{
        'buff-container': !isMobile,
        'buff-container-mobile': isMobile,
      }"
    >
      <div class="buff" v-if="getMultiplyNb > 0">
        <img src="../assets/buff/coin.png" alt="coin" class="buff-image" />
        <div class="tooltip">{{ `${getMultiplyNb} remaining` }}</div>
      </div>
      <div class="buff" v-if="getFactoryAccident">
        <img
          src="../assets/buff/factory.png"
          alt="factory"
          class="buff-image"
        />
        <div class="tooltip">{{ `${getFactoryAccident} bigger booster` }}</div>
      </div>
      <div class="buff" v-if="getIdRobot || getIdRobot == 0">
        <img src="../assets/buff/L3-38.png" alt="L3" class="buff-image" />
        <div class="tooltip">{{ `Card ${getIdRobot.id} is atracted` }}</div>
      </div>
      <div class="buff" v-if="getBlessing == 'COIN'">
        <img
          src="../assets/buff/blessingCoin.png"
          alt="L3"
          class="buff-image"
        />
        <div class="tooltip">{{ `sell for 3 more coins` }}</div>
      </div>
      <div class="buff" v-if="getBlessing == 'FAST_WORKER'">
        <img
          src="../assets/buff/blessingFast.png"
          alt="L3"
          class="buff-image"
        />
        <div class="tooltip">{{ `Earn money faster` }}</div>
      </div>
      <div class="buff" v-if="getMalediction">
        <img src="../assets/buff/malediction.png" alt="L3" class="buff-image" />
        <div class="tooltip">{{ `no more shiny card` }}</div>
      </div>
    </div>
    <div
      :class="{ 'book-link': !isMobile, 'book-link-mobile': isMobile }"
      @click="openCatalog"
    >
      <img
        v-if="catalogState"
        id="book-icon"
        src="../assets/book-close-icon.png"
        alt="book-nav-image"
        class="book-nav-icon"
      />
      <img
        v-else
        id="book-icon"
        src="../assets/book-icon.png"
        alt="book-nav-image"
        class="book-nav-icon"
      />
      <div id="number-new-card" v-if="nbEvent > 0">{{ nbEvent }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mobile from "is-mobile";

export default {
  props: {
    switchCatalog: Function,
    catalogState: Boolean,
  },
  data() {
    return {
      nbEvent: 0,
      settings: false,
      init: true,
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        this.settings &&
        !document.getElementById("settings").contains(e.target) &&
        !document.getElementById("settingsBtn").contains(e.target)
      ) {
        this.switchSettings(false);
      }
    });
  },
  methods: {
    ...mapActions(["resetNew", "setCheat"]),
    cheat() {
      if (process.env.NODE_ENV === "development") {
        this.setCheat();
      }
    },
    twitch() {
      var doc = document.getElementById("book-icon");
      doc.style.paddingTop = "10px";
      setTimeout(() => {
        doc.style.paddingTop = "0px";
      }, 100);
    },
    openCatalog() {
      if (!this.catalogState) {
        this.nbEvent = 0;
      } else {
        this.resetNew();
      }
      this.switchCatalog();
    },
    addCard() {
      this.nbEvent++;
    },
    reset() {
      localStorage.setItem("boosterGameObj", null);
    },
    switchSettings(close) {
      if (close) {
        this.switchCatalog(false);
      }
      this.settings = close;
    },
    resetGame() {
      localStorage.setItem("boosterGameObj", null);
      document.location.reload();
    },
  },
  computed: {
    ...mapGetters([
      "getCards",
      "getMultiplyNb",
      "getFactoryAccident",
      "getIdRobot",
      "getBlessing",
      "getMalediction",
    ]),
    getCardsData() {
      return this.getCards;
    },

    isMobile() {
      return mobile();
    },
  },
  watch: {
    getCardsData: function () {
      if (this.catalogState !== undefined && !this.catalogState && !this.init) {
        this.nbEvent++;
        if (this.nbEvent > 0) {
          this.twitch();
        }
      }
      this.init = false;
    },
  },
};
</script>

<style>
#number-new-card {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  top: 8px;
  right: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Balsamiq Sans", cursive;
}

.popin-settings {
  position: absolute;
  max-height: 0px;
  width: 400px;
  background-color: wheat;
  z-index: 2;
  top: 39px;
  left: 0px;
  transition: max-height 0.3s ease-in;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  font-family: "Balsamiq Sans", cursive;
}

.spacer {
  width: 100%;
  height: 2px;
  background-color: black;
}

.popin-settings-mobile {
  position: absolute;
  max-height: 0px;
  width: 250px;
  background-color: wheat;
  z-index: 2;
  top: 39px;
  left: 0px;
  transition: max-height 0.3s ease-in;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  font-family: "Balsamiq Sans", cursive;
}

.popin-settings p {
  margin-left: 10px;
}

.reset-game-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-btn {
  width: 120px;
  height: 40px;
  background-color: #252525;
  margin: 0px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.popin-settings.big {
  max-height: 500px;
}

.nav-bar-container {
  width: 100%;
  height: 39px;
  background-image: url("../assets/foothead.png");
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
}

.tooltip {
  height: 20px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  position: absolute;
  bottom: -15px;
  right: 3px;
  background: white;
  font-family: "Balsamiq Sans", cursive;
  font-size: 14px;
  visibility: hidden;
}

.buff {
  width: 35px;
  height: 35px;
  margin-left: 7px;
  align-items: center;
  position: relative;
}

.buff:hover .tooltip {
  visibility: visible;
}

.buff-image {
  height: 100%;
}

.buff-container {
  position: absolute;
  right: 150px;
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buff-container-mobile {
  position: absolute;
  right: 100px;
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.settings-icon {
  width: 27px;
  margin-left: 26px;
  cursor: pointer;
  transition: transform 0.5s;
  z-index: 1;
}

.settings-icon.active {
  transform: rotate(180deg);
}

.book-link {
  width: 137px;
  height: 70px;
  background-image: url("../assets/foothead.png");
  border-bottom-left-radius: 8px;
  position: relative;
  cursor: pointer;
}

.book-link-mobile {
  width: 100px;
  height: 70px;
  background-image: url("../assets/foothead.png");
  border-bottom-left-radius: 8px;
  position: relative;
  cursor: pointer;
}

.book-nav-icon {
  height: 55px;
  margin-top: 8px;
  transition: padding 0.3s;
  margin-left: 20px;
}
</style>