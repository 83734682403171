<template>
  <div
    :class="{
      'page-mobile': isMobile,
      page: !isMobile,
      pageLeft: orientationLeft,
      pageRight: !orientationLeft,
      pageTexture1: page == 0 || page == 4,
      pageTexture2: page == 1 || page == 5,
      pageTexture3: page == 2 || page == 3,
    }"
    v-if="cards.length > 0"
  >
    <img
      v-if="orientationLeft || orientationLeft === null"
      src="@/assets/arrow-left.png"
      alt="left-arrow"
      class="arrow"
      :class="{ hidden: page == 0 }"
      @click="goLeft"
    />
    <div class="card-displayed-container">
      <div class="cards-row" v-for="row in rows" :key="row">
        <div class="card-container">
          <Card
            v-if="cards[0 + row * 3].rare + cards[0 + row * 3].normal > 0"
            :card="cards[0 + row * 3]"
            :class="{
              'card-position': !isMobile,
              'card-position-mobile': isMobile,
            }"
          />
          <img
            v-else
            src="../assets/empty-card.png"
            alt="new tag"
            class="empty-slot"
          />
          <div
            class="numberCardEmpty"
            v-if="cards[row * 3].normal + cards[row * 3].rare == 0"
          >
            {{ cards[row * 3].id }}
          </div>
          <img
            src="../assets/clue.png"
            alt="clue"
            class="clueBtn"
            v-if="
              cards[row * 3].clue &&
              cards[row * 3].normal + cards[row * 3].rare == 0
            "
            @click="hint(cards[row * 3].id)"
          />
          <img
            src="../assets/newTag.png"
            alt="new tag"
            class="new-tag"
            v-if="
              getNewCard.includes(cards[0 + row * 3].id) &&
              cards[0 + row * 3].normal + cards[0 + row * 3].rare > 0
            "
          />
          <img
            @click="displayCard(cards[0 + row * 3])"
            src="../assets/card-holder.png"
            alt="new tag"
            class="card-holder"
          />
          <div
            class="nb-card-indicator"
            v-if="cards[0 + row * 3].normal + cards[0 + row * 3].rare > 1"
          >
            {{ cards[0 + row * 3].normal + cards[0 + row * 3].rare }}
          </div>
          <div class="boosterClueList">
            <img
              v-if="
                cards[row * 3].boosters.includes(0) &&
                getShops.includes('woodStand')
              "
              src="../assets/booster0.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[row * 3].boosters.includes(1) &&
                getShops.includes('chest')
              "
              src="../assets/booster1.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[row * 3].boosters.includes(2) &&
                getShops.includes('stand')
              "
              src="../assets/booster2.png"
              alt=""
              class="boosterClue"
            />
          </div>
        </div>
        <div class="card-container">
          <Card
            v-if="cards[1 + row * 3].rare + cards[1 + row * 3].normal > 0"
            :card="cards[1 + row * 3]"
            :class="{
              'card-position': !isMobile,
              'card-position-mobile': isMobile,
            }"
          />
          <img
            v-else
            src="../assets/empty-card.png"
            alt="new tag"
            class="empty-slot"
          />
          <div
            class="numberCardEmpty"
            v-if="cards[1 + row * 3].normal + cards[1 + row * 3].rare == 0"
          >
            {{ cards[1 + row * 3].id }}
          </div>
          <img
            src="../assets/clue.png"
            alt="clue"
            class="clueBtn"
            v-if="
              cards[1 + row * 3].clue &&
              cards[1 + row * 3].normal + cards[1 + row * 3].rare == 0
            "
            @click="hint(cards[1 + row * 3].id)"
          />
          <img
            src="../assets/newTag.png"
            alt="new tag"
            class="new-tag"
            v-if="
              getNewCard.includes(cards[1 + row * 3].id) &&
              cards[1 + row * 3].normal + cards[1 + row * 3].rare > 0
            "
          />
          <img
            @click="displayCard(cards[1 + row * 3])"
            src="../assets/card-holder.png"
            alt="new tag"
            class="card-holder"
          />
          <div
            class="nb-card-indicator"
            v-if="cards[1 + row * 3].normal + cards[1 + row * 3].rare > 1"
          >
            {{ cards[1 + row * 3].normal + cards[1 + row * 3].rare }}
          </div>
          <div class="boosterClueList">
            <img
              v-if="
                cards[1 + row * 3].boosters.includes(0) &&
                getShops.includes('woodStand')
              "
              src="../assets/booster0.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[1 + row * 3].boosters.includes(1) &&
                getShops.includes('chest')
              "
              src="../assets/booster1.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[1 + row * 3].boosters.includes(2) &&
                getShops.includes('stand')
              "
              src="../assets/booster2.png"
              alt=""
              class="boosterClue"
            />
          </div>
        </div>
        <div class="card-container">
          <Card
            v-if="cards[2 + row * 3].rare + cards[2 + row * 3].normal > 0"
            :card="cards[2 + row * 3]"
            :class="{
              'card-position': !isMobile,
              'card-position-mobile': isMobile,
            }"
          />
          <img
            v-else
            src="../assets/empty-card.png"
            alt="new tag"
            class="empty-slot"
          />
          <div
            class="numberCardEmpty"
            v-if="cards[2 + row * 3].normal + cards[2 + row * 3].rare == 0"
          >
            {{ cards[2 + row * 3].id }}
          </div>
          <img
            src="../assets/clue.png"
            alt="clue"
            class="clueBtn"
            v-if="
              cards[2 + row * 3].clue &&
              cards[2 + row * 3].normal + cards[2 + row * 3].rare == 0
            "
            @click="hint(cards[2 + row * 3].id)"
          />
          <img
            src="../assets/newTag.png"
            alt="new tag"
            class="new-tag"
            v-if="
              getNewCard.includes(cards[2 + row * 3].id) &&
              cards[2 + row * 3].normal + cards[2 + row * 3].rare > 0
            "
          />
          <img
            @click="displayCard(cards[2 + row * 3])"
            src="../assets/card-holder.png"
            alt="new tag"
            class="card-holder"
          />
          <div
            class="nb-card-indicator"
            v-if="cards[2 + row * 3].normal + cards[2 + row * 3].rare > 1"
          >
            {{ cards[2 + row * 3].normal + cards[2 + row * 3].rare }}
          </div>
          <div class="boosterClueList">
            <img
              v-if="
                cards[2 + row * 3].boosters.includes(0) &&
                getShops.includes('woodStand')
              "
              src="../assets/booster0.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[2 + row * 3].boosters.includes(1) &&
                getShops.includes('chest')
              "
              src="../assets/booster1.png"
              alt=""
              class="boosterClue"
            />
            <img
              v-if="
                cards[2 + row * 3].boosters.includes(2) &&
                getShops.includes('stand')
              "
              src="../assets/booster2.png"
              alt=""
              class="boosterClue"
            />
          </div>
        </div>
      </div>
    </div>
    <img
      v-if="!orientationLeft"
      src="@/assets/arrow-left.png"
      alt="right-arrow"
      class="arrowRight arrow"
      :class="{ hidden: page == 5 }"
      @click="goRight"
    />
  </div>
</template>

<script>
import Card from "@/components/card";
import { mapGetters } from "vuex";
import mobile from "is-mobile";
export default {
  data() {
    return {
      rows: [0, 1, 2],
    };
  },
  props: {
    orientationLeft: Boolean,
    cards: Array,
    navigate: Function,
    page: Number,
    showCard: Function,
    hint: Function,
  },
  components: {
    Card,
  },
  computed: {
    ...mapGetters(["getNewCard", "getShops"]),
    isMobile() {
      return mobile();
    },
  },
  methods: {
    goLeft() {
      this.navigate(-1);
    },
    goRight() {
      this.navigate(1);
    },
    displayCard(card) {
      if (card.normal + card.rare > 0) {
        this.showCard(card);
      }
    },
  },
};
</script>

<style>
.card-container {
  position: relative;
  height: 100%;
}

.numberCardEmpty {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
  color: black;
  font-family: "Balsamiq Sans", cursive;
}

.empty-slot {
  height: 100%;
  border-radius: 8px;
}

.card-position {
  height: 100%;
}

.card-position-mobile {
  height: 90%;
}

.cards-row {
  display: flex;
  justify-content: space-around;
  height: 30%;
  align-items: center;
}

.arrowRight {
  transform: scaleX(-1);
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  width: 48%;
  background-size: cover;
}

.page-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  width: 95%;
  background-size: cover;
}

.pageTexture1 {
  background-image: url("../assets/paper1.jpg");
}

.pageTexture2 {
  background-image: url("../assets/paper2.jpg");
}

.pageTexture3 {
  background-image: url("../assets/paper3.jpg");
}

.pageLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: solid 1px black;
}

.pageRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card-displayed-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  height: 100%;
}

.boosterClueList {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: absolute;
  bottom: 2%;

  display: flex;
  justify-content: flex-end;
}

.boosterClue {
  height: 100%;
  margin-right: 2%;
}

.arrow {
  width: 10%;
  cursor: pointer;
  z-index: 1;
}

.clueBtn {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 0px;
  top: 0px;
  width: 20%;
}

.new-tag {
  position: absolute;
  width: 60%;
  top: 0px;
  left: 0px;
}

.nb-card-indicator {
  position: absolute;
  border-radius: 50%;
  border: solid 1px black;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  right: -10px;
  top: -10px;
  z-index: 1;
  font-family: "Balsamiq Sans", cursive;
}

.card-holder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.hidden {
  visibility: hidden;
}
</style>