<template>
  <div class="viewerMobile-dark-background">
    <div class="viewerMobile-hint" v-if="hintDisplayed">
      <div class="viewerMobile-hint-title-container">
        <span>{{ infos.name }}</span>
        <img
          src="../assets/close.svg"
          class="viewerMobile-hint-cross"
          @click="hintDisplayed = false"
        />
      </div>
      <div class="viewerMobile-hint-text-container">
        <p>{{ infos.detail }}</p>
      </div>
    </div>
    <div class="viewerMobile-card-container">
      <div class="viewerMobile-card-container2">
        <Card :card="{ id: card.id, rare: rare }" Class="viewerMobile-card" />
      </div>
    </div>
    <div class="viewerMobile-button-container">
      <span class="viewerMobile-rare">{{
        `${rare ? card.rare : card.normal}X`
      }}</span
      ><Button size="small" @click="sell"
        :class="{'gold-price': getMultiplyNb > 0,'btn-shine': rare,'not-visible': card.id == 53 }"
        >Sell {{ getValue }}
        <div class="coin"></div></Button
      ><Button
        size="small"
        @click="play"
        :class="{ 'not-visible': !infos.playable }"
        >Play</Button
      >
    </div>
    <div class="viewerMobile-switch-container">
      <img
        src="../assets/clue.png"
        alt="clue"
        :class="{ 'not-visible': !card.clue }"
        @click="hintDisplayed = true"
      />
      <div class="viewerMobile-switch-text-container">
        <div
          :class="{ 'switch-innactive': hideSwitch }"
          class="viewerMobile-switch-body"
          @click="switchThumb"
        >
          <div
            class="viewerMobile-switch-thumb"
            :class="{ active: rare, 'switch-innactive': hideSwitch }"
          ></div>
        </div>
        <span class="viewerMobile-rare">{{ rare ? "Rare" : "Normal" }}</span>
      </div>
      <img @click="hide" src="../assets/close.svg" class="viewerMobile-cross" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/card";
import Button from "@/components/button";
import cardInfo from "@/assets/card.json";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Card,
    Button,
    // Button,
  },
  props: {
    card: Object,
    hide: Function,
    playCard: Function,
  },
  data() {
    return {
      rare: false,
      hintDisplayed: false,
    };
  },
  mounted() {
    this.rare = this.card.normal == 0;
  },
  computed: {
    ...mapGetters(['getMultiplyNb','getBlessing']),
    hideSwitch() {
      return this.card.normal == 0 || this.card.rare == 0;
    },
    infos() {
      return cardInfo[this.card.id];
    },
    getValue() {
      var valueTmp = this.rare ? this.infos.value * 2 : this.infos.value;
      valueTmp = this.getMultiplyNb > 0 ? valueTmp * 3 : valueTmp;
      return this.getBlessing == "COIN" ? valueTmp + 3 : valueTmp;
    },
  },
  methods: {
    ...mapActions(["soldCard"]),
    switchThumb() {
      this.rare = !this.rare;
    },
    sell() {
      var cardNb = this.card.normal + this.card.rare;
      var cardNb2 = this.rare ? this.card.rare : this.card.normal;

      var value = this.getValue;

      this.soldCard({
        value,
        id: this.infos.id,
        rare: this.rare,
      });
      if (
        ((this.rare && this.card.normal == 0) ||
          (!this.rare && this.card.rare == 0)) &&
        cardNb == 1
      ) {
        this.hide();
      }
      if (cardNb2 == 1) {
        this.rare = !this.rare;
      }
    },
    play() {
      this.playCard(this.card.id, this.rare);
    },
  },
};
</script>

<style>
.viewerMobile-dark-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.911);
  position: absolute;
  z-index: 2;
}

.viewerMobile-card {
  height: 100%;
}

.viewerMobile-switch-container {
  width: 100%;
  height: 12%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.not-visible {
  visibility: hidden;
}

.viewerMobile-card-container {
  width: 100%;
  height: 70%;
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewerMobile-card-container2 {
  height: 60vh;
  position: absolute;
}

.viewerMobile-button-container {
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.viewerMobile-switch-body {
  width: 90px;
  height: 40px;
  background-color: azure;
  border-radius: 25px;
}

.viewerMobile-switch-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(194, 194, 194);
  margin-top: 5px;
  margin-left: 5px;
  transition: margin 0.5s ease-in-out;
}

.viewerMobile-switch-thumb.active {
  margin-left: 54px;
}

.viewerMobile-switch-text-container {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewerMobile-cross {
  width: 35px;
  height: 35px;
}

.viewerMobile-rare {
  color: white;
  font-family: "Balsamiq Sans", cursive;
  font-size: 24px;
}

.viewerMobile-hint {
  width: 80%;
  height: auto;
  background-color: black;
  position: absolute;
  z-index: 1;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  color: white;
  padding: 10px;
  font-family: "Balsamiq Sans", cursive;
}

.viewerMobile-hint-title-container {
  height: 50px;
  width: 100%;
  font-family: "Balsamiq Sans", cursive;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewerMobile-hint-cross {
  width: 30px;
  height: 30px;
}

.switch-innactive {
  background-color: rgba(117, 117, 117, 0.644);
  pointer-events: none;
}
</style>